<template src="./createClient.html"></template>

<script lang="ts">
    import { axiosRequests } from '../../../services/axiosinstance';
    import { defineComponent } from 'vue';
    import { editUrl, getFormatedDate, getFormatedAmount, convertToISOString } from '../../../services/helper';
    import { Routes } from '../../../const';
    import { vMaska } from "maska"
    import { SelectModel } from '../../../models/GUIModels';
    import { Service } from '../../../models/Service';
    import { ClubWithAgreements } from '../../../models/Club';

    interface ComponentModel {
        options: any;
        minBirthdayDate: string,
        maxBirthdayDate: string,
        lastname: string,
        firstname: string,
        middlename: string,
        email: string,
        phone: string,
        birthday: string,
        sex: SelectModel[],
        selectSex: SelectModel,
        lastnameErrorText: string,
        firstnameErrorText: string,
        emailErrorText: string,
        phoneErrorText: string,
        birthdayErrorText: string,
        allClubs: ClubWithAgreements[],
        clubs: SelectModel[],
        selectedClub: SelectModel | null,
        agreements: SelectModel[],
        selectedAgreement: SelectModel | null,
        paymentMethods: SelectModel[],
        selectedPaymentMethod: SelectModel,
        activationDate: string,
        paymentDate: string,
        isCorporateClient: boolean,
        tempContractPrice: number,
        tempSevices: Service[],
        totalPrice: number,
        clubErrorText: string,
        agreementErrorText: string,
        activationDateErrorText: string,
        organizationNameErrorText: string,
        paymentDateErrorText: string,
        currencySign: string,
        organizationName: string,
        organizations: SelectModel[],
        selectedOrganizations: SelectModel | null
    }

    export default defineComponent({
        name: 'CreateClient',
        directives: { maska: vMaska },
        data(): ComponentModel {
            return {
                 options: {
                      mask: "+7 (###) ###-##-##"
                },
                minBirthdayDate: getFormatedDate(convertToISOString(new Date(new Date().getFullYear() - 100, new Date().getMonth(), new Date().getDate())), "yyyy-MM-DD"),
                maxBirthdayDate: getFormatedDate(convertToISOString(new Date(new Date().getFullYear() - 16, new Date().getMonth(), new Date().getDate())), "yyyy-MM-DD"),
                lastname: "",
                firstname: "",
                middlename: "",
                email: "",
                phone: "",
                birthday: "",
                sex: [
                    { id: "false", text: 'clients.female', isTranslated: true, disabled: false, groupName: null },
                    { id: "true", text: 'clients.male', isTranslated: true, disabled: false, groupName: null }
                ],
                selectSex: { id: "true", text: 'clients.male', isTranslated: true, disabled: false, groupName: null },
                lastnameErrorText: "",
                firstnameErrorText: "",
                emailErrorText: "",
                phoneErrorText: "",
                birthdayErrorText: "",
                allClubs: [],
                clubs: [],
                selectedClub: null,
                agreements: [],
                selectedAgreement: null,
                paymentMethods: [
                    { id: "posTerminal", text: 'payment_method.terminal', isTranslated: true, disabled: false, groupName: null },
                    { id: "cash", text: 'payment_method.cash', isTranslated: true, disabled: false, groupName: null },
                    { id: "bankTransfer", text: 'payment_method.bankTransfer', isTranslated: true, disabled: false, groupName: null },
                ],
                selectedPaymentMethod: { id: "posTerminal", text: 'payment_method.terminal', isTranslated: true, disabled: false, groupName: null },
                activationDate: getFormatedDate(convertToISOString(new Date()), "yyyy-MM-DD"),
                paymentDate: getFormatedDate(convertToISOString(new Date()), "yyyy-MM-DD"),
                isCorporateClient: false,
                tempContractPrice: 0,
                tempSevices: [],
                totalPrice: 0,
                clubErrorText: "",
                agreementErrorText: "",
                activationDateErrorText: "",
                organizationNameErrorText: "",
                paymentDateErrorText: "",
                currencySign: "₽",
                organizationName: "",
                organizations: [],
                selectedOrganizations: null
            }
        },
        methods: {
            getClubsWithAgreements: async function () {
                await axiosRequests.get(editUrl(Routes.getClubsWithAvailableAgreements))
                    .then(
                        (data: ClubWithAgreements[]) => {
                            this.allClubs = data;
                            this.clubs = data.map(x => { return { id: x.id.toString(), text: x.name, isTranslated: false, disabled: false, groupName: null }});

                            if(this.clubs.length > 0){
                                this.selectedClub = this.clubs[0];
                                this.selectClub();
                            }
                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 500) {
                                this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            getOrganizations: async function () {
                await axiosRequests.get(editUrl(Routes.getOrganizations, this.selectedClub?.id))
                    .then(
                        (data: string[]) => {
                            this.organizations = data.map(x => { return { id: x, text: x, isTranslated: false, disabled: false, groupName: null }});
                            this.organizations.unshift({ id: "", text: 'clients.create.new_organization', isTranslated: true, disabled: false, groupName: null})
                            this.selectedOrganizations = this.organizations[0];
                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 500) {
                                this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            save: function () {
                if (!this.lastname) this.lastnameErrorText = 'clients.create.lastname_error';
                if (!this.firstname) this.firstnameErrorText = 'clients.create.firstname_error';
                if (!this.email || !this.validEmail(this.email)) this.emailErrorText = 'clients.create.email_error';
                if (!this.phone || this.phone.length != 18) this.phoneErrorText = 'clients.create.phone_error';
                if (!this.birthday) this.birthdayErrorText = 'clients.create.birthday_error';
                if (!this.selectedClub) this.clubErrorText = 'clients.create.club_error';
                if (!this.selectedAgreement) this.agreementErrorText = 'clients.create.agreement_error';
                if (!this.activationDate) this.activationDateErrorText = 'clients.create.contract_activation_date_error';
                if (!this.paymentDate) this.paymentDateErrorText = 'clients.create.payment_date_error';
                if (this.isCorporateClient && !this.organizationName) this.organizationNameErrorText = 'clients.create.organization_name_error';

                if (this.lastnameErrorText || this.firstnameErrorText || this.emailErrorText || this.phoneErrorText || this.birthdayErrorText || this.clubErrorText || this.agreementErrorText || this.activationDateErrorText || this.paymentDateErrorText || this.organizationNameErrorText) return;

                const phone = this.phone.replace(/\D/g, '');

                let data = { firstName: this.firstname, lastName: this.lastname, patronymic: this.middlename, email: this.email, phoneCountryCode: phone[0], phone: phone.slice(1), sex: this.selectSex.id == "true", 
                             birthDay: getFormatedDate(this.birthday, "yyyy-MM-DD"), agreementId: this.selectedAgreement?.id, contractActivationDate: getFormatedDate(this.activationDate, "yyyy-MM-DD"),
                             paymentDate: getFormatedDate(this.paymentDate, "yyyy-MM-DD"), paymentMethod: this.selectedPaymentMethod.id, isCorporateClient: this.isCorporateClient, organizationName: this.organizationName, contractPrice: parseInt((this.tempContractPrice * 100).toString()), mandatoryServices: this.tempSevices.map(x => { return { serviceId: x.serviceId, price: parseInt((x.price * 100).toString()) }}) };

                axiosRequests.post(editUrl(Routes.createClient), data)
                    .then(
                        () => {
                            this.$router.push('/clients/new-contracts');
                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 500) {
                                this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            validEmail: function (email: string) {
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            selectClub: function () {
                this.selectedAgreement = null;
                this.agreements = [];
                this.organizations = [];
                this.selectedOrganizations = null;
                this.organizationName = "";

                this.getOrganizations();

                const index = this.allClubs.findIndex(x => x.id.toString() == this.selectedClub?.id);
                if(index == -1) return;

                this.agreements = this.allClubs[index].agreements.map(x => { return { id: x.agreementId.toString(), text: x.name, isTranslated: false, disabled: false, groupName: null }})
                this.currencySign = this.allClubs[index].currencySign;
            },
            selectAgreement: function () {
                 const clubIndex = this.allClubs.findIndex(x => x.id.toString() == this.selectedClub?.id);
                 if(clubIndex == -1) return;

                 const agreementIndex = this.allClubs[clubIndex].agreements.findIndex(x => x.agreementId.toString() == this.selectedAgreement?.id);

                 if(agreementIndex == -1) return;

                 this.tempContractPrice = this.allClubs[clubIndex].agreements[agreementIndex].price / 100;
                 this.tempSevices = this.allClubs[clubIndex].agreements[agreementIndex].mandatoryServices.map(({price, ...rest}) => ({ price: price / 100, ...rest }));

                 this.getTotalPrice();
            },
            selectOrganization: function() {
                this.organizationName = this.selectedOrganizations?.id ?? "";
                this.organizationNameErrorText = "";
            },
            checkContractPrice: function () {
                if (!this.tempContractPrice || this.tempContractPrice < 0) this.tempContractPrice = 0;
            },
            checkServicePrice: function (index: number) {
                if (!this.tempSevices[index].price || this.tempSevices[index].price < 0) this.tempSevices[index].price = 0;
            },
            getTotalPrice: function () {
                this.totalPrice = !this.tempContractPrice || this.tempContractPrice < 0 ? 0 : this.tempContractPrice;

                for (let service of this.tempSevices) {
                    if (service.price && service.price > 0) this.totalPrice += service.price;
                }
            },
            getFormatedAmount
        },
        beforeMount() {
            this.getClubsWithAgreements();
        }
    });
</script>

<style scoped>
    .client_wrapper{
        display: flex;
        flex-flow: wrap;
    }

    .contract_wrapper {
        margin-top: 18px;
        display: flex;
        flex-flow: wrap;
    }

    .content_body {
        padding-right: 5px;
        overflow: auto;
    }

    .elment_wrapper {
        width: 25%;
        margin-top: 8px;
        margin-bottom: 8px;
    }
    
    .elment{
        width: 97% !important;
    }

    .button_wrapper {
        display: flex;
        margin-bottom: 6px;
    }

    .button_space {
        margin-right:16px;
    }

    .agreement_element_wrapper {
        margin-top: 6px;
        display: flex;
        flex-flow: wrap;
    }

    .agreement_element:nth-child(odd) {
        width: calc(50% - 14px);
        padding-right: 14px;
        margin-bottom: 8px;
    }

    .agreement_element:nth-child(even) {
        width: calc(50% - 14px);
        margin-bottom: 8px;
    }

    .agreement_element_label {
        margin-top: 0px;
        margin-bottom: 12px;
        font-weight: 700;
    }

    .total_price {
        color: #007CA3;
        font-weight: 600;
        font-size: 28px;
        margin-top: 12px;
        margin-bottom: 8px;
    }

    .corporate_organizations_wrapper {
        display: flex;
        width: 100%;
    }

    @media(max-width: 1600px) {
        .elment_wrapper {
            width: 33%;
        }

        .agreement_element:nth-child(odd) {
            width: calc(50% - 23px);
        }

        .agreement_element:nth-child(even) {
            width: calc(50% - 14px);
        }
    }


    @media(max-width: 960px) {
        .elment_wrapper {
            width: 50% !important;
        }

        .agreement_element:nth-child(odd) {
            width: calc(50% - 12px);
            padding-right: 12px;
        }

        .agreement_element:nth-child(even) {
            width: calc(50% - 13px);
        }
    }

    @media(max-width: 720px) {
        .elment_wrapper {
            width: 100% !important;
        }

        .elment {
            width: 100% !important;
        }

        .contract_wrapper {
            margin-top: 0px;
        }

        .agreement_element:nth-child(odd) {
            width: 100%;
            padding-right: 0px;
        }

        .agreement_element:nth-child(even) {
            width: 100%;
        }

        .new_organization_name{
            margin-top: 18px;
        }

        .corporate_organizations_wrapper{
            display: block;
        }
    }

    @media(max-width: 500px){
        .button_wrapper {
            display: block;
            margin-bottom: 0px;
        }

        .button_space {
            margin-right: 0px;
        }

        .total_price {
            font-size: 17px;
            margin-top: 4px;
            margin-bottom: 12px;
        }
    }


</style>