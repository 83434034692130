<template>
    <div>
        <div :class="radioWrapperClass" v-for="(item, index) in radioList" v-bind:key="index" @click="selectRadio(item)">
            <div class="radio">
                <div class="radio_active" v-show="item == data"></div>
            </div>
            <input type="radio" v-bind:value="item" v-model="data" hidden>
            <label class="pointer">{{item?.label ? $t(item?.label) : $t("empty_string")}}</label>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { RadioModel } from '../../models/GUIModels';

    interface ComponentModel {
        data: RadioModel | null,
        radioWrapperClass: string,
    }

    export default defineComponent({
        name: 'VRadio',
        props: ["modelValue", "radioList", "mb", "mr"],
        data(): ComponentModel {
            return {
                data: null,
                radioWrapperClass: ""
            }
        },
        methods: {
            selectRadio: function (item: RadioModel) {
                this.data = item;
                this.$emit('update:modelValue', this.data);
            },
        },
        watch: {
            'modelValue': function (newVal) {
                this.data = newVal;
            }
        },
        mounted() {
            let mb = "mb-";
            let mr = "mr-";

            if (this.mb != null && this.mb != undefined) {
                mb += this.mb
            } else {
                mb += "0";
            }

            if (this.mr != null && this.mr != undefined) {
                mr += this.mr
            } else {
                mr += "0";
            }

            this.radioWrapperClass = `radio_wrapper ${mb} ${mr}`;

            this.data = this.radioList?.find((x: RadioModel) => x.value == this.modelValue.value);
        },
    });
</script>

<style scoped>
    .radio_wrapper {
        display: flex;
    }

    .radio {
        height: 12px;
        width: 12px;
        border: 1.5px solid #48aab8;
        border-radius: 50%;
        cursor: pointer;
        margin-right: 6px;
        margin-top: 2px;
    }

    .radio_active {
        margin: 2px;
        background-color: #48aab8;
        height: 8px;
        width: 8px;
        border-radius: 50%;
    }
</style>
