<template src="./viewTasks.html"></template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import draggable from 'vuedraggable'
    import { axiosRequests } from '../../services/axiosinstance';
    import { editUrl, addPhoneMask, getFormatedDate, getTextColor, getContractStatus, retryGetPermissions, convertToISOString } from '../../services/helper';
    import { Routes } from '../../const';
    import { Task, LeadStatus, LeadManager } from '../../models/Task';
    import { SelectModel } from '../../models/GUIModels';
    import CreateTask from './CreateTask/CreateTask.vue';
    import ViewTask from './ViewTask/ViewTask.vue';
    import ChangeLeadNextStepDate from './ChangeLeadNextStepDate/ChangeLeadNextStepDate.vue';

    interface LeadElement {
        isShow: boolean,
        leadId: number,
        leadClass: string,
        leadColor: string,
        name: string,
        phone: string,
        nextStepDate: string,
        nextStepDateText: string,
        isClient: boolean,
        contractLabel: string,
        contractDotClass: string,
        contractStatus: string,
        contractName: string,
        commentLabel: string,
        comment: string,
        labelNextStep: string,
        nextStep: string,
        leadStatus: string,
        leadTextColor: string,
        userId: string,
        leadStatusId: string,
        clubId: number,
        isUnhandled: boolean
    }

    export interface ChangeNextStepDateModel {
        kanban: string,
        leadId: number,
        nextStepDate: string,
        minDate: string,
        maxDate: string | null,
        isOpenAfterDrag: boolean,
        clubId: number
    }

    interface ComponentModel {
        taskData: Task,
        statuses: SelectModel[],
        managers: SelectModel[],
        nextSteps: SelectModel[],
        filterStatus: SelectModel | null,
        filterManager: SelectModel | null,
        filterNextStep: SelectModel | null,
        filterDate: string | null,
        isShowFilters: boolean,
        isShowClosed: boolean,
        searchText: string,
        isShowCreate: boolean,
        isShowView: boolean,
        isMobile: boolean,
        callingPhone: string,
        callingButton: any
        unprocessedLeads: LeadElement[],
        overdueLeads: LeadElement[],
        todayLeads: LeadElement[],
        tomorrowLeads: LeadElement[],
        thisWeekLeads: LeadElement[],
        laterLeads: LeadElement[],
        unprocessedLeadsCount: number,
        overdueLeadsCount: number,
        todayLeadsCount: number,
        tomorrowLeadsCount: number,
        thisWeekLeadsCount: number,
        laterLeadsCount: number,
        today: Date,
        tomorrow: Date,
        thisWeekEnd: Date,
        laterBegin: Date,
        showCloseDebounce: any,
        searchDebounce: any,
        unprocessedLeadsShowIndex: number,
        overdueLeadsShowIndex: number,
        todayLeadsShowIndex: number,
        tomorrowLeadsShowIndex: number,
        thisWeekLeadsShowIndex: number,
        laterLeadsShowIndex: number,
        kanbans: any,
        isShowChangeDate: boolean,
        changeNextStepDateModel: ChangeNextStepDateModel,
        popupLeadId: number
    }

    export default defineComponent({
       name: 'ViewTasks',
       data(): ComponentModel {
           return {
               taskData: {
                   today: "",
                   thisWeekBegin: "",
                   thisWeekEnd: "",
                   laterBegin: "", 
                   unprocessedLeads: [],
                   overdueLeads: [],
                   todayLeads: [],
                   tomorrowLeads: [],
                   thisWeekLeads: [],
                   laterLeads: [] },
                statuses: [],
                managers: [],
                nextSteps: [
                    { id: this.$t('task.absent'), text: "task.absent", isTranslated: true, groupName: null, disabled: false },
                    { id: this.$t("crm_next_step.call"), text: "crm_next_step.call", isTranslated: true, groupName: null, disabled: false },
                    { id: this.$t("crm_next_step.email"), text: "crm_next_step.email", isTranslated: true, groupName: null, disabled: false },
                    { id: this.$t("crm_next_step.sms"), text: "crm_next_step.sms", isTranslated: true, groupName: null, disabled: false }
                ],
                filterStatus: null,
                filterManager: null,
                filterNextStep: null,
                filterDate: null,
                isShowFilters: false,
                isShowClosed: false,
                searchText: "",
                isShowCreate: false,
                isShowView: false,
                isMobile: true,
                callingPhone: "",
                callingButton: null,
                unprocessedLeads: [],
                overdueLeads: [],
                todayLeads: [],
                tomorrowLeads: [],
                thisWeekLeads: [],
                laterLeads: [],
                unprocessedLeadsCount: 0,
                overdueLeadsCount: 0,
                todayLeadsCount: 0,
                tomorrowLeadsCount: 0,
                thisWeekLeadsCount: 0,
                laterLeadsCount: 0,
                today: new Date(),
                tomorrow: new Date(),
                thisWeekEnd: new Date(),
                laterBegin: new Date(),
                showCloseDebounce: null,
                searchDebounce: null,
                unprocessedLeadsShowIndex: 9,
                overdueLeadsShowIndex: 9,
                todayLeadsShowIndex: 9,
                tomorrowLeadsShowIndex: 9,
                thisWeekLeadsShowIndex: 9,
                laterLeadsShowIndex: 9,
                kanbans: null,
                isShowChangeDate: false,
                changeNextStepDateModel: {
                    maxDate: "",
                    minDate: "",
                    nextStepDate: "",
                    kanban: "",
                    leadId: 0,
                    isOpenAfterDrag: false,
                    clubId: 0
                },
                popupLeadId: 0
          }
        },
        methods: {
            getAllTasks: async function () {

                await axiosRequests.get(editUrl(`${Routes.getAllLeads}?IsClosedIncluded=${this.isShowClosed}`))
                    .then(
                        (data: Task) => {
                            this.taskData = data;

                            this.today = new Date(data.today);

                            let tomorrow = new Date(this.today);
                            tomorrow.setDate(tomorrow.getDate() + 1);

                            this.tomorrow = tomorrow;
                            this.thisWeekEnd = new Date(data.thisWeekEnd);
                            this.laterBegin = new Date(data.laterBegin);

                            this.applyFilter();
                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 500) {
                                this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                            } else if (err.status == 403) {
                                 this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            getAllLeadStatuses: async function () {
                if (this.statuses.length != 0) return;

                await axiosRequests.get(editUrl(Routes.getAllLeadsStatuses))
                    .then(
                        (data: LeadStatus[]) => {
                            this.statuses = data.map(x => {
                                return {
                                    id: x.leadStatusId.toString(),
                                    text: x.name,
                                    groupName: null,
                                    isTranslated: false,
                                    disabled: false
                                }
                            });

                            this.statuses.unshift({ id: "", text: "task.absent", isTranslated: true, groupName: null, disabled: false });
                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 500) {
                                this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            getAllManagers: async function () {
                if (this.managers.length != 0) return;

                await axiosRequests.get(editUrl(Routes.getAllManagers))
                    .then(
                        (data: LeadManager[]) => {
                            this.managers = data.map(x => {
                                return {
                                    id: x.userId.toString(),
                                    text: x.fullName,
                                    groupName: null,
                                    isTranslated: false,
                                    disabled: false
                                }
                            });

                            this.managers.unshift({ id: "", text: "task.absent", isTranslated: true, groupName: null, disabled: false });
                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 500) {
                                this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            clearAllFilters: function () {
                this.filterDate = null;
                this.filterManager = null;
                this.filterNextStep = null;
                this.filterStatus = null;

                this.applyFilter();
            },
            applyFilter: function () {
                this.updateKanban("unprocessedLeads");
                this.updateKanban("overdueLeads");
                this.updateKanban("todayLeads");
                this.updateKanban("tomorrowLeads");
                this.updateKanban("thisWeekLeads");
                this.updateKanban("laterLeads");
            },
            updateKanban: function (kanbanName: string) {
                let element = document.getElementById(kanbanName);

                element?.parentElement?.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "auto",
                });

                this[`${kanbanName}ShowIndex`] = 9;
                this[kanbanName] = [];

                let tempLead: LeadElement;
                for (let i = 0; i < this.taskData[kanbanName].length; i++) {
                    tempLead = {
                        isShow: false,
                        leadId: this.taskData[kanbanName][i].leadId,
                        leadClass: this.taskData[kanbanName][i].isClosed ? `lead isClosed lead_${this.taskData[kanbanName][i].leadId}` : `lead lead_${this.taskData[kanbanName][i].leadId}`,
                        leadColor: kanbanName == "unprocessedLeads" || kanbanName == "overdueLeads" ?  "#E22E2E" : this.taskData[kanbanName][i].leadStatus?.color ?? "#fff",
                        name: this.taskData[kanbanName][i].client ? `${this.taskData[kanbanName][i].client.lastName} ${this.taskData[kanbanName][i].client.firstName}` : `${this.taskData[kanbanName][i].firstName ? this.taskData[kanbanName][i].firstName : this.$t('task.absent')}`,
                        phone: this.taskData[kanbanName][i].client ? addPhoneMask(this.taskData[kanbanName][i].client.phone) : addPhoneMask(this.taskData[kanbanName][i].phone),
                        nextStepDate: this.taskData[kanbanName][i].nextStepDate,
                        nextStepDateText: `${getFormatedDate(this.taskData[kanbanName][i].nextStepDate, 'DD.MM.yyyy')} ${this.$t('task.at')} ${getFormatedDate(this.taskData[kanbanName][i].nextStepDate, 'HH:mm')}`,
                        isClient: this.taskData[kanbanName][i].client ? true : false,
                        contractLabel: this.$t('task.contract'),
                        contractDotClass: this.taskData[kanbanName][i].client ? `dot lead_dot_margin dot_contract_${getContractStatus(this.taskData[kanbanName][i].client.status)}` : "",
                        contractStatus: this.taskData[kanbanName][i].client ? this.$t(`contract_status.${getContractStatus(this.taskData[kanbanName][i].client.status)}`) : "",
                        contractName: this.taskData[kanbanName][i].client ? `${this.taskData[kanbanName][i].client.contract} (${this.taskData[kanbanName][i].client.agreement})` : "",
                        commentLabel: this.$t('task.comment'),
                        comment: this.taskData[kanbanName][i].description ? this.taskData[kanbanName][i].description : this.$t('task.absent'),
                        labelNextStep: this.$t('task.next_step'),
                        nextStep: this.taskData[kanbanName][i].nextStep ? this.$t(this.taskData[kanbanName][i].nextStep) : this.$t('task.absent'),
                        leadStatus: this.taskData[kanbanName][i].leadStatus?.name ?? "",
                        leadTextColor: this.taskData[kanbanName][i].leadStatus?.color ? getTextColor(this.taskData[kanbanName][i].leadStatus?.color ?? "#fff") : "#000",
                        userId: this.taskData[kanbanName][i].userId?.toString() ?? "",
                        leadStatusId: this.taskData[kanbanName][i].leadStatus?.leadStatusId?.toString() ?? "",
                        clubId: this.taskData[kanbanName][i].clubId,
                        isUnhandled: !this.taskData[kanbanName][i].client && !this.taskData[kanbanName][i].firstName
                    }

                    if (!this.checkForVisibility(tempLead)) continue;

                    this[kanbanName].push(tempLead);
                }

                this[kanbanName] = this[kanbanName].sort(this.sortByDate);
                this[kanbanName] = this[kanbanName].sort(this.sortByStatus);

                for (let i = 0; i < 10; i++) {
                    if (i <= this[kanbanName].length - 1) this[kanbanName][i].isShow = true;
                }
                    
                this[`${kanbanName}Count`] = this.taskData[kanbanName].length;
            },
            checkForVisibility(checkingElement: LeadElement) {
                if (this.filterDate == null && this.filterManager == null && this.filterNextStep == null && this.filterStatus == null && this.checkNameOrPhone(checkingElement)) {
                    return true;
                } else if (this.filterDate != null && this.filterManager == null && this.filterNextStep == null && this.filterStatus == null) {
                    if (new Date(checkingElement.nextStepDate).toDateString() == new Date(this.filterDate).toDateString() && this.checkNameOrPhone(checkingElement)) {
                        return true;
                    } else {
                        return false;
                    }
                } else if (this.filterDate == null && this.filterManager != null && this.filterNextStep == null && this.filterStatus == null) {
                    if (checkingElement.userId == this.filterManager.id && this.checkNameOrPhone(checkingElement)) {
                        return true;
                    } else {
                        return false;
                    }
                } else if (this.filterDate == null && this.filterManager == null && this.filterNextStep != null && this.filterStatus == null) {
                    if (checkingElement.nextStep == this.filterNextStep.id && this.checkNameOrPhone(checkingElement)) {
                        return true;
                    } else {
                        return false;
                    }
                } else if (this.filterDate != null && this.filterManager != null && this.filterNextStep == null && this.filterStatus == null) {
                    if (new Date(checkingElement.nextStepDate).toDateString() == new Date(this.filterDate).toDateString() && checkingElement.userId == this.filterManager.id && this.checkNameOrPhone(checkingElement)) {
                        return true;
                    } else {
                        return false;
                    }
                } else if (this.filterDate == null && this.filterManager != null && this.filterNextStep != null && this.filterStatus == null) {
                    if (checkingElement.userId == this.filterManager.id && checkingElement.nextStep == this.filterNextStep.id && this.checkNameOrPhone(checkingElement)) {
                        return true;
                    } else {
                        return false;
                    }
                } else if (this.filterDate != null && this.filterManager == null && this.filterNextStep != null && this.filterStatus == null) {
                    if (new Date(checkingElement.nextStepDate).toDateString() == new Date(this.filterDate).toDateString() && checkingElement.nextStep == this.filterNextStep.id && this.checkNameOrPhone(checkingElement)) {
                        return true;
                    } else {
                        return false;
                    }
                } else if (this.filterDate != null && this.filterManager != null && this.filterNextStep != null && this.filterStatus == null) {
                    if (new Date(checkingElement.nextStepDate).toDateString() == new Date(this.filterDate).toDateString() && checkingElement.userId == this.filterManager.id && checkingElement.nextStep == this.filterNextStep.id && this.checkNameOrPhone(checkingElement)) {
                        return true;
                    } else {
                        return false;
                    }
                } else if (this.filterDate == null && this.filterManager == null && this.filterNextStep == null && this.filterStatus != null) {
                    if (checkingElement.leadStatusId == this.filterStatus.id && this.checkNameOrPhone(checkingElement)) {
                        return true;
                    } else {
                        return false;
                    }
                } else if (this.filterDate != null && this.filterManager == null && this.filterNextStep == null && this.filterStatus != null) {
                    if (new Date(checkingElement.nextStepDate).toDateString() == new Date(this.filterDate).toDateString() && checkingElement.leadStatusId == this.filterStatus.id && this.checkNameOrPhone(checkingElement)) {
                        return true;
                    } else {
                        return false;
                    }
                } else if (this.filterDate == null && this.filterManager != null && this.filterNextStep == null && this.filterStatus != null) {
                    if (checkingElement.userId == this.filterManager.id && checkingElement.leadStatusId == this.filterStatus.id && this.checkNameOrPhone(checkingElement)) {
                        return true;
                    } else {
                        return false;
                    }
                } else if (this.filterDate == null && this.filterManager == null && this.filterNextStep != null && this.filterStatus != null) {
                    if (checkingElement.nextStep == this.filterNextStep.id && checkingElement.leadStatusId == this.filterStatus.id && this.checkNameOrPhone(checkingElement)) {
                        return true;
                    } else {
                        return false;
                    }
                } else if (this.filterDate != null && this.filterManager != null && this.filterNextStep == null && this.filterStatus != null) {
                    if (new Date(checkingElement.nextStepDate).toDateString() == new Date(this.filterDate).toDateString() && checkingElement.userId == this.filterManager.id && checkingElement.leadStatusId == this.filterStatus.id && this.checkNameOrPhone(checkingElement)) {
                        return true;
                    } else {
                        return false;
                    }
                } else if (this.filterDate == null && this.filterManager != null && this.filterNextStep != null && this.filterStatus != null) {
                    if (checkingElement.userId == this.filterManager.id && checkingElement.nextStep == this.filterNextStep.id && checkingElement.leadStatusId == this.filterStatus.id && this.checkNameOrPhone(checkingElement)) {
                        return true;
                    } else {
                        return false;
                    }
                } else if (this.filterDate != null && this.filterManager == null && this.filterNextStep != null && this.filterStatus != null) {
                    if (new Date(checkingElement.nextStepDate).toDateString() == new Date(this.filterDate).toDateString() && checkingElement.nextStep == this.filterNextStep.id && checkingElement.leadStatusId == this.filterStatus.id && this.checkNameOrPhone(checkingElement)) {
                        return true;
                    } else {
                        return false;
                    }
                } else if (this.filterDate != null && this.filterManager != null && this.filterNextStep != null && this.filterStatus != null) {
                    if (new Date(checkingElement.nextStepDate).toDateString() == new Date(this.filterDate).toDateString() && checkingElement.userId == this.filterManager.id && checkingElement.nextStep  == this.filterNextStep.id && checkingElement.leadStatusId == this.filterStatus.id && this.checkNameOrPhone(checkingElement)) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            },
            checkNameOrPhone(checkingElement: LeadElement) {
                if (!this.searchText || this.searchText.length < 3) return true;
                let tempPhone = this.searchText.replace(/\D/g, '')

                if ((tempPhone !== "" && checkingElement.phone?.replace(/\D/g, '').includes(tempPhone)) || (checkingElement.name?.toLowerCase().includes(this.searchText.toLowerCase()))) {
                    return true;
                } else {
                    return false;
                }
            },
            clickOnIsShowClosed: function () {
                if (this.showCloseDebounce) {
                    clearTimeout(this.showCloseDebounce);
                }

                this.showCloseDebounce = setTimeout(() => {
                    this.getAllTasks();
                }, 600);
            },
            search: function () {
                if (this.searchDebounce) {
                    clearTimeout(this.searchDebounce)
                }

                if (!this.searchText) {
                    this.applyFilter();
                    return;
                }

                this.searchDebounce = setTimeout(() => {
                    this.applyFilter();
                }, 600);
            },
            endMove: function (e: any) {
                if (e.to.id == e.from.id) {
                    return;
                }

                if (e.to.id == "overdueLeads") {
                    let index = this[e.to.id].findIndex(x => x.leadId == e.item.__draggable_context.element.leadId);

                    if (index == -1) return;

                    this[e.to.id].splice(index, 1);

                    this[e.from.id].push(e.item.__draggable_context.element)
                    this[e.from.id].sort(this.sortByDate);
                    this[e.from.id].sort(this.sortByStatus);

                    this[`${e.from.id}Count`] = this[e.from.id].length;
                    this[`${e.to.id}Count`] = this[e.to.id].length;

                    return;
                }

                if (e.to.id == "todayLeads") {
                    let tempDate = e.item.__draggable_context.element.nextStepDate?.split("T");

                    if (!tempDate || tempDate.length != 2) return;

                    this.saveChangedLeadDate(e.item.__draggable_context.element.leadId, e.item.__draggable_context.element.clubId, e.item.__draggable_context.element.nextStepDate);

                    let index = this.taskData[e.from.id].findIndex(x => x.leadId == e.item.__draggable_context.element.leadId);

                    if (index != -1) {
                        this.taskData[e.from.id][index].nextStepDate = `${getFormatedDate(convertToISOString(this.today), "yyyy-MM-DD")}T${tempDate[1]}`;
                        this.taskData[e.to.id].push(this.taskData[e.from.id][index]);
                        this.taskData[e.from.id].splice(index, 1);

                        this.saveChangedLeadDate(e.item.__draggable_context.element.leadId, e.item.__draggable_context.element.clubId, `${getFormatedDate(convertToISOString(this.today), "yyyy-MM-DD")}T${tempDate[1]}`);
                    }

                    this.updateKanban(e.from.id);
                    this.updateKanban(e.to.id);
                   
                    return;
                }

                if (e.to.id == "tomorrowLeads") {
                    let tempDate = e.item.__draggable_context.element.nextStepDate?.split("T");

                    if (!tempDate || tempDate.length != 2) return;

                    let index = this.taskData[e.from.id].findIndex(x => x.leadId == e.item.__draggable_context.element.leadId);

                    if (index != -1) {
                        this.taskData[e.from.id][index].nextStepDate = `${getFormatedDate(convertToISOString(this.tomorrow), "yyyy-MM-DD")}T${tempDate[1]}`;
                        this.taskData[e.to.id].push(this.taskData[e.from.id][index]);
                        this.taskData[e.from.id].splice(index, 1);

                        this.saveChangedLeadDate(e.item.__draggable_context.element.leadId, e.item.__draggable_context.element.clubId, `${getFormatedDate(convertToISOString(this.tomorrow), "yyyy-MM-DD")}T${tempDate[1]}`);
                    }

                    this.updateKanban(e.from.id)
                    this.updateKanban(e.to.id)

                    return;
                }

                if (e.to.id == "thisWeekLeads") {
                    this.openChangeDateWindow(e.item.__draggable_context.element, e.to.id, true)

                    return;
                }

                if (e.to.id == "laterLeads") {
                    this.openChangeDateWindow(e.item.__draggable_context.element, e.to.id, true)

                    return;
                }
            },
            sortByStatus: function (a: LeadElement, b: LeadElement) {
                let aleadStatusId: number = a.leadStatusId ? parseInt(a.leadStatusId) : 0;
                let bleadStatusId: number = b.leadStatusId ? parseInt(b.leadStatusId) : 0;

                if (aleadStatusId > bleadStatusId) {
                    return -1;
                }
                else {
                    return 1;
                }
            },
            sortByDate: function (a: LeadElement, b: LeadElement) {
                if (a.nextStepDate > b.nextStepDate) {
                    return 1;
                }
                else {
                    return -1;
                }
            },
            saveChangedLeadDate: function (leadId: number, clubId: number, nextStepDate: string) {
                axiosRequests.patch(editUrl(Routes.changeLeadDate, leadId), { clubId: clubId, nextStepDate: nextStepDate })
                    .then(() => { })
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 500) {
                                this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            openChangeDateWindow: function (lead: LeadElement, kanban: string, isOpenAfterDrag: boolean = false) {
                this.changeNextStepDateModel.leadId = lead.leadId;
                this.changeNextStepDateModel.nextStepDate = lead.nextStepDate;
                this.changeNextStepDateModel.kanban = kanban;
                this.changeNextStepDateModel.isOpenAfterDrag = isOpenAfterDrag;
                this.changeNextStepDateModel.clubId = lead.clubId;

                if (!isOpenAfterDrag) {
                    this.changeNextStepDateModel.minDate = getFormatedDate(convertToISOString(this.today), "yyyy-MM-DDTHH:mm");
                    this.changeNextStepDateModel.maxDate = null;
                } else {
                    if (kanban == "thisWeekLeads") {
                        this.changeNextStepDateModel.minDate = getFormatedDate(convertToISOString(this.today), "yyyy-MM-DDTHH:mm");
                        this.changeNextStepDateModel.maxDate = getFormatedDate(convertToISOString(this.thisWeekEnd), "yyyy-MM-DDTHH:mm");
                    } else if (kanban == "laterLeads") {
                        this.changeNextStepDateModel.minDate = getFormatedDate(convertToISOString(this.laterBegin), "yyyy-MM-DDTHH:mm");
                        this.changeNextStepDateModel.maxDate = null;
                    }
                }

                this.isShowChangeDate = true;
            },
            closeChangeDate: function (nextStepDate: string) {
                this.isShowChangeDate = false;

                if (!nextStepDate) return;

                if (nextStepDate == this.changeNextStepDateModel.nextStepDate && !this.changeNextStepDateModel.isOpenAfterDrag) return;
                let kanban: string = "";
                let tempNextStepDate = getFormatedDate(convertToISOString(new Date(nextStepDate)), "yyyy-MM-DD");
                if (nextStepDate == this.changeNextStepDateModel.nextStepDate && this.changeNextStepDateModel.isOpenAfterDrag) {
                    let index = this[this.changeNextStepDateModel.kanban].findIndex(x => x.leadId == this.changeNextStepDateModel.leadId);
                    if (index == -1) return;

                    if (tempNextStepDate < getFormatedDate(convertToISOString(new Date(this.today)), "yyyy-MM-DD")) {
                        kanban = "overdueLeads";
                    }
                    else if (tempNextStepDate == getFormatedDate(convertToISOString(new Date(this.today)), "yyyy-MM-DD")) {
                        kanban = "todayLeads";
                    }
                    else if (tempNextStepDate == getFormatedDate(convertToISOString(new Date(this.tomorrow)), "yyyy-MM-DD")) {
                        kanban = "tomorrowLeads";
                    }
                    else if (this.changeNextStepDateModel.kanban == "laterLeads") {
                        kanban = "thisWeekLeads";
                    }
                    else if (this.changeNextStepDateModel.kanban == "thisWeekLeads") {
                        kanban = "laterLeads";
                    }

                    if (!kanban) return;

                    this[kanban].push(this[this.changeNextStepDateModel.kanban][index])
                    this[kanban].sort(this.sortByDate);
                    this[kanban].sort(this.sortByStatus);

                    this[`${kanban}Count`] = this[kanban].length;

                    this[this.changeNextStepDateModel.kanban].splice(index, 1);
                    this[`${this.changeNextStepDateModel.kanban}Count`] = this[this.changeNextStepDateModel.kanban].length;

                    return;
                }

                if (nextStepDate != this.changeNextStepDateModel.nextStepDate) {
                    let index = this.taskData[this.changeNextStepDateModel.kanban].findIndex(x => x.leadId == this.changeNextStepDateModel.leadId);
                    if (index == -1) return;

                    let isKanbanNotChanged: boolean = false;
                    if (tempNextStepDate == getFormatedDate(convertToISOString(new Date(this.today)), "yyyy-MM-DD")) {
                        kanban = "todayLeads";
                        isKanbanNotChanged = this.changeNextStepDateModel.kanban == "todayLeads";
                    }
                    else if (tempNextStepDate == getFormatedDate(convertToISOString(new Date(this.tomorrow)), "yyyy-MM-DD")) {
                        kanban = "tomorrowLeads";
                        isKanbanNotChanged = this.changeNextStepDateModel.kanban == "tomorrowLeads";
                    }
                    else if (tempNextStepDate >= getFormatedDate(convertToISOString(new Date(this.today)), "yyyy-MM-DD") && tempNextStepDate <= getFormatedDate(convertToISOString(new Date(this.thisWeekEnd)), "yyyy-MM-DD")) {
                        kanban = "thisWeekLeads";
                        isKanbanNotChanged = this.changeNextStepDateModel.kanban == "todayLeads";
                    }
                    else if (tempNextStepDate >= getFormatedDate(convertToISOString(new Date(this.laterBegin)), "yyyy-MM-DD")) {
                        kanban = "laterLeads";
                        isKanbanNotChanged = this.changeNextStepDateModel.kanban == "laterLeads";
                    }

                    if (!kanban) return;

                    this.saveChangedLeadDate(this.changeNextStepDateModel.leadId, this.changeNextStepDateModel.clubId, nextStepDate);

                    this.taskData[this.changeNextStepDateModel.kanban][index].nextStepDate = nextStepDate;

                    if (!isKanbanNotChanged) {
                        this.taskData[kanban].push(this.taskData[this.changeNextStepDateModel.kanban][index]);
                        this.taskData[this.changeNextStepDateModel.kanban].splice(index, 1);

                        this.updateKanban(this.changeNextStepDateModel.kanban);
                    }

                    this.updateKanban(kanban);
                }
                

                return;
            },
            openCreateWindow: function (isResetPhone: boolean = true) {
                this.isShowCreate = true;
                if (isResetPhone) this.callingPhone = "";
                
                this.$store.commit('SET_IS_LEAD_OPEN', true);
            },
            closeLeadPopup: async function (needUpdate: boolean) {
                this.isShowCreate = false;
                this.isShowView = false;
                this.$store.commit('SET_IS_LEAD_OPEN', false);

                history.pushState(history.state, "", "/tasks");

                if (needUpdate) await this.getAllTasks()
            },
            openLead: function (leadId: number) {
                this.isShowCreate = false;

                history.pushState(history.state, "", "/tasks/" + leadId);

                this.$store.commit('SET_IS_LEAD_OPEN', true);

                this.popupLeadId = leadId;
                this.isShowView = true;
            },
            cliclOnCallingButton: function () {
                let leadCalling = this.$store.getters.getLeadCalling;

                if (!leadCalling) return;

                if (leadCalling.leadId) {
                    this.openLead(leadCalling.leadId);

                    return;
                }

                if (leadCalling.phone) {
                    this.callingPhone = leadCalling.phone;
                    this.openCreateWindow(false);

                    return;
                }
            },
            scrollKanban: function (e: any) {
                if (Math.abs(e.target.children[0].scrollHeight - e.target.scrollTop - e.target.children[0].clientHeight) < 50) {
                    let kanban = e.target.getAttribute("param-kanban");
                    if (this[`${kanban}ShowIndex`] == this[kanban].length - 1) return;

                    let nextElementCount = 5;
                    if (this[`${kanban}ShowIndex`] + 5 > this[kanban].length - 1) nextElementCount = this[kanban].length - 1 - this[`${kanban}ShowIndex`];

                    for (let i = 1; i <= nextElementCount; i++) {
                        this[kanban][this[`${kanban}ShowIndex`] + i].isShow = true;
                        this[kanban][this[`${kanban}ShowIndex`] - 10 + i].isShow = false;
                    }

                    this[`${kanban}ShowIndex`] = this[`${kanban}ShowIndex`] + nextElementCount;

                    return;
                }

                if (e.target.scrollTop < 50) {
                    let kanban = e.target.getAttribute("param-kanban");
                    if (this[`${kanban}ShowIndex`] == 9) return;

                    let prevElementCount = 5;
                    if (this[`${kanban}ShowIndex`] - 5 < 9) prevElementCount = this[`${kanban}ShowIndex`] - 9;

                    for (let i = 0; i < prevElementCount; i++) {
                        this[kanban][this[`${kanban}ShowIndex`] - 10 - i].isShow = true;
                        this[kanban][this[`${kanban}ShowIndex`] - i].isShow = false;
                    }

                    this[`${kanban}ShowIndex`] = this[`${kanban}ShowIndex`] - prevElementCount;

                    return;
                }
            }
        },
        watch: {
            '$route.params.id': {
                handler: async function (id) {
                    if (!id) return;

                    await this.getAllLeadStatuses();
                    await this.getAllManagers();

                    this.openLead(id);
                },
                deep: true,
                immediate: true
            }
        },
        async beforeMount() {
            let tasksPermission = this.$store.getters.getTasksPermission;
            if (tasksPermission.length == 0) tasksPermission = await retryGetPermissions("lead");

            if (!tasksPermission?.includes('leads')) this.$router.push('/forbidden');

            await this.getAllTasks();
            await this.getAllLeadStatuses();
            await this.getAllManagers();
        },
        mounted() {
            this.callingButton = document.getElementById("callingButton");
            this.callingButton?.addEventListener('click', this.cliclOnCallingButton);  

            window.addEventListener("touchstart", () => this.isMobile = true, { once: true });
            window.addEventListener("mousemove", () => this.isMobile = false, { once: true });

            if (this.$store.getters.getIsCallingButtonClicked) this.cliclOnCallingButton();

            this.$store.commit('SET_IS_CALLING_BUTTON_CLICKED', false);
        },
        beforeUnmount() {
            this.callingButton?.removeEventListener("click", this.cliclOnCallingButton);

            window.removeEventListener("touchstart", () => this.isMobile = true);
            window.removeEventListener("mousemove", () => this.isMobile = false);
        },
        components: { draggable, createTask: CreateTask, changeLeadNextStepDate: ChangeLeadNextStepDate, viewTask: ViewTask }
    });
</script>

<style scoped>
    .leads_list_wrapper {
        display: flex;
        flex-flow: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        margin-top: 8px;
        padding-bottom: 8px;
    }

    .lead_list_wrapper {
        overflow: hidden;
        margin-right: 12px;
        border-radius: 8px;
        min-width: 380px;
        border: 4px solid #f6f7f8;
    }

    .lead_list_wrapper:last-child {
        margin-right: 0px;
    }

    .lead_list_title {
        background-color: #f6f7f8;
        text-align: center;
        font-weight: bold;
        padding: 8px 0px 12px 0px;
    }

    .lead_list {
        background-color: #f6f7f8;
        padding: 0px 4px;
        overflow-x: hidden;
        overflow-y: auto;
        height: calc(100vh - 380px);
    }

    .lead {
        background-color: white;
        margin-bottom: 8px;
        border-radius: 18px;
        overflow:hidden;
        cursor: pointer;
        user-select:none;
    }

    .isClosed {
        background-color: #00a3ba1c;
    }

    .lead_name {
        color: black;
        margin-top: 16px;
        margin-bottom: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(100% - 32px);
        font-weight: bold;
        padding: 0px 16px;
    }

    .lead_phone {
        color: black;
        margin-top: 0px;
        margin-bottom: 8px;
        font-weight: bold;
        padding: 0px 16px;
    }

    .lead_next_step_date {
        margin-top: 12px;
        margin-bottom: 8px;
        cursor: pointer;
        padding: 0px 16px;
        position: relative;
        width: fit-content;
    }

    .lead_date_input {
        width: 0px;
        height: 0px;
        padding: 0px;
        border: none;
        position: absolute;
        bottom: 0px;
        left: 15px;
    }

    .lead_contract_wrapper {
        background-color: #F6F7F8;
        padding: 8px 16px;
        margin-bottom: 8px;
    }

    .lead_contract_title{
        display: flex;
        justify-content: space-between;
    }

    .lead_contact_name {
        margin-top: 4px;
        margin-bottom: 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
    }

    .lead_contract_status {
        display: flex;
        color: #65656F;
        font-size: 14px;
    }

    .lead_dot_margin{
        margin-top: 4px;
    }

    .lead_label {
        color: #65656F;
        font-size: 14px;
    }

    .lead_comment_wrapper {
        padding: 0px 16px;
    }

    .lead_comment {
        font-size: 14px;
        margin-top: 4px;
        margin-bottom: 0px;
    }

    .lead_next_step_wrapper {
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 0px 16px;
    }

    .lead_next_step {
        background-color: #C7E9F3;
        padding: 4px 10px;
        border-radius: 12px;
        color: #0d99ff;
        margin-top: 4px;
        width: fit-content;
        font-size: 14px;
    }

    .lead_status {
        text-align: center;
        padding: 8px;
        margin-bottom: 0px;
        margin-top: 0px;
    }

    .new_lead_wrapper {
        display: flex;
        justify-content: space-between;
    }

    .margin_right {
        margin-right: 16px;
    }

    .margin_top {
        margin-top: 0px;
    }

    .filter_wrapper {
        display: flex;
        flex-flow: wrap;
    }

    .filter_right_wrapper {
        width: calc(50% - 8px);
        display: flex;
        padding-left: 8px
    }

    .filter_left_wrapper {
        width: calc(50% - 8px);
        display: flex;
        padding-right: 8px
    }

    .clear_all_filters {
        color: #21A7D1;
        cursor: pointer;
        margin: 8px 0;
        width: fit-content;
    }

    .filter_button {
        display: none;
    }

    .new_task_button {
        display: flex;
        padding: 10px 24px;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 12px;
        cursor: pointer;
        background-color: #00a3ba;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 12px;
        min-width: 300px;
        color: white;
    }

    .search {
        max-width: calc(50% - 8px);
        margin-left: 16px;
    }

    @media(max-width: 1200px) {
        .filter_wrapper {
            display: block;
        }

        .filter_right_wrapper {
            width: 100%;
            margin-top: 12px;
            padding-left: 0px
        }

        .filter_left_wrapper {
            width: 100%;
            padding-right: 0px
        }

        .lead_list {
            height: calc(100vh - 428px);
        }
    }

    @media(max-width: 768px) {
        .filter_wrapper {
            display: block;
        }

        .filter_right_wrapper {
            display: block;
        }

        .filter_left_wrapper {
            display: block;
        }

        .margin_right {
            margin-right: 0px;
        }

        .margin_top {
            margin-top: 12px;
        }

        .new_lead_wrapper {
            justify-content: unset;
            flex-flow: column-reverse;
        }

        .new_task_button {
            min-width: 100%;
        }

        .search {
            max-width: 100%;
            margin-left: 0px;
            margin-bottom: 12px;
        }

        .lead_list{
            height: calc(100vh - 569px);
        }
    }

    @media(max-width: 550px) {
        .lead_list_wrapper {
            min-width: calc(100% - 8px);
        }
    }

    @media(max-width: 500px) {
        .show_filtes_mobile {
            display: block;
        }

        .hide_filtes_mobile {
            display: none;
        }

        .filter_button {
            display: flex;
            width: 100%;
            padding: 10px 24px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border: none;
            border-radius: 12px;
            cursor: pointer;
            background-color: #f2f2f2;
            font-size: 16px;
            line-height: 24px;
        }

        .leads_list_wrapper {
            margin-top: 16px;
        }

        .lead_list {
            height: calc(100vh - 360px);
        }

        .open_filter {
            height: calc(100vh - 515px) !important;
        }

        .lead_name {
            font-size: 14px;
            line-height: 12px;
        }

        .lead_phone {
            font-size: 14px;
            line-height: 12px;
        }

        .lead_next_step_date {
            font-size: 14px;
            line-height: 12px;
        }

        .lead_contact_name {
            margin-top: 2px;
            font-size: 14px;
        }

        .lead_dot_margin {
            margin-top: 3px;
        }

        .lead_contract_status {
            font-size: 13px;
        }

        .lead_label {
            font-size: 13px;
        }

        .lead_contract_wrapper {
            margin-bottom: 2px;
        }

        .lead_comment_wrapper {
            padding: 0px 16px;
        }

        .lead_comment {
            font-size: 14px;
            margin-top: 2px;
            margin-bottom: 0px;
        }

        .lead_next_step_wrapper {
            margin-top: 4px;
        }

        .lead_next_step {
            font-size: 13px;
        }

        .lead_status {
            font-size: 14px;
        }
    }
</style>