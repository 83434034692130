import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5711a59d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content_wrapper" }
const _hoisted_2 = { class: "mt-0" }
const _hoisted_3 = { class: "contract_wrapper" }
const _hoisted_4 = { class: "elment_wrapper" }
const _hoisted_5 = { class: "elment_wrapper" }
const _hoisted_6 = { class: "elment_wrapper" }
const _hoisted_7 = { class: "elment_wrapper" }
const _hoisted_8 = { class: "elment_wrapper" }
const _hoisted_9 = { class: "w-100 mt-2 mb-3" }
const _hoisted_10 = { class: "flex" }
const _hoisted_11 = { class: "corporate_organizations_wrapper" }
const _hoisted_12 = { class: "elment_wrapper" }
const _hoisted_13 = { class: "elment_wrapper" }
const _hoisted_14 = { class: "agreement_element_wrapper" }
const _hoisted_15 = { class: "agreement_element" }
const _hoisted_16 = { class: "agreement_element_label" }
const _hoisted_17 = { class: "agreement_element" }
const _hoisted_18 = { class: "agreement_element_label" }
const _hoisted_19 = { class: "button_wrapper" }

export function render(_ctx, _cache) {
  const _component_v_select = _resolveComponent("v-select")
  const _component_v_input = _resolveComponent("v-input")
  const _component_v_checkbox = _resolveComponent("v-checkbox")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('contract.create.create_new_contract')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_v_select, {
          class: "elment",
          modelValue: _ctx.selectedClub,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = $event => ((_ctx.selectedClub) = $event)),
            _ctx.selectClub
          ],
          label: 'contract.create.club',
          items: _ctx.clubs,
          errorText: _ctx.clubErrorText,
          onClick: _cache[1] || (_cache[1] = $event => (_ctx.clubErrorText = ''))
        }, null, 8, ["modelValue", "label", "items", "errorText", "onUpdate:modelValue"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_v_select, {
          class: "elment",
          modelValue: _ctx.selectedAgreement,
          "onUpdate:modelValue": [
            _cache[2] || (_cache[2] = $event => ((_ctx.selectedAgreement) = $event)),
            _ctx.selectAgreement
          ],
          label: 'contract.create.agreement',
          items: _ctx.agreements,
          errorText: _ctx.agreementErrorText,
          onClick: _cache[3] || (_cache[3] = $event => (_ctx.agreementErrorText = '')),
          clearable: ""
        }, null, 8, ["modelValue", "label", "items", "errorText", "onUpdate:modelValue"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_v_input, {
          class: "elment",
          modelValue: _ctx.activationDate,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.activationDate) = $event)),
          inputType: "date",
          label: _ctx.$t('contract.create.contract_activation_date'),
          height: "22",
          errorText: _ctx.activationDateErrorText,
          onFocus: _cache[5] || (_cache[5] = $event => (_ctx.activationDateErrorText = '')),
          clearable: ""
        }, null, 8, ["modelValue", "label", "errorText"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_v_input, {
          class: "elment",
          modelValue: _ctx.paymentDate,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.paymentDate) = $event)),
          inputType: "date",
          label: _ctx.$t('contract.create.payment_date'),
          height: "22",
          errorText: _ctx.paymentDateErrorText,
          onFocus: _cache[7] || (_cache[7] = $event => (_ctx.paymentDateErrorText = '')),
          clearable: ""
        }, null, 8, ["modelValue", "label", "errorText"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_v_select, {
          class: "elment",
          modelValue: _ctx.selectedPaymentMethod,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.selectedPaymentMethod) = $event)),
          label: 'contract.create.payment_method',
          items: _ctx.paymentMethods
        }, null, 8, ["modelValue", "label", "items"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_v_checkbox, {
            modelValue: _ctx.isCorporateClient,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.isCorporateClient) = $event)),
            label: _ctx.$t('contract.create.corporate_client')
          }, null, 8, ["modelValue", "label"])
        ])
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_v_select, {
            class: "elment",
            modelValue: _ctx.selectedOrganizations,
            "onUpdate:modelValue": [
              _cache[10] || (_cache[10] = $event => ((_ctx.selectedOrganizations) = $event)),
              _ctx.selectOrganization
            ],
            label: 'contract.create.organization',
            items: _ctx.organizations
          }, null, 8, ["modelValue", "label", "items", "onUpdate:modelValue"])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _withDirectives(_createVNode(_component_v_input, {
            class: "elment new_organization_name",
            modelValue: _ctx.organizationName,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => ((_ctx.organizationName) = $event)),
            label: _ctx.$t('contract.create.organization_name'),
            height: "22",
            errorText: _ctx.organizationNameErrorText,
            onFocus: _cache[12] || (_cache[12] = $event => (_ctx.organizationNameErrorText = '')),
            clearable: ""
          }, null, 8, ["modelValue", "label", "errorText"]), [
            [_vShow, !_ctx.selectedOrganizations?.id]
          ])
        ])
      ], 512), [
        [_vShow, _ctx.isCorporateClient]
      ])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.selectedAgreement?.text), 1),
        _createVNode(_component_v_input, {
          modelValue: _ctx.tempContractPrice,
          "onUpdate:modelValue": [
            _cache[13] || (_cache[13] = $event => ((_ctx.tempContractPrice) = $event)),
            _ctx.getTotalPrice
          ],
          inputType: "number",
          label: `${_ctx.$t('contract.create.price')}, ${_ctx.currencySign}`,
          height: "22",
          min: 0,
          step: 0.01,
          onBlur: _ctx.checkContractPrice
        }, null, 8, ["modelValue", "label", "onBlur", "onUpdate:modelValue"])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tempSevices, (service, index) => {
        return (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _createElementVNode("p", _hoisted_18, _toDisplayString(service.name), 1),
          _createVNode(_component_v_input, {
            modelValue: service.price,
            "onUpdate:modelValue": [$event => ((service.price) = $event), _ctx.getTotalPrice],
            inputType: "number",
            label: `${_ctx.$t('contract.create.price')}, ${_ctx.currencySign}`,
            height: "22",
            min: 0,
            step: 0.01,
            onBlur: $event => (_ctx.checkServicePrice(index))
          }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "onBlur"])
        ]))
      }), 256))
    ], 512), [
      [_vShow, _ctx.selectedAgreement]
    ]),
    _withDirectives(_createElementVNode("p", { class: "total_price" }, _toDisplayString(_ctx.$t('contract.create.total_price')) + ": " + _toDisplayString(_ctx.getFormatedAmount(_ctx.totalPrice * 100)) + " " + _toDisplayString(_ctx.currencySign), 513), [
      [_vShow, _ctx.selectedAgreement]
    ]),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("button", {
        class: "primary_button button_space",
        onClick: _cache[14] || (_cache[14] = (...args) => (_ctx.save && _ctx.save(...args)))
      }, _toDisplayString(_ctx.$t('contract.create.create')), 1),
      _createElementVNode("button", {
        class: "secondary_button",
        onClick: _cache[15] || (_cache[15] = $event => (_ctx.$router.go(-1)))
      }, _toDisplayString(_ctx.$t('contract.create.back')), 1)
    ])
  ]))
}