import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bb8124a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "view_training_wrapper" }
const _hoisted_2 = { class: "view_training_body" }
const _hoisted_3 = { class: "view_training_title" }
const _hoisted_4 = { class: "view_training" }
const _hoisted_5 = { class: "view_training_info_wrapper" }
const _hoisted_6 = { class: "blocks_wrapper" }
const _hoisted_7 = { class: "block" }
const _hoisted_8 = { class: "block_label" }
const _hoisted_9 = { class: "block" }
const _hoisted_10 = { class: "block_label" }
const _hoisted_11 = {
  key: 1,
  class: "blocks_wrapper"
}
const _hoisted_12 = { class: "mb-4" }
const _hoisted_13 = { class: "block_label" }
const _hoisted_14 = { class: "blocks_wrapper" }
const _hoisted_15 = { class: "block" }
const _hoisted_16 = { class: "block_label" }
const _hoisted_17 = { class: "block" }
const _hoisted_18 = { class: "block_label" }
const _hoisted_19 = ["disabled"]
const _hoisted_20 = ["disabled"]
const _hoisted_21 = { class: "view_training_clients_wrapper" }
const _hoisted_22 = { class: "view_training_clients" }
const _hoisted_23 = { class: "view_training_clients_title" }
const _hoisted_24 = {
  key: 0,
  class: "view_training_clients_body"
}
const _hoisted_25 = { class: "client_wrapper" }
const _hoisted_26 = { class: "client_info_wrapper" }
const _hoisted_27 = { class: "flex" }
const _hoisted_28 = ["onClick"]
const _hoisted_29 = { class: "client_phone" }
const _hoisted_30 = { class: "client_pay_wrapper" }
const _hoisted_31 = ["onClick"]
const _hoisted_32 = {
  key: 0,
  class: "client_pay_amount"
}
const _hoisted_33 = { key: 0 }
const _hoisted_34 = { key: 1 }
const _hoisted_35 = {
  key: 1,
  class: "view_training_clients_body"
}
const _hoisted_36 = { class: "client_wrapper" }
const _hoisted_37 = { class: "client_wait_list_info_wrapper" }
const _hoisted_38 = { class: "flex" }
const _hoisted_39 = ["onClick"]
const _hoisted_40 = { class: "client_phone" }

export function render(_ctx, _cache) {
  const _component_v_alert = _resolveComponent("v-alert")
  const _component_registerClient = _resolveComponent("registerClient")
  const _component_retryToPay = _resolveComponent("retryToPay")
  const _component_v_select = _resolveComponent("v-select")
  const _directive_click_outside = _resolveDirective("click-outside")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_alert, {
      modelValue: _ctx.isShowAlert,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.isShowAlert) = $event)),
      title: 'timetable.view.alert_title',
      text: 'timetable.view.are_you_sure',
      successButtonText: _ctx.$t('timetable.view.confirm'),
      cancelButtonText: _ctx.$t('timetable.view.cancel'),
      onSuccess: _ctx.deleteTraining,
      onCancel: _cache[1] || (_cache[1] = $event => (_ctx.isShowAlert = false))
    }, null, 8, ["modelValue", "title", "text", "successButtonText", "cancelButtonText", "onSuccess"]),
    (_ctx.isShowRegisterClient)
      ? (_openBlock(), _createBlock(_component_registerClient, {
          key: 0,
          training: _ctx.training,
          onClose: _ctx.closeWindow
        }, null, 8, ["training", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.isShowRetry)
      ? (_openBlock(), _createBlock(_component_retryToPay, {
          key: 1,
          client: _ctx.clientForRetryToPay,
          onClose: _ctx.closeWindow
        }, null, 8, ["client", "onClose"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createTextVNode(_toDisplayString(_ctx.training?.trainingClassName) + " ", 1),
          _createElementVNode("img", {
            class: "close_button",
            src: "/assets/img/close.svg",
            onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.close && _ctx.close(...args)))
          })
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('timetable.view.status')), 1),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.training?.isTrainerAccepted == null ? 'not_accept' : _ctx.training?.isTrainerAccepted == true ? 'accept' : 'reject')
                }, _toDisplayString(_ctx.training?.isTrainerAccepted == null ? _ctx.$t('timetable.view.not_accept') : _ctx.training?.isTrainerAccepted == true ? _ctx.$t('timetable.view.accept') : _ctx.$t('timetable.view.reject')), 3)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('timetable.view.start_date')), 1),
                _createElementVNode("div", null, _toDisplayString(_ctx.getFormatedDate(_ctx.training?.trainingStartDate, 'DD.MM.yyyy HH:mm')), 1)
              ])
            ]),
            (!_ctx.isPassed)
              ? (_openBlock(), _createBlock(_component_v_select, {
                  key: 0,
                  class: "mb-4",
                  modelValue: _ctx.selectedTrainer,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.selectedTrainer) = $event)),
                  label: 'timetable.view.trainer',
                  items: _ctx.training?.trainers
                }, null, 8, ["modelValue", "label", "items"]))
              : _createCommentVNode("", true),
            (_ctx.isPassed)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('timetable.view.trainer')), 1),
                    _createElementVNode("div", null, _toDisplayString(_ctx.selectedTrainer?.text), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('timetable.view.capacity')), 1),
                _createElementVNode("div", null, _toDisplayString(_ctx.training?.capacity), 1)
              ]),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t('timetable.view.load')), 1),
                _createElementVNode("div", null, _toDisplayString(_ctx.training?.load), 1)
              ])
            ]),
            (!_ctx.isPassed)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: _normalizeClass(_ctx.hasAddClientPermission ? 'view_training_buttons_wrapper_with_add' : 'view_training_buttons_wrapper')
                }, [
                  (_ctx.hasUpdatePermission)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "save_button",
                        onClick: _cache[4] || (_cache[4] = (...args) => (_ctx.save && _ctx.save(...args))),
                        disabled: _ctx.isButtonDisabled
                      }, _toDisplayString(_ctx.$t('timetable.view.save')), 9, _hoisted_19))
                    : _createCommentVNode("", true),
                  _createElementVNode("button", {
                    class: "delete_button",
                    onClick: _cache[5] || (_cache[5] = $event => (_ctx.isShowAlert = true)),
                    disabled: _ctx.isButtonDisabled
                  }, _toDisplayString(_ctx.$t('timetable.view.delete')), 9, _hoisted_20)
                ], 2))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("div", {
                  class: _normalizeClass(["view_training_clients_title_element view_training_clients_title_element_first", { active: _ctx.isShowRegistered}]),
                  onClick: _cache[6] || (_cache[6] = $event => (_ctx.isShowRegistered = true))
                }, _toDisplayString(_ctx.$t('timetable.view.registered')), 3),
                _createElementVNode("div", {
                  class: _normalizeClass(["view_training_clients_title_element", { active: !_ctx.isShowRegistered}]),
                  onClick: _cache[7] || (_cache[7] = $event => (_ctx.isShowRegistered = false))
                }, _toDisplayString(_ctx.$t('timetable.view.awaited')), 3)
              ]),
              (_ctx.isShowRegistered)
                ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.training?.registeredClients, (client) => {
                      return (_openBlock(), _createElementBlock("div", _hoisted_25, [
                        _createElementVNode("div", {
                          class: "client_photo",
                          style: _normalizeStyle({ 'background-image': `url(${client?.photoUrl})` })
                        }, null, 4),
                        _createElementVNode("div", _hoisted_26, [
                          _createElementVNode("div", _hoisted_27, [
                            _createElementVNode("p", {
                              class: "client_name",
                              onClick: $event => (_ctx.redirectToClient(client?.id))
                            }, _toDisplayString(client?.fullName), 9, _hoisted_28)
                          ]),
                          _createElementVNode("p", _hoisted_29, _toDisplayString(_ctx.addPhoneMask(client.phoneWithCountryCode)), 1)
                        ]),
                        _createElementVNode("div", _hoisted_30, [
                          _createElementVNode("div", {
                            class: _normalizeClass(client?.isPayed || !_ctx.hasRetryPermission ? 'client_payed' : 'client_not_payed'),
                            onClick: $event => (_ctx.openRetryWindow(client))
                          }, [
                            _createElementVNode("div", {
                              class: _normalizeClass(client?.isPayed ? 'dot mt-1 payed' : 'dot mt-1 not_payed')
                            }, null, 2),
                            _createTextVNode(_toDisplayString(client?.isPayed ? _ctx.$t('timetable.view.payed') : _ctx.$t('timetable.view.not_payed')), 1)
                          ], 10, _hoisted_31),
                          (client?.isPayed)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_32, [
                                (client?.paymentMethod == 'training_payment_method.training_block')
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_33, _toDisplayString(_ctx.$t('timetable.view.from_block')), 1))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_34, _toDisplayString(_ctx.getFormatedAmount(client?.amount)) + " " + _toDisplayString(client?.currencySign), 1))
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    }), 256))
                  ]))
                : _createCommentVNode("", true),
              (!_ctx.isShowRegistered)
                ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.training?.waitingList, (client) => {
                      return (_openBlock(), _createElementBlock("div", _hoisted_36, [
                        _createElementVNode("div", {
                          class: "client_photo",
                          style: _normalizeStyle({ 'background-image': `url(${client?.photoUrl})` })
                        }, null, 4),
                        _createElementVNode("div", _hoisted_37, [
                          _createElementVNode("div", _hoisted_38, [
                            _createElementVNode("p", {
                              class: "client_name",
                              onClick: $event => (_ctx.redirectToClient(client?.id))
                            }, _toDisplayString(client?.fullName), 9, _hoisted_39)
                          ]),
                          _createElementVNode("p", _hoisted_40, _toDisplayString(_ctx.addPhoneMask(client.phoneWithCountryCode)), 1)
                        ])
                      ]))
                    }), 256))
                  ]))
                : _createCommentVNode("", true)
            ]),
            (_ctx.hasAddClientPermission)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  class: "add_client",
                  onClick: _cache[8] || (_cache[8] = $event => (_ctx.isShowRegisterClient = true))
                }, _toDisplayString(_ctx.$t('timetable.view.add_client')), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ])), [
        [_directive_click_outside, _ctx.clickOutside]
      ])
    ])
  ], 64))
}