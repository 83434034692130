import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71c6c8c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content_wrapper wrapper" }
const _hoisted_2 = { class: "new_lead_wrapper" }
const _hoisted_3 = { class: "filter_wrapper pt-2" }
const _hoisted_4 = { class: "filter_left_wrapper" }
const _hoisted_5 = { class: "filter_right_wrapper" }
const _hoisted_6 = { class: "w-100 mt-3" }
const _hoisted_7 = { src: "/assets/img/filter.svg" }
const _hoisted_8 = {
  class: "leads_list_wrapper",
  id: "leads_list_wrapper"
}
const _hoisted_9 = { class: "lead_list_wrapper" }
const _hoisted_10 = { class: "lead_list_title" }
const _hoisted_11 = { class: "m-0" }
const _hoisted_12 = ["onDblclick"]
const _hoisted_13 = { class: "lead_body" }
const _hoisted_14 = { class: "lead_name" }
const _hoisted_15 = { class: "lead_phone" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = {
  key: 0,
  class: "lead_contract_wrapper"
}
const _hoisted_18 = { class: "lead_contract_title" }
const _hoisted_19 = { class: "lead_label" }
const _hoisted_20 = { class: "lead_contract_status" }
const _hoisted_21 = { class: "lead_contact_name" }
const _hoisted_22 = { class: "lead_comment_wrapper" }
const _hoisted_23 = { class: "lead_label" }
const _hoisted_24 = { class: "lead_comment" }
const _hoisted_25 = { class: "lead_next_step_wrapper" }
const _hoisted_26 = { class: "lead_label" }
const _hoisted_27 = { class: "lead_next_step" }
const _hoisted_28 = { class: "lead_list_wrapper" }
const _hoisted_29 = { class: "lead_list_title" }
const _hoisted_30 = { class: "m-0" }
const _hoisted_31 = ["onDblclick"]
const _hoisted_32 = { class: "lead_body" }
const _hoisted_33 = { class: "lead_name" }
const _hoisted_34 = { class: "lead_phone" }
const _hoisted_35 = ["onClick"]
const _hoisted_36 = {
  key: 0,
  class: "lead_contract_wrapper"
}
const _hoisted_37 = { class: "lead_contract_title" }
const _hoisted_38 = { class: "lead_label" }
const _hoisted_39 = { class: "lead_contract_status" }
const _hoisted_40 = { class: "lead_contact_name" }
const _hoisted_41 = { class: "lead_comment_wrapper" }
const _hoisted_42 = { class: "lead_label" }
const _hoisted_43 = { class: "lead_comment" }
const _hoisted_44 = { class: "lead_next_step_wrapper" }
const _hoisted_45 = { class: "lead_label" }
const _hoisted_46 = { class: "lead_next_step" }
const _hoisted_47 = { class: "lead_list_wrapper" }
const _hoisted_48 = { class: "lead_list_title" }
const _hoisted_49 = { class: "m-0" }
const _hoisted_50 = ["onDblclick"]
const _hoisted_51 = { class: "lead_body" }
const _hoisted_52 = { class: "lead_name" }
const _hoisted_53 = { class: "lead_phone" }
const _hoisted_54 = ["onClick"]
const _hoisted_55 = {
  key: 0,
  class: "lead_contract_wrapper"
}
const _hoisted_56 = { class: "lead_contract_title" }
const _hoisted_57 = { class: "lead_label" }
const _hoisted_58 = { class: "lead_contract_status" }
const _hoisted_59 = { class: "lead_contact_name" }
const _hoisted_60 = { class: "lead_comment_wrapper" }
const _hoisted_61 = { class: "lead_label" }
const _hoisted_62 = { class: "lead_comment" }
const _hoisted_63 = { class: "lead_next_step_wrapper" }
const _hoisted_64 = { class: "lead_label" }
const _hoisted_65 = { class: "lead_next_step" }
const _hoisted_66 = { class: "lead_list_wrapper" }
const _hoisted_67 = { class: "lead_list_title" }
const _hoisted_68 = { class: "m-0" }
const _hoisted_69 = ["onDblclick"]
const _hoisted_70 = { class: "lead_body" }
const _hoisted_71 = { class: "lead_name" }
const _hoisted_72 = { class: "lead_phone" }
const _hoisted_73 = ["onClick"]
const _hoisted_74 = {
  key: 0,
  class: "lead_contract_wrapper"
}
const _hoisted_75 = { class: "lead_contract_title" }
const _hoisted_76 = { class: "lead_label" }
const _hoisted_77 = { class: "lead_contract_status" }
const _hoisted_78 = { class: "lead_contact_name" }
const _hoisted_79 = { class: "lead_comment_wrapper" }
const _hoisted_80 = { class: "lead_label" }
const _hoisted_81 = { class: "lead_comment" }
const _hoisted_82 = { class: "lead_next_step_wrapper" }
const _hoisted_83 = { class: "lead_label" }
const _hoisted_84 = { class: "lead_next_step" }
const _hoisted_85 = { class: "lead_list_wrapper" }
const _hoisted_86 = { class: "lead_list_title" }
const _hoisted_87 = { class: "m-0" }
const _hoisted_88 = ["onDblclick"]
const _hoisted_89 = { class: "lead_body" }
const _hoisted_90 = { class: "lead_name" }
const _hoisted_91 = { class: "lead_phone" }
const _hoisted_92 = ["onClick"]
const _hoisted_93 = {
  key: 0,
  class: "lead_contract_wrapper"
}
const _hoisted_94 = { class: "lead_contract_title" }
const _hoisted_95 = { class: "lead_label" }
const _hoisted_96 = { class: "lead_contract_status" }
const _hoisted_97 = { class: "lead_contact_name" }
const _hoisted_98 = { class: "lead_comment_wrapper" }
const _hoisted_99 = { class: "lead_label" }
const _hoisted_100 = { class: "lead_comment" }
const _hoisted_101 = { class: "lead_next_step_wrapper" }
const _hoisted_102 = { class: "lead_label" }
const _hoisted_103 = { class: "lead_next_step" }
const _hoisted_104 = { class: "lead_list_wrapper" }
const _hoisted_105 = { class: "lead_list_title" }
const _hoisted_106 = { class: "m-0" }
const _hoisted_107 = ["onDblclick"]
const _hoisted_108 = { class: "lead_body" }
const _hoisted_109 = { class: "lead_name" }
const _hoisted_110 = { class: "lead_phone" }
const _hoisted_111 = ["onClick"]
const _hoisted_112 = {
  key: 0,
  class: "lead_contract_wrapper"
}
const _hoisted_113 = { class: "lead_contract_title" }
const _hoisted_114 = { class: "lead_label" }
const _hoisted_115 = { class: "lead_contract_status" }
const _hoisted_116 = { class: "lead_contact_name" }
const _hoisted_117 = { class: "lead_comment_wrapper" }
const _hoisted_118 = { class: "lead_label" }
const _hoisted_119 = { class: "lead_comment" }
const _hoisted_120 = { class: "lead_next_step_wrapper" }
const _hoisted_121 = { class: "lead_label" }
const _hoisted_122 = { class: "lead_next_step" }

export function render(_ctx, _cache) {
  const _component_createTask = _resolveComponent("createTask")
  const _component_viewTask = _resolveComponent("viewTask")
  const _component_changeLeadNextStepDate = _resolveComponent("changeLeadNextStepDate")
  const _component_v_input = _resolveComponent("v-input")
  const _component_v_select = _resolveComponent("v-select")
  const _component_v_checkbox = _resolveComponent("v-checkbox")
  const _component_draggable = _resolveComponent("draggable")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isShowCreate)
      ? (_openBlock(), _createBlock(_component_createTask, {
          key: 0,
          managers: _ctx.managers,
          statuses: _ctx.statuses,
          callingPhone: _ctx.callingPhone,
          onClose: _ctx.closeLeadPopup,
          onOpenLead: _ctx.openLead
        }, null, 8, ["managers", "statuses", "callingPhone", "onClose", "onOpenLead"]))
      : _createCommentVNode("", true),
    (_ctx.isShowView)
      ? (_openBlock(), _createBlock(_component_viewTask, {
          key: 1,
          managers: _ctx.managers,
          statuses: _ctx.statuses,
          leadId: _ctx.popupLeadId,
          onClose: _ctx.closeLeadPopup
        }, null, 8, ["managers", "statuses", "leadId", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.isShowChangeDate)
      ? (_openBlock(), _createBlock(_component_changeLeadNextStepDate, {
          key: 2,
          data: _ctx.changeNextStepDateModel,
          onClose: _ctx.closeChangeDate
        }, null, 8, ["data", "onClose"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          class: "new_task_button",
          onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.openCreateWindow && _ctx.openCreateWindow(...args)))
        }, _toDisplayString(_ctx.$t('task.add_task')), 1),
        _createVNode(_component_v_input, {
          class: "search",
          modelValue: _ctx.searchText,
          "onUpdate:modelValue": [
            _cache[1] || (_cache[1] = $event => ((_ctx.searchText) = $event)),
            _ctx.search
          ],
          label: _ctx.$t('task.search'),
          placeholder: _ctx.$t('task.search_placeholder'),
          height: "24",
          inputType: "text",
          clearable: ""
        }, null, 8, ["modelValue", "label", "placeholder", "onUpdate:modelValue"])
      ], 512), [
        [_vShow, !_ctx.isShowFilters]
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.isShowFilters ? 'show_filtes_mobile' : 'hide_filtes_mobile')
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_v_select, {
              modelValue: _ctx.filterStatus,
              "onUpdate:modelValue": [
                _cache[2] || (_cache[2] = $event => ((_ctx.filterStatus) = $event)),
                _ctx.applyFilter
              ],
              label: 'task.status',
              class: "w-100 margin_right",
              items: _ctx.statuses,
              clearable: ""
            }, null, 8, ["modelValue", "label", "items", "onUpdate:modelValue"]),
            _createVNode(_component_v_select, {
              modelValue: _ctx.filterManager,
              "onUpdate:modelValue": [
                _cache[3] || (_cache[3] = $event => ((_ctx.filterManager) = $event)),
                _ctx.applyFilter
              ],
              label: 'task.manager',
              class: "w-100 margin_top",
              items: _ctx.managers,
              clearable: ""
            }, null, 8, ["modelValue", "label", "items", "onUpdate:modelValue"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_v_select, {
              class: "w-100 margin_right",
              modelValue: _ctx.filterNextStep,
              "onUpdate:modelValue": [
                _cache[4] || (_cache[4] = $event => ((_ctx.filterNextStep) = $event)),
                _ctx.applyFilter
              ],
              label: 'task.next_step',
              items: _ctx.nextSteps,
              clearable: ""
            }, null, 8, ["modelValue", "label", "items", "onUpdate:modelValue"]),
            _createVNode(_component_v_input, {
              class: "w-100 margin_top",
              modelValue: _ctx.filterDate,
              "onUpdate:modelValue": [
                _cache[5] || (_cache[5] = $event => ((_ctx.filterDate) = $event)),
                _ctx.applyFilter
              ],
              label: _ctx.$t('task.date'),
              inputType: "date",
              height: "22",
              clearable: ""
            }, null, 8, ["modelValue", "label", "onUpdate:modelValue"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_v_checkbox, {
              modelValue: _ctx.isShowClosed,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.isShowClosed) = $event)),
              label: _ctx.$t('task.show_closed'),
              onClick: _ctx.clickOnIsShowClosed
            }, null, 8, ["modelValue", "label", "onClick"])
          ])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("p", {
            onClick: _cache[7] || (_cache[7] = (...args) => (_ctx.clearAllFilters && _ctx.clearAllFilters(...args))),
            class: "clear_all_filters"
          }, _toDisplayString(_ctx.$t('task.clear_all_filters')), 1)
        ])
      ], 2),
      _createElementVNode("button", {
        class: "filter_button",
        onClick: _cache[8] || (_cache[8] = $event => (_ctx.isShowFilters = !_ctx.isShowFilters))
      }, [
        _createTextVNode(_toDisplayString(!_ctx.isShowFilters ? _ctx.$t('task.filter') : _ctx.$t('task.hide')) + " ", 1),
        _withDirectives(_createElementVNode("img", _hoisted_7, null, 512), [
          [_vShow, !_ctx.isShowFilters]
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("p", _hoisted_11, [
              _createTextVNode(_toDisplayString(_ctx.$t('task.unprocessed')), 1),
              _withDirectives(_createElementVNode("span", null, "(" + _toDisplayString(_ctx.unprocessedLeadsCount) + ")", 513), [
                [_vShow, _ctx.unprocessedLeadsCount > 0]
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["lead_list", {open_filter: _ctx.isShowFilters}]),
            "param-kanban": "unprocessedLeads",
            onScroll: _cache[9] || (_cache[9] = (...args) => (_ctx.scrollKanban && _ctx.scrollKanban(...args)))
          }, [
            _createVNode(_component_draggable, {
              list: _ctx.unprocessedLeads,
              group: "lead",
              "item-key": "leadId",
              disabled: true,
              sort: false,
              class: "h-100",
              id: "unprocessedLeads"
            }, {
              item: _withCtx(({element}) => [
                _withDirectives(_createElementVNode("div", {
                  class: _normalizeClass(element.leadClass),
                  style: _normalizeStyle({ 'border': `1px solid ${element.leadColor}`, 'backgroundColor': `${element.isUnhandled ? '#FBE0E0' : '#fff'}` }),
                  onDblclick: $event => (_ctx.openLead(element.leadId))
                }, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("p", _hoisted_14, _toDisplayString(element.name), 1),
                    _createElementVNode("p", _hoisted_15, _toDisplayString(element.phone), 1),
                    _createElementVNode("div", {
                      class: "lead_next_step_date",
                      onClick: $event => (_ctx.openChangeDateWindow(element, 'unprocessedLeads'))
                    }, _toDisplayString(element.nextStepDateText), 9, _hoisted_16),
                    (element.isClient)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                          _createElementVNode("div", _hoisted_18, [
                            _createElementVNode("label", _hoisted_19, _toDisplayString(element.contractLabel), 1),
                            _createElementVNode("div", _hoisted_20, [
                              _createElementVNode("div", {
                                class: _normalizeClass(element.contractDotClass)
                              }, null, 2),
                              _createTextVNode(_toDisplayString(element.contractStatus), 1)
                            ])
                          ]),
                          _createElementVNode("p", _hoisted_21, _toDisplayString(element.contractName), 1)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("label", _hoisted_23, _toDisplayString(element.commentLabel), 1),
                      _createElementVNode("p", _hoisted_24, _toDisplayString(element.comment), 1)
                    ]),
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("label", _hoisted_26, _toDisplayString(element.labelNextStep), 1),
                      _createElementVNode("div", _hoisted_27, _toDisplayString(element.nextStep), 1)
                    ])
                  ]),
                  _withDirectives(_createElementVNode("p", {
                    class: "lead_status",
                    style: _normalizeStyle({ 'background-color': element.leadColor ?? '#fff', 'color': element.leadTextColor })
                  }, _toDisplayString(element.leadStatus), 5), [
                    [_vShow, element.leadStatus]
                  ])
                ], 46, _hoisted_12), [
                  [_vShow, element.isShow]
                ])
              ]),
              _: 1
            }, 8, ["list"])
          ], 34)
        ]),
        _createElementVNode("div", _hoisted_28, [
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("p", _hoisted_30, [
              _createTextVNode(_toDisplayString(_ctx.$t('task.overdue')), 1),
              _withDirectives(_createElementVNode("span", null, "(" + _toDisplayString(_ctx.overdueLeadsCount) + ")", 513), [
                [_vShow, _ctx.overdueLeadsCount > 0]
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["lead_list", {open_filter: _ctx.isShowFilters}]),
            "param-kanban": "overdueLeads",
            onScroll: _cache[10] || (_cache[10] = (...args) => (_ctx.scrollKanban && _ctx.scrollKanban(...args)))
          }, [
            _createVNode(_component_draggable, {
              list: _ctx.overdueLeads,
              group: "lead",
              disabled: _ctx.isMobile,
              "item-key": "leadId",
              sort: false,
              class: "h-100",
              onEnd: _ctx.endMove,
              forceFallback: true,
              id: "overdueLeads"
            }, {
              item: _withCtx(({element}) => [
                _withDirectives(_createElementVNode("div", {
                  class: _normalizeClass(element.leadClass),
                  style: _normalizeStyle({ 'border': `1px solid ${element.leadColor}`, 'backgroundColor': `${element.isUnhandled ? '#FBE0E0' : '#fff'}` }),
                  onDblclick: $event => (_ctx.openLead(element.leadId))
                }, [
                  _createElementVNode("div", _hoisted_32, [
                    _createElementVNode("p", _hoisted_33, _toDisplayString(element.name), 1),
                    _createElementVNode("p", _hoisted_34, _toDisplayString(element.phone), 1),
                    _createElementVNode("div", {
                      class: "lead_next_step_date",
                      onClick: $event => (_ctx.openChangeDateWindow(element, 'overdueLeads'))
                    }, _toDisplayString(element.nextStepDateText), 9, _hoisted_35),
                    (element.isClient)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                          _createElementVNode("div", _hoisted_37, [
                            _createElementVNode("label", _hoisted_38, _toDisplayString(element.contractLabel), 1),
                            _createElementVNode("div", _hoisted_39, [
                              _createElementVNode("div", {
                                class: _normalizeClass(element.contractDotClass)
                              }, null, 2),
                              _createTextVNode(_toDisplayString(element.contractStatus), 1)
                            ])
                          ]),
                          _createElementVNode("p", _hoisted_40, _toDisplayString(element.contractName), 1)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_41, [
                      _createElementVNode("label", _hoisted_42, _toDisplayString(element.commentLabel), 1),
                      _createElementVNode("p", _hoisted_43, _toDisplayString(element.comment), 1)
                    ]),
                    _createElementVNode("div", _hoisted_44, [
                      _createElementVNode("label", _hoisted_45, _toDisplayString(element.labelNextStep), 1),
                      _createElementVNode("div", _hoisted_46, _toDisplayString(element.nextStep), 1)
                    ])
                  ]),
                  _withDirectives(_createElementVNode("p", {
                    class: "lead_status",
                    style: _normalizeStyle({ 'background-color': element.leadColor ?? '#fff', 'color': element.leadTextColor })
                  }, _toDisplayString(element.leadStatus), 5), [
                    [_vShow, element.leadStatus]
                  ])
                ], 46, _hoisted_31), [
                  [_vShow, element.isShow]
                ])
              ]),
              _: 1
            }, 8, ["list", "disabled", "onEnd"])
          ], 34)
        ]),
        _createElementVNode("div", _hoisted_47, [
          _createElementVNode("div", _hoisted_48, [
            _createElementVNode("p", _hoisted_49, [
              _createTextVNode(_toDisplayString(_ctx.$t('task.today')), 1),
              _withDirectives(_createElementVNode("span", null, "(" + _toDisplayString(_ctx.todayLeadsCount) + ")", 513), [
                [_vShow, _ctx.todayLeadsCount > 0]
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["lead_list", {open_filter: _ctx.isShowFilters}]),
            "param-kanban": "todayLeads",
            onScroll: _cache[11] || (_cache[11] = (...args) => (_ctx.scrollKanban && _ctx.scrollKanban(...args)))
          }, [
            _createVNode(_component_draggable, {
              list: _ctx.todayLeads,
              group: "lead",
              disabled: _ctx.isMobile,
              "item-key": "leadId",
              sort: false,
              class: "h-100",
              onEnd: _ctx.endMove,
              forceFallback: true,
              id: "todayLeads"
            }, {
              item: _withCtx(({element}) => [
                _withDirectives(_createElementVNode("div", {
                  class: _normalizeClass(element.leadClass),
                  style: _normalizeStyle({ 'border': `1px solid ${element.leadColor}`, 'backgroundColor': `${element.isUnhandled ? '#FBE0E0' : '#fff'}` }),
                  onDblclick: $event => (_ctx.openLead(element.leadId))
                }, [
                  _createElementVNode("div", _hoisted_51, [
                    _createElementVNode("p", _hoisted_52, _toDisplayString(element.name), 1),
                    _createElementVNode("p", _hoisted_53, _toDisplayString(element.phone), 1),
                    _createElementVNode("div", {
                      class: "lead_next_step_date",
                      onClick: $event => (_ctx.openChangeDateWindow(element, 'todayLeads'))
                    }, _toDisplayString(element.nextStepDateText), 9, _hoisted_54),
                    (element.isClient)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_55, [
                          _createElementVNode("div", _hoisted_56, [
                            _createElementVNode("label", _hoisted_57, _toDisplayString(element.contractLabel), 1),
                            _createElementVNode("div", _hoisted_58, [
                              _createElementVNode("div", {
                                class: _normalizeClass(element.contractDotClass)
                              }, null, 2),
                              _createTextVNode(_toDisplayString(element.contractStatus), 1)
                            ])
                          ]),
                          _createElementVNode("p", _hoisted_59, _toDisplayString(element.contractName), 1)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_60, [
                      _createElementVNode("label", _hoisted_61, _toDisplayString(element.commentLabel), 1),
                      _createElementVNode("p", _hoisted_62, _toDisplayString(element.comment), 1)
                    ]),
                    _createElementVNode("div", _hoisted_63, [
                      _createElementVNode("label", _hoisted_64, _toDisplayString(element.labelNextStep), 1),
                      _createElementVNode("div", _hoisted_65, _toDisplayString(element.nextStep), 1)
                    ])
                  ]),
                  _withDirectives(_createElementVNode("p", {
                    class: "lead_status",
                    style: _normalizeStyle({ 'background-color': element.leadColor ?? '#fff', 'color': element.leadTextColor })
                  }, _toDisplayString(element.leadStatus), 5), [
                    [_vShow, element.leadStatus]
                  ])
                ], 46, _hoisted_50), [
                  [_vShow, element.isShow]
                ])
              ]),
              _: 1
            }, 8, ["list", "disabled", "onEnd"])
          ], 34)
        ]),
        _createElementVNode("div", _hoisted_66, [
          _createElementVNode("div", _hoisted_67, [
            _createElementVNode("p", _hoisted_68, [
              _createTextVNode(_toDisplayString(_ctx.$t('task.tomorrow')), 1),
              _withDirectives(_createElementVNode("span", null, "(" + _toDisplayString(_ctx.tomorrowLeadsCount) + ")", 513), [
                [_vShow, _ctx.tomorrowLeadsCount > 0]
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["lead_list", {open_filter: _ctx.isShowFilters}]),
            "param-kanban": "tomorrowLeads",
            onScroll: _cache[12] || (_cache[12] = (...args) => (_ctx.scrollKanban && _ctx.scrollKanban(...args)))
          }, [
            _createVNode(_component_draggable, {
              list: _ctx.tomorrowLeads,
              group: "lead",
              disabled: _ctx.isMobile,
              "item-key": "leadId",
              sort: false,
              class: "h-100",
              onEnd: _ctx.endMove,
              forceFallback: true,
              id: "tomorrowLeads"
            }, {
              item: _withCtx(({element}) => [
                _withDirectives(_createElementVNode("div", {
                  class: _normalizeClass(element.leadClass),
                  style: _normalizeStyle({ 'border': `1px solid ${element.leadColor}`, 'backgroundColor': `${element.isUnhandled ? '#FBE0E0' : '#fff'}` }),
                  onDblclick: $event => (_ctx.openLead(element.leadId))
                }, [
                  _createElementVNode("div", _hoisted_70, [
                    _createElementVNode("p", _hoisted_71, _toDisplayString(element.name), 1),
                    _createElementVNode("p", _hoisted_72, _toDisplayString(element.phone), 1),
                    _createElementVNode("div", {
                      class: "lead_next_step_date",
                      onClick: $event => (_ctx.openChangeDateWindow(element, 'tomorrowLeads'))
                    }, _toDisplayString(element.nextStepDateText), 9, _hoisted_73),
                    (element.isClient)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_74, [
                          _createElementVNode("div", _hoisted_75, [
                            _createElementVNode("label", _hoisted_76, _toDisplayString(element.contractLabel), 1),
                            _createElementVNode("div", _hoisted_77, [
                              _createElementVNode("div", {
                                class: _normalizeClass(element.contractDotClass)
                              }, null, 2),
                              _createTextVNode(_toDisplayString(element.contractStatus), 1)
                            ])
                          ]),
                          _createElementVNode("p", _hoisted_78, _toDisplayString(element.contractName), 1)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_79, [
                      _createElementVNode("label", _hoisted_80, _toDisplayString(element.commentLabel), 1),
                      _createElementVNode("p", _hoisted_81, _toDisplayString(element.comment), 1)
                    ]),
                    _createElementVNode("div", _hoisted_82, [
                      _createElementVNode("label", _hoisted_83, _toDisplayString(element.labelNextStep), 1),
                      _createElementVNode("div", _hoisted_84, _toDisplayString(element.nextStep), 1)
                    ])
                  ]),
                  _withDirectives(_createElementVNode("p", {
                    class: "lead_status",
                    style: _normalizeStyle({ 'background-color': element.leadColor ?? '#fff', 'color': element.leadTextColor })
                  }, _toDisplayString(element.leadStatus), 5), [
                    [_vShow, element.leadStatus]
                  ])
                ], 46, _hoisted_69), [
                  [_vShow, element.isShow]
                ])
              ]),
              _: 1
            }, 8, ["list", "disabled", "onEnd"])
          ], 34)
        ]),
        _createElementVNode("div", _hoisted_85, [
          _createElementVNode("div", _hoisted_86, [
            _createElementVNode("p", _hoisted_87, [
              _createTextVNode(_toDisplayString(_ctx.$t('task.this_week')), 1),
              _withDirectives(_createElementVNode("span", null, "(" + _toDisplayString(_ctx.thisWeekLeadsCount) + ")", 513), [
                [_vShow, _ctx.thisWeekLeadsCount > 0]
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["lead_list", {open_filter: _ctx.isShowFilters}]),
            "param-kanban": "thisWeekLeads",
            onScroll: _cache[13] || (_cache[13] = (...args) => (_ctx.scrollKanban && _ctx.scrollKanban(...args)))
          }, [
            _createVNode(_component_draggable, {
              list: _ctx.thisWeekLeads,
              group: "lead",
              disabled: _ctx.isMobile,
              "item-key": "leadId",
              sort: false,
              class: "h-100",
              onEnd: _ctx.endMove,
              forceFallback: true,
              id: "thisWeekLeads"
            }, {
              item: _withCtx(({element}) => [
                _withDirectives(_createElementVNode("div", {
                  class: _normalizeClass(element.leadClass),
                  style: _normalizeStyle({ 'border': `1px solid ${element.leadColor}`, 'backgroundColor': `${element.isUnhandled ? '#FBE0E0' : '#fff'}` }),
                  onDblclick: $event => (_ctx.openLead(element.leadId))
                }, [
                  _createElementVNode("div", _hoisted_89, [
                    _createElementVNode("p", _hoisted_90, _toDisplayString(element.name), 1),
                    _createElementVNode("p", _hoisted_91, _toDisplayString(element.phone), 1),
                    _createElementVNode("div", {
                      class: "lead_next_step_date",
                      onClick: $event => (_ctx.openChangeDateWindow(element, 'thisWeekLeads'))
                    }, _toDisplayString(element.nextStepDateText), 9, _hoisted_92),
                    (element.isClient)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_93, [
                          _createElementVNode("div", _hoisted_94, [
                            _createElementVNode("label", _hoisted_95, _toDisplayString(element.contractLabel), 1),
                            _createElementVNode("div", _hoisted_96, [
                              _createElementVNode("div", {
                                class: _normalizeClass(element.contractDotClass)
                              }, null, 2),
                              _createTextVNode(_toDisplayString(element.contractStatus), 1)
                            ])
                          ]),
                          _createElementVNode("p", _hoisted_97, _toDisplayString(element.contractName), 1)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_98, [
                      _createElementVNode("label", _hoisted_99, _toDisplayString(element.commentLabel), 1),
                      _createElementVNode("p", _hoisted_100, _toDisplayString(element.comment), 1)
                    ]),
                    _createElementVNode("div", _hoisted_101, [
                      _createElementVNode("label", _hoisted_102, _toDisplayString(element.labelNextStep), 1),
                      _createElementVNode("div", _hoisted_103, _toDisplayString(element.nextStep), 1)
                    ])
                  ]),
                  _withDirectives(_createElementVNode("p", {
                    class: "lead_status",
                    style: _normalizeStyle({ 'background-color': element.leadColor ?? '#fff', 'color': element.leadTextColor })
                  }, _toDisplayString(element.leadStatus), 5), [
                    [_vShow, element.leadStatus]
                  ])
                ], 46, _hoisted_88), [
                  [_vShow, element.isShow]
                ])
              ]),
              _: 1
            }, 8, ["list", "disabled", "onEnd"])
          ], 34)
        ]),
        _createElementVNode("div", _hoisted_104, [
          _createElementVNode("div", _hoisted_105, [
            _createElementVNode("p", _hoisted_106, [
              _createTextVNode(_toDisplayString(_ctx.$t('task.later')), 1),
              _withDirectives(_createElementVNode("span", null, "(" + _toDisplayString(_ctx.laterLeadsCount) + ")", 513), [
                [_vShow, _ctx.laterLeadsCount > 0]
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["lead_list", {open_filter: _ctx.isShowFilters}]),
            "param-kanban": "laterLeads",
            onScroll: _cache[14] || (_cache[14] = (...args) => (_ctx.scrollKanban && _ctx.scrollKanban(...args)))
          }, [
            _createVNode(_component_draggable, {
              list: _ctx.laterLeads,
              group: "lead",
              disabled: _ctx.isMobile,
              "item-key": "leadId",
              sort: false,
              class: "h-100",
              onEnd: _ctx.endMove,
              forceFallback: true,
              id: "laterLeads"
            }, {
              item: _withCtx(({element}) => [
                _withDirectives(_createElementVNode("div", {
                  class: _normalizeClass(element.leadClass),
                  style: _normalizeStyle({ 'border': `1px solid ${element.leadColor}`, 'backgroundColor': `${element.isUnhandled ? '#FBE0E0' : '#fff'}` }),
                  onDblclick: $event => (_ctx.openLead(element.leadId))
                }, [
                  _createElementVNode("div", _hoisted_108, [
                    _createElementVNode("p", _hoisted_109, _toDisplayString(element.name), 1),
                    _createElementVNode("p", _hoisted_110, _toDisplayString(element.phone), 1),
                    _createElementVNode("div", {
                      class: "lead_next_step_date",
                      onClick: $event => (_ctx.openChangeDateWindow(element, 'laterLeads'))
                    }, _toDisplayString(element.nextStepDateText), 9, _hoisted_111),
                    (element.isClient)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_112, [
                          _createElementVNode("div", _hoisted_113, [
                            _createElementVNode("label", _hoisted_114, _toDisplayString(element.contractLabel), 1),
                            _createElementVNode("div", _hoisted_115, [
                              _createElementVNode("div", {
                                class: _normalizeClass(element.contractDotClass)
                              }, null, 2),
                              _createTextVNode(_toDisplayString(element.contractStatus), 1)
                            ])
                          ]),
                          _createElementVNode("p", _hoisted_116, _toDisplayString(element.contractName), 1)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_117, [
                      _createElementVNode("label", _hoisted_118, _toDisplayString(element.commentLabel), 1),
                      _createElementVNode("p", _hoisted_119, _toDisplayString(element.comment), 1)
                    ]),
                    _createElementVNode("div", _hoisted_120, [
                      _createElementVNode("label", _hoisted_121, _toDisplayString(element.labelNextStep), 1),
                      _createElementVNode("div", _hoisted_122, _toDisplayString(element.nextStep), 1)
                    ])
                  ]),
                  _withDirectives(_createElementVNode("p", {
                    class: "lead_status",
                    style: _normalizeStyle({ 'background-color': element.leadColor ?? '#fff', 'color': element.leadTextColor })
                  }, _toDisplayString(element.leadStatus), 5), [
                    [_vShow, element.leadStatus]
                  ])
                ], 46, _hoisted_107), [
                  [_vShow, element.isShow]
                ])
              ]),
              _: 1
            }, 8, ["list", "disabled", "onEnd"])
          ], 34)
        ])
      ])
    ])
  ], 64))
}