<template src="./createTask.html"></template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { axiosRequests } from '../../../services/axiosinstance';
    import { editUrl, getFormatedDate, convertToISOString } from '../../../services/helper';
    import { Routes } from '../../../const';
    import { SelectModel } from '../../../models/GUIModels';
    import { Club } from '../../../models/Club';

    interface ComponentModel {
        options: any,
        clubs: SelectModel[],
        nextSteps: SelectModel[],
        selectedClub: SelectModel | null,
        sex: SelectModel[],
        selectedSex: SelectModel,
        selectedStatus: SelectModel | null,
        selectedNextStep: SelectModel | null,
        selectedManagers: SelectModel | null,
        date: string,
        name: string,
        mail: string,
        message: string,
        isButtonDisabled: boolean,
        phone: string,
        phoneErrorText: string,
        clubErrorText: string,
        nameErrorText: string,
        dateErrorText: string,
        isShowExistError: boolean,
        existLeadId: string
    }

    export default defineComponent({
        name: 'CreateTask',
        props: ["managers", "statuses", "callingPhone"],
        emits: ["close", "update", "openLead"], 
        data(): ComponentModel {
            return {
                options: {
                    mask: "+7 (###) ###-##-##"
                },
                clubs: [],
                nextSteps: [
                     { id: "", text: "task.absent", isTranslated: true, groupName: null, disabled: false },
                     { id: "call", text: "crm_next_step.call", isTranslated: true, groupName: null, disabled: false },
                     { id: "email", text: "crm_next_step.email", isTranslated: true, groupName: null, disabled: false },
                     { id: "sms", text: "crm_next_step.sms", isTranslated: true, groupName: null, disabled: false }
                 ],
                sex: [
                    { id: "false", text: 'task.create.female', isTranslated: true, disabled: false, groupName: null },
                    { id: "true", text: 'task.create.male', isTranslated: true, disabled: false, groupName: null }
                ],
                selectedSex: { id: "true", text: 'task.create.male', isTranslated: true, disabled: false, groupName: null },
                selectedClub: null,
                selectedStatus: null,
                selectedNextStep: null,
                selectedManagers: null,
                name: "",
                date: "",
                mail: "",
                message: "",
                isButtonDisabled: false,
                phone: "",
                phoneErrorText: "",
                clubErrorText: "",
                nameErrorText: "",
                dateErrorText: "",
                isShowExistError: false,
                existLeadId: ""
            }
        },
        methods: {
            getAvailableClubs: async function () {
                await axiosRequests.get(editUrl(Routes.getClubs))
                    .then(
                        (data: Club[]) => {
                            this.clubs = data.map(x => { return { id: x.id.toString(), text: x.name, isTranslated: false, disabled: false, groupName: null } });

                            if(this.clubs.length > 0){
                                if(this.callingPhone){
                                    let selectedClubForCalls = localStorage.getItem("selected-club-for-calls");

                                    if (selectedClubForCalls) this.selectedClub = this.clubs.find(x => x.id == selectedClubForCalls) ?? null;
                                } else {
                                     this.selectedClub = this.clubs[0];
                                }
                            }
                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            checkLeadExist: function () {
                if (!this.selectedClub?.id) return;
                if (!this.phone) return;

                let phone = this.phone.replace(/\D/g, '');

                axiosRequests.get(editUrl(`${Routes.findLead}?clubId=${this.selectedClub?.id}&phone=${phone}`))
                    .then(
                        (data: any) => {
                            if (data.leadId) {
                                this.isShowExistError = true;
                                this.existLeadId = data.leadId;
                            }       
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isButtonDisabled = false;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );


               
            },
            close: function () {
                this.$emit("close", false);
            },
            clickOnRedirect: function () {
                if (this.existLeadId) this.$emit("openLead", this.existLeadId); 
            },
            setNowTime: function(){
                this.date = getFormatedDate(convertToISOString(new Date()), "yyyy-MM-DDTHH:mm")

            },
            save: function () {
                let phone = this.phone.replace(/\D/g, '');

                if (!phone || phone.length != 11) this.phoneErrorText = 'task.create.phone_lenght_error';
                if (!this.selectedClub?.id) this.clubErrorText = 'task.create.club_error_text';
                if (!this.name) this.nameErrorText = 'task.create.name_error_text';
                if (!this.date) this.dateErrorText = 'task.create.date_error_text';

                if (this.phoneErrorText || this.clubErrorText || this.nameErrorText || this.dateErrorText || this.isShowExistError) return;

                this.isButtonDisabled = true;

                const data = {
                    clubId: this.selectedClub?.id ? this.selectedClub.id : null,
                    nextStepDate: getFormatedDate(this.date, "yyyy-MM-DDTHH:mm"),
                    description: this.message,
                    nextStep: this.selectedNextStep?.id ? this.selectedNextStep.id : null,
                    statusId: this.selectedStatus?.id ? this.selectedStatus.id : null,
                    firstName: this.name,
                    email: this.mail,
                    phoneWithCountryCode: phone,
                    sex: this.selectedSex.id == "true",
                    managerId: this.selectedManagers?.id ? this.selectedManagers.id : null
                };

                axiosRequests.post(editUrl(Routes.createLead), data)
                     .then(
                         () => {
                             this.isButtonDisabled = false;

                             this.$emit("close", true);         
                         }
                     )
                     .catch(
                         async (err: any) => {
                             this.isButtonDisabled = false;

                             if (err.status == 401) {
                                 return;
                             } else if (err.status == 403) {
                                  this.$router.push('/forbidden');
                             } else if (err.status == 400 && err.data) {
                                 this.$store.commit('SET_ADD_ERROR', err.data);
                             } else {
                                 this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                             }
                         }
                     );
            },
            checkPressedButton: function (event: any) {
                if (event.keyCode == 27) this.close();
            }
        },
        async beforeMount() {
            await this.getAvailableClubs();
            this.phone = this.callingPhone;
        },
        mounted() {
            window.addEventListener('keyup', this.checkPressedButton);
        },
        beforeUnmount() {
            window.removeEventListener('keyup', this.checkPressedButton);
        }
    });
</script>

<style scoped>
    .create_new_lead_wrapper {
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: #b5b4b466;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .create_new_lead_body {
        background-color: white;
        max-width: 2000px;
        width: 1000px;
        border-radius: 24px;
        animation: show 0.6s linear;
        max-height: 98%;
        display: flex;
        flex-flow: column;
    }

    .create_new_lead_body_title {
        font-size: 17px;
        letter-spacing: 1px;
        font-weight: 600;
        background-color: #f6f7f8;
        padding: 16px 24px;
        border-radius: 24px 24px 0px 0px;
    }

    .create_new_lead_content_wrapper {
        overflow: auto;
        padding-top: 16px;
    }

    .create_new_lead {
        padding: 0px 24px;
    }

    .two_block_wrapper {
        width: 100%;
        display: flex;
    }

    .three_block_wrapper {
        width: 100%;
        display: flex;
    }

    .left_block {
        display: flex;
        flex-flow: column;
        margin-right: 8px;
        width: calc(50% - 8px);
    }

    .right_block {
        display: flex;
        flex-flow: column;
        margin-left: 8px;
        width: calc(50% - 8px);
    }

    .left_two_blocks {
        display: flex;
        margin-right: 8px;
        width: calc(50% - 8px);
    }

    .right_one_block {
        display: flex;
        margin-left: 8px;
        width: calc(50% - 8px);
    }


    .create_new_lead_buttons_wrapper {
        margin-top: 12px;
        display: flex;
        padding: 0px 24px;
        margin-bottom: 8px;
    }

    .button_space {
        margin-left: 16px;
    }

    .exist_phone_error {
        font-size: 11px;
        margin-left: 15px;
        margin-top: 3px;
        color: red;
    }

    .exist_phone_error a {
        text-decoration: underline;
        cursor: pointer;
    }

    .now_button {
        cursor: pointer;
        height: 19px;
        width: 19px;
        border-radius: 50%;
        background-color: white;
        border: gainsboro;
        position: absolute;
        right: 62px;
        top: 8px;
        padding: 0;
    }

    @media(max-width: 1050px) {
        .three_block_wrapper {
            flex-flow: column;
        }

        .left_two_blocks {
            margin-right: 0px;
            width: 100%;
        }

        .right_one_block {
            margin-left: 0px;
            width: 100%;
        }

        .create_new_lead_body {
            width: 98%;
        }
    }

    @media(max-width: 768px) {
        .two_block_wrapper {
            flex-flow: column;
        }

        .left_two_blocks {
            flex-flow: column;
        }

        .left_block {
            margin-right: 0px;
            width: 100%;
        }

        .right_block {
            margin-left: 0px;
            width: 100%;
        }
    }

    @media(max-width: 500px) {
        
        .button_space {
            margin-left: 0px;
        }

        .create_new_lead_buttons_wrapper {
            display: block;
        }
    }
</style>