import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6eccceff"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "add_restriction_wrapper" }
const _hoisted_2 = { class: "add_restriction_body" }
const _hoisted_3 = { class: "add_restriction_title" }
const _hoisted_4 = {
  key: 0,
  class: "add_restriction"
}
const _hoisted_5 = {
  key: 1,
  class: "add_restriction"
}
const _hoisted_6 = { class: "add_restriction_text" }
const _hoisted_7 = { class: "add_restriction_buttons_wrapper" }
const _hoisted_8 = ["disabled"]

export function render(_ctx, _cache) {
  const _component_v_select = _resolveComponent("v-select")
  const _component_v_textarea = _resolveComponent("v-textarea")
  const _directive_click_outside = _resolveDirective("click-outside")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('clients.view.add_restriction.title')), 1),
      (_ctx.clubs?.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", null, [
              _createVNode(_component_v_select, {
                modelValue: _ctx.selectedClub,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.selectedClub) = $event)),
                label: 'clients.view.add_restriction.club',
                class: "w-100 mb-3",
                items: _ctx.clubs,
                errorText: _ctx.clubErrorText,
                onClick: _cache[1] || (_cache[1] = $event => (_ctx.clubErrorText = ''))
              }, null, 8, ["modelValue", "label", "items", "errorText"])
            ]),
            _createVNode(_component_v_textarea, {
              modelValue: _ctx.text,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.text) = $event)),
              label: _ctx.$t('clients.view.add_restriction.comment'),
              errorText: _ctx.textErrorText,
              onFocus: _cache[3] || (_cache[3] = $event => (_ctx.textErrorText = ''))
            }, null, 8, ["modelValue", "label", "errorText"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.clubs?.length == 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('clients.view.add_restriction.no_active_contract')), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.clubs?.length > 0)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "primary_button button_space",
              onClick: _cache[4] || (_cache[4] = (...args) => (_ctx.save && _ctx.save(...args))),
              disabled: _ctx.isButtonDisabled || _ctx.clubs.length == 0
            }, _toDisplayString(_ctx.$t('clients.view.add_restriction.set')), 9, _hoisted_8))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          class: "secondary_button",
          onClick: _cache[5] || (_cache[5] = $event => (_ctx.$emit('close')))
        }, _toDisplayString(_ctx.$t('clients.view.add_restriction.cancel')), 1)
      ])
    ])), [
      [_directive_click_outside, _ctx.close]
    ])
  ]))
}