<template src="./timetable.html"></template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { axiosRequests } from '../../services/axiosinstance';
    import { editUrl, getFormatedDate, getTextColor, retryGetPermissions, convertToISOString } from '../../services/helper';
    import { Routes } from '../../const';
    import { SelectModel } from '../../models/GUIModels';
    import { GroupTimetable, PersonalTimetable, TimetableFilter, TimetableFilterClub, RegisteredClient, ClientShortResponse, DetailedTraining } from '../../models/Timetable';
    import CreateGroupTraining from './CreateGroupTraining/CreateGroupTraining.vue';
    import CreatePesonalTraining from './CreatePesonalTraining/CreatePesonalTraining.vue';
    import ViewGroupTraining from './ViewGroupTraining/ViewGroupTraining.vue';
    import ViewPersonalTraining from './ViewPersonalTraining/ViewPersonalTraining.vue';
    import CopyingAlert from './CopyingAlert/CopyingAlert.vue';

    interface CellModel {
        isDisable: boolean,
        isPassed: boolean,
        dateTime: string,
        trainings: any[],
    }

    interface Timetable {
         date: string,
         weekDay: string,
         cells: CellModel[]
    }

    interface PersonalTraining {
        trainingId: number,
        trainerNickName: string,
        trainingStartDate: string,
        isPaid: boolean,
        paymentType: string,
        amount: number | null,
        currencySign: string,
        clientId: number,
        clientName: string,
        clientPhoto: string,
        clientPhone: string,
        hasPaymentMean: boolean,
        hasTrainingBlock: boolean,
        duration: number
    }

    interface GroupTraining {
         trainingId: number,
         trainingClassName: string,
         trainingClassId: number,
         isTrainerAccepted: boolean | null,
         trainingStartDate: string,
         capacity: number,
         load: number,
         trainerId: number,
         price: number,
         currencySign: string,
         trainers: SelectModel[],
         registeredClients: RegisteredClient[],
         waitingList: ClientShortResponse[]
    }
    
    interface ComponentModel {
        groupTrainingsTimetable: Timetable[],
        personalTrainingsTimetable: Timetable[],
        clubWithZones: TimetableFilterClub[],
        selectedClubZone: SelectModel | null,
        filterClass: SelectModel | null,
        filterTrainer: SelectModel | null,
        personalTrainer: SelectModel | null,
        clubZones: SelectModel[],
        classes: SelectModel[],
        groupTrainers: SelectModel[],
        personalTrainers: SelectModel[],
        isShowGroupTrainings: boolean,
        hasCreateGroupPermission: boolean,
        hasCreatePersonalPermission: boolean,
        hasPDFLoadPermission: boolean,
        hasCopyPermission: boolean,
        isShowFilters: boolean,
        isLoading: boolean,
        mobileShowDay: number,
        isShowCreateGroupTraining: boolean,
        isShowCreatePersonalTraining: boolean,
        isShowGroupTraining: boolean,
        isShowPersonalTraining: boolean,
        addTrainingDate: string | null,
        clubWithZone: TimetableFilterClub | null,
        selectedPersonalTrainer: any,
        startWeekDate: Date,
        groupCellHieght: number
        personalCellHieght: number,
        selectedGroupTraining: GroupTraining | null,
        selectedPersonalTraining: PersonalTraining | null,
        isShowButtons: boolean,
        isShowAlert: boolean,
        alertCopyingWeek: string,
        alertPastedWeek: string
    }

    export default defineComponent({
        name: 'TimetableView',
        data(): ComponentModel {
            return {
                groupTrainingsTimetable: [],
                personalTrainingsTimetable: [],
                clubWithZones: [],
                selectedClubZone: null,
                filterClass: null,
                filterTrainer: null,
                personalTrainer: null,
                clubZones: [],
                classes: [],
                groupTrainers: [],
                personalTrainers: [],
                isShowGroupTrainings: true,
                hasPDFLoadPermission: false,
                hasCreateGroupPermission: false,
                hasCreatePersonalPermission: false,
                hasCopyPermission: false,
                isShowFilters: false,
                isLoading: false,
                mobileShowDay: 0,
                isShowCreateGroupTraining: false,
                isShowCreatePersonalTraining: false,
                isShowGroupTraining: false,
                isShowPersonalTraining: false,
                addTrainingDate: null,
                clubWithZone: null,
                selectedPersonalTrainer: null,
                startWeekDate: new Date(),
                groupCellHieght: 120,
                personalCellHieght: 120,
                selectedGroupTraining: null,
                selectedPersonalTraining: null,
                isShowButtons: false,
                isShowAlert: false,
                alertCopyingWeek: "",
                alertPastedWeek: ""
            }
        },
        methods: {
            getGroupTrainings: async function () {
                this.isLoading = true;

                await axiosRequests.get(editUrl(`${Routes.getTimetableForGroupTrainings}?clubZoneId=${this.selectedClubZone?.id}&weekStartDate=${getFormatedDate(convertToISOString(this.startWeekDate), 'yyyy-MM-DD')}`))
                    .then(
                        (data: GroupTimetable) => {
                            this.groupTrainingsTimetable = [];

                            if(data.timetableStepMinutes == 15){
                                this.groupCellHieght = 60;
                            }
                            else if(data.timetableStepMinutes == 30){
                                 this.groupCellHieght = 90;
                            }
                            else{
                                 this.groupCellHieght = 120;
                            }

                            for(let i = 0; i < data.groupTrainings.length; i++){

                                let cells: CellModel[] = [];
                                for(let key in data.groupTrainings[i]){
                                     cells.push({
                                         isDisable: data.groupTrainings[i][key].notAvailable,
                                         isPassed: new Date() > new Date(`${data.groupTrainings[i][key].trainingDate}T${key}`),
                                         dateTime: `${data.groupTrainings[i][key].trainingDate}T${key}`,
                                         trainings: this.addTrainings(data.groupTrainings[i][key], true),
                                     })
                                }
                                
                                let tempDate = this.addDay(this.startWeekDate, i);

                                this.groupTrainingsTimetable.push({
                                    date: convertToISOString(tempDate),
                                    weekDay: `week_day.${this.getWeekday(tempDate.getDay())}`,
                                    cells: cells
                                });

                            }

                            this.applyGroupFilter();

                            this.isLoading = false;
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isLoading = false;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 500) {
                                this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                            } else if (err.status == 403) {
                                 this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            getPersonalTrainings: async function () {
                if(!this.personalTrainer) return;

                let trainerIdWithClubId = this.personalTrainer.id.split("|");

                if(trainerIdWithClubId.length != 2) return;

                this.isLoading = true;

                await axiosRequests.get(editUrl(`${Routes.getTimetableForPersonalTrainings}?trainerId=${trainerIdWithClubId[0]}&clubId=${trainerIdWithClubId[1]}&weekStartDate=${getFormatedDate(convertToISOString(this.startWeekDate), 'yyyy-MM-DD')}`))
                    .then(
                        (data: PersonalTimetable) => {

                             this.personalTrainingsTimetable = [];

                             for(let i = 0; i < data.personalTrainings.length; i++){

                                 let cells: CellModel[] = [];
                                 for(let key in data.personalTrainings[i]){
                                      cells.push({
                                          isDisable: data.personalTrainings[i][key].notAvailable,
                                          isPassed: new Date() > new Date(`${data.personalTrainings[i][key].trainingDate}T${key}`),
                                          dateTime: `${data.personalTrainings[i][key].trainingDate}T${key}`,
                                          trainings: this.addTrainings(data.personalTrainings[i][key], false),
                                      })
                                 }
     
                                 let tempDate = this.addDay(this.startWeekDate, i);

                                 this.personalTrainingsTimetable.push({
                                     date: convertToISOString(tempDate),
                                     weekDay: `week_day.${this.getWeekday(tempDate.getDay())}`,
                                     cells: cells
                                 });

                             }


                             this.isLoading = false;
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isLoading = false;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 500) {
                                this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                            } else if (err.status == 403) {
                                 this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            getFilters: async function (isApplyFilters: boolean) {
               await axiosRequests.get(editUrl(Routes.getTimetableFilters))
                    .then(
                        (data: TimetableFilter) => {
                             this.clubWithZones = data.clubs;

                             this.clubZones = [];
                             this.personalTrainers = [];

                             this.personalTrainer = null;
                             this.selectedClubZone = null;

                             for (let club of this.clubWithZones) {
                                 for (let clubZone of club.clubZones) {
                                     this.clubZones.push({ id: clubZone.clubZoneId.toString(), text: clubZone.name, isTranslated: false, disabled: false, groupName: club.name });
                                 }

                                 if(club.trainers.length > 0){
                                     this.personalTrainers.push({ id: `0|${club.clubId}`, text: this.$t('timetable.all'), isTranslated: false, disabled: false, groupName: club.name });

                                     for (let trainer of club.trainers) {
                                         this.personalTrainers.push({ id: `${trainer.trainerId.toString()}|${club.clubId}`, text: trainer.name, isTranslated: false, disabled: false, groupName: club.name });
                                     }
                                 }
                             }

                             if(!isApplyFilters) return;

                             if(this.personalTrainers.length > 0){
                                 this.personalTrainer = this.personalTrainers[0];
                                 this.selectPersonalTrainer();
                             }

                             if (this.clubZones.length > 0) {
                                 this.selectedClubZone = this.clubZones[0];
                                 this.changeClubZone();
                             }
                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 500) {
                                this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            getTraining: async function (trainingId: number, isClickOnCell: boolean) {
                await axiosRequests.get(editUrl(Routes.getTraining, trainingId))
                    .then(
                        async (data: DetailedTraining) => {

                            if(data.groupTraining){
                                this.selectedPersonalTraining = null;

                                if (isClickOnCell) {
                                    if(!this.selectedGroupTraining) return;

                                    this.selectedGroupTraining.registeredClients = data.groupTraining.registeredClients;
                                    this.selectedGroupTraining.waitingList = data.groupTraining.waitingClients;
                                } else {

                                    this.startWeekDate = this.findStartWeekDate(new Date(data.groupTraining.beginDate));

                                    if(this.personalTrainers.length > 0){
                                        this.personalTrainer = this.personalTrainers[0];
                                        this.selectPersonalTrainer();
                                    }
  
                                    let index = this.clubZones.findIndex(x => x.id == data.groupTraining.clubZoneId.toString())
  
                                    if(index == -1) return;
  
                                    this.selectedClubZone = this.clubZones[index];
                                    await this.changeClubZone();

                                    index = this.groupTrainingsTimetable.findIndex(x => new Date(x.date).toDateString() == new Date(data.groupTraining.beginDate).toDateString());

                                    if(index == -1) return;

                                    let training: any = null;
                                    for(let cell of this.groupTrainingsTimetable[index].cells){
                                        if(cell.trainings.length == 0) continue;

                                        if (cell.trainings[0].trainingId == data.trainingId) training = cell.trainings[0];
                                    }
                                     
                                    if(!training) return;

                                    let piceAndCurrencySign = this.findPiceAndCurrencySignByTrainingClassId(training.trainingClassId);

                                    this.selectedGroupTraining = {
                                        trainingId: data.trainingId,
                                        trainingClassName: training.trainingClassName,
                                        trainingClassId: training.trainingClassId,
                                        isTrainerAccepted: training.trainerAccepted,
                                        trainingStartDate: training.trainingStartDate,
                                        capacity: training.capacity,
                                        load: training.load,
                                        trainerId: training.trainerId,
                                        trainers: this.groupTrainers,
                                        price: piceAndCurrencySign.price,
                                        currencySign: piceAndCurrencySign.currencySign,
                                        registeredClients: data.groupTraining.registeredClients,
                                        waitingList: data.groupTraining.waitingClients
                                    }
                                }

                                this.isShowGroupTraining = true;

                                return;  
                            }

                            if(data.personalTraining){
                                this.selectedGroupTraining = null;

                                if (isClickOnCell) {
                                    if (!this.selectedPersonalTraining) return;

                                    this.selectedPersonalTraining.amount = data.personalTraining.registeredClient.amount;
                                    this.selectedPersonalTraining.currencySign = data.personalTraining.registeredClient.currencySign;
                                    this.selectedPersonalTraining.clientId = data.personalTraining.registeredClient.id;
                                    this.selectedPersonalTraining.clientName = data.personalTraining.registeredClient.fullName;
                                    this.selectedPersonalTraining.clientPhoto = data.personalTraining.registeredClient.photoUrl;
                                    this.selectedPersonalTraining.clientPhone = data.personalTraining.registeredClient.phoneWithCountryCode;
                                    this.selectedPersonalTraining.hasPaymentMean = data.personalTraining.registeredClient.hasPaymentMean;
                                    this.selectedPersonalTraining.hasTrainingBlock = data.personalTraining.registeredClient.hasTrainingBlock;

                                } else {
                                    this.startWeekDate = this.findStartWeekDate(new Date(data.personalTraining.beginDate));

                                    if (this.clubZones.length > 0) {
                                        this.selectedClubZone = this.clubZones[0];
                                        this.changeClubZone();
                                    }

                                    let index = this.personalTrainers.findIndex(x => x.id == `0|${data.personalTraining.clubId}`)

                                    if (index == -1) return;

                                    this.personalTrainer = this.personalTrainers[index];

                                    await this.selectPersonalTrainer();

                                    
                                    index = this.personalTrainingsTimetable.findIndex(x => new Date(x.date).toDateString() == new Date(data.personalTraining.beginDate).toDateString());

                                    if (index == -1) return;

                                    let training: any = null;
                                    for (let cell of this.personalTrainingsTimetable[index].cells) {
                                        if (cell.trainings.length == 0) continue;

                                        for (let cellTraining of cell.trainings) {
                                            if (cellTraining.trainingId == data.trainingId) training = cellTraining;
                                        }
                                    }

                                    if (!training) return;

                                    this.selectedPersonalTraining = {
                                        trainingId: training.trainingId,
                                        trainerNickName: training.trainerName,
                                        trainingStartDate: training.trainingStartDate,
                                        isPaid: training.isPaid,
                                        paymentType: training.paymentType,
                                        amount: data.personalTraining.registeredClient.amount,
                                        currencySign: data.personalTraining.registeredClient.currencySign,
                                        clientId: data.personalTraining.registeredClient.id,
                                        clientName: data.personalTraining.registeredClient.fullName,
                                        clientPhoto: data.personalTraining.registeredClient.photoUrl,
                                        clientPhone: data.personalTraining.registeredClient.phoneWithCountryCode,
                                        hasPaymentMean: data.personalTraining.registeredClient.hasPaymentMean,
                                        hasTrainingBlock: data.personalTraining.registeredClient.hasTrainingBlock,
                                        duration: training.duration
                                    }
                                }

                                this.isShowPersonalTraining = true;
                                this.isShowGroupTrainings = false;

                                return;
                            }

                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 500) {
                                this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            addTrainings: function (cell: any, isGroup: boolean) {
                if(isGroup){
                    if(!cell.training) return [];

                    return [{
                        trainingTime: cell.trainingTime,
                        trainingClassName: cell.trainingClassName,
                        trainingClassId: cell.trainingClasses,
                        trainingStartDate: `${cell.trainingDate}T${cell.trainingStartTime}`,
                        numberOfCells: cell.numberOfCells,
                        trainingId: cell.training,
                        trainingColor: cell.trainingColor,
                        trainingTextColor: cell.trainingTextColor,
                        trainerId: cell.trainer,
                        trainerName: cell.trainerName,
                        trainerPhoto: cell.trainerPhoto,
                        trainerAccepted: cell.trainerAccepted,
                        capacity: cell.capacity,
                        load: cell.load,
                        isShow: true
                    }];
                } else {
                    if(cell.trainings.length == 0) return [];

                    let result: any[] = [];

                    for(let i = 0; i < cell.trainings.length; i++){
                        result.push({
                            trainingTime: cell.trainings[i].trainingTime,
                            trainingStartDate: `${cell.trainingDate}T${cell.trainingStartTime}`,
                            numberOfCells: cell.numberOfCells,
                            trainingId: cell.trainings[i].training,
                            serviceName: cell.trainings[i].serviceName,
                            trainerName: cell.trainings[i].trainer,
                            clientrName: cell.trainings[i].client,
                            isPaid: cell.trainings[i].paid,
                            paymentType: cell.trainings[i].paymentType,
                            left: i * 15,
                            isShowFullTraining: false,
                            isHideTraining: false,
                            duration: cell.trainings[i].duration
                        });
                    }

                    return result;
                }           
            },
            addDay: function(date: Date, daysCount: number){
                let tempDate = new Date(date);
                tempDate.setDate(tempDate.getDate() + daysCount);
                return tempDate;
            },
            getWeekday: function(numberOfDay: number){
                if(numberOfDay == 1) return 'monday';
                if(numberOfDay == 2) return 'tuesday';
                if(numberOfDay == 3) return 'wednesday';
                if(numberOfDay == 4) return 'thursday';
                if(numberOfDay == 5) return 'friday';
                if(numberOfDay == 6) return 'saturday';
                if(numberOfDay == 0) return 'sunday';
            },
            clickOnGroupTrainersCell: async function (cell: CellModel) {
                if (cell.isDisable) return;
                else if (new Date() > new Date(cell.dateTime) && cell.trainings.length == 0) return;
                else if (cell.trainings.length == 0) this.createTraining(cell.dateTime);
                else {
                    let piceAndCurrencySign = this.findPiceAndCurrencySignByTrainingClassId(cell.trainings[0].trainingClassId);

                    history.pushState(history.state, "", "/timetable/" + cell.trainings[0].trainingId);

                    this.selectedGroupTraining = {
                        trainingId: cell.trainings[0].trainingId,
                        trainingClassName: cell.trainings[0].trainingClassName,
                        trainingClassId: cell.trainings[0].trainingClassId,
                        isTrainerAccepted: cell.trainings[0].trainerAccepted,
                        trainingStartDate: cell.trainings[0].trainingStartDate,
                        capacity: cell.trainings[0].capacity,
                        load: cell.trainings[0].load,
                        trainerId: cell.trainings[0].trainerId,
                        trainers: this.groupTrainers,
                        price: piceAndCurrencySign.price,
                        currencySign: piceAndCurrencySign.currencySign,
                        registeredClients: [],
                        waitingList: []
                    }

                    await this.getTraining(cell.trainings[0].trainingId, true)
                }
            },
            clickOnPersonalTrainersCell: async function (cell: CellModel) {
                if (cell.isDisable) return;
                else if (new Date() > new Date(cell.dateTime) && cell.trainings.length == 0) return;
                else if (cell.trainings.length == 0) this.createTraining(cell.dateTime);
            },
            changeClubZone: async function () {
                let index = this.clubWithZones.findIndex(x => x.clubZones.find(x => x.clubZoneId.toString() == this.selectedClubZone?.id));

                if (index == -1) return;

                this.clubWithZone = this.clubWithZones[index];

                this.groupTrainers = [];
                this.classes = [];

                this.filterClass = null;
                this.filterTrainer = null;

                this.groupTrainers = this.clubWithZones[index].trainers.filter(x => x.isGroupTrainer).map(x => { return { id: x.trainerId.toString(), text: x.name, disabled: false, isTranslated: false, groupName: null } });
                this.classes = this.clubWithZones[index].trainingClasses.map(x => { return { id: x.trainingClassId.toString(), text: x.name, disabled: false, isTranslated: false, groupName: null } });

                await this.getGroupTrainings();
            },
            selectPersonalTrainer: async function () {
               await this.getPersonalTrainings();
            },
            clickOnPersonalTraining: async function (training: any) {
                history.pushState(history.state, "", "/timetable/" + training.trainingId);

                this.selectedPersonalTraining = {
                    trainingId: training.trainingId,
                    trainerNickName: training.trainerName,
                    trainingStartDate: training.trainingStartDate,
                    isPaid: training.isPaid,
                    paymentType: training.paymentType,
                    amount: null,
                    currencySign: "",
                    clientId: 0,
                    clientName: "",
                    clientPhoto: "",
                    clientPhone: "",
                    hasPaymentMean: false,
                    hasTrainingBlock: false,
                    duration: training.duration
                }

                await this.getTraining(training.trainingId, true)
            },
            findPiceAndCurrencySignByTrainingClassId: function (trainingClassId: number) {
                let index = this.clubWithZones.findIndex(x => x.clubZones.find(x => x.clubZoneId.toString() == this.selectedClubZone?.id));

                if (index == -1) return { price: 0, currencySign: "" };

                let trainingClass = this.clubWithZones[index].trainingClasses.find(x => x.trainingClassId == trainingClassId);

                if (!trainingClass) return { price: 0, currencySign: "" };

                return { price: trainingClass.price, currencySign: trainingClass.currencySign };
            },
            mouseenterOnPersonalTraining: function(cell: CellModel, trainingId: number){
                for(let training of cell.trainings){
                    if(training.trainingId == trainingId){
                        training.isShowFullTraining = true;
                        training.isHideTraining = false;
                    }else{
                        training.isShowFullTraining = false;
                        training.isHideTraining = true;
                    }
                }
            },
            mouseleaveOnPersonalTraining: function(cell: CellModel){
                for(let training of cell.trainings){
                    training.isShowFullTraining = false;
                    training.isHideTraining = false;
                 }
            },
            clearAllGroupFilters: function () {
                this.filterClass = null;
                this.filterTrainer = null;
                this.applyGroupFilter();
            },
            clickOnPreviousWeek: function(){
                this.startWeekDate.setDate(this.startWeekDate.getDate() - 7);
                this.getGroupTrainings();
                this.getPersonalTrainings();
            },            
            clickOnNextWeek: function(){
                this.startWeekDate.setDate(this.startWeekDate.getDate() + 7);
                this.getGroupTrainings();
                this.getPersonalTrainings();
            },
            applyGroupFilter: function () {
                for (let i = 0; i < this.groupTrainingsTimetable.length; i++) {
                    for (let j = 0; j < this.groupTrainingsTimetable[i].cells.length; j++) {
                        if (this.groupTrainingsTimetable[i].cells[j].trainings.length == 0) continue;

                        if (this.filterClass == null && this.filterTrainer == null) {
                            this.groupTrainingsTimetable[i].cells[j].trainings[0].isShow = true;
                        } else if (this.filterClass != null && this.filterTrainer == null) {
                            this.groupTrainingsTimetable[i].cells[j].trainings[0].isShow = this.filterClass.id == this.groupTrainingsTimetable[i].cells[j].trainings[0].trainingClassId.toString();
                        } else if (this.filterClass == null && this.filterTrainer != null) {
                            this.groupTrainingsTimetable[i].cells[j].trainings[0].isShow = this.filterTrainer.id == this.groupTrainingsTimetable[i].cells[j].trainings[0].trainerId.toString();
                        } else if (this.filterClass != null && this.filterTrainer != null) {
                            this.groupTrainingsTimetable[i].cells[j].trainings[0].isShow = this.filterClass.id == this.groupTrainingsTimetable[i].cells[j].trainings[0].trainingClassId.toString() && this.filterTrainer.id == this.groupTrainingsTimetable[i].cells[j].trainings[0].trainerId.toString();
                        }
                    }
                }
            },
            createTraining: function (date: string | null) {
                this.addTrainingDate = date;
                this.isShowCreateGroupTraining = this.isShowGroupTrainings;
                this.isShowCreatePersonalTraining = !this.isShowGroupTrainings;
            },
            findStartWeekDate: function(date: Date){
                let day = date.getDay(),
                diff = date.getDate() - day + (day == 0 ? -6 : 1); 
                return new Date(date.setDate(diff));
            },
            closeAllWindows: function (needUpdate: boolean) {
                this.isShowCreateGroupTraining = false;
                this.isShowCreatePersonalTraining = false;
                this.isShowGroupTraining = false;
                this.isShowPersonalTraining = false;

                history.pushState(history.state, "", "/timetable");

                if (needUpdate) {
                    this.getGroupTrainings();
                    this.getPersonalTrainings();
                }
            },
            updateTraining: function (trainingId: number) {
                this.getTraining(trainingId, false);
            },
            onClickOutside: function () {
                this.isShowButtons = false;
            },
            showCopyTrainingsAlert: function () {
                const selectedWeekFirstDayDate = getFormatedDate(convertToISOString(this.startWeekDate), "DD.MM.yyyy");
                const selectedWeekLastDayDate = getFormatedDate(convertToISOString(this.addDay(this.startWeekDate, 6)), "DD.MM.yyyy");
                const nextWeekFirstDayDate = getFormatedDate(convertToISOString(this.addDay(this.startWeekDate, 7)), "DD.MM.yyyy");
                const nextWeekLastDayDate = getFormatedDate(convertToISOString(this.addDay(this.startWeekDate, 13)), "DD.MM.yyyy");

                this.alertCopyingWeek = `${selectedWeekFirstDayDate} - ${selectedWeekLastDayDate}`;
                this.alertPastedWeek = `${nextWeekFirstDayDate} - ${nextWeekLastDayDate}`;

                this.isShowAlert = true;

                this.isShowButtons = false;
            },
            copyTrainings: function () {
                if (!this.selectedClubZone) return;

                this.isShowAlert = false;
                axiosRequests.post(editUrl(Routes.copyGroupTrainingToNextWeek), { clubZoneId: this.selectedClubZone.id, date: getFormatedDate(convertToISOString(this.startWeekDate), 'yyyy-MM-DD') })
                    .then(
                        () => {
                            this.$store.commit('SET_ADD_SUCCESS_MESSAGES', 'success_messages.trainings_successfully_copied');
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isLoading = false;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 500) {
                                this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            downloadFile: function () {
                this.isShowButtons = false;

                if (!this.selectedClubZone) return;

                axiosRequests.getFile(editUrl(`${Routes.downloadGroupTrainings}?clubZoneId=${this.selectedClubZone.id}&weekStartDate=${getFormatedDate(convertToISOString(this.startWeekDate), 'yyyy-MM-DD') }`))
                    .then(
                        (data: any) => {

                            let fileName = "";

                            if (data.headers["content-disposition"]) {
                                let contentDisposittion = data.headers["content-disposition"].split(";");
                                if (contentDisposittion.length == 2) fileName = decodeURIComponent(contentDisposittion[1].trim().replace('filename=', ''));
                            }

                            if (fileName == "") {
                                this.$store.commit('SET_ADD_ERROR', 'error.error_downloading_file');
                                return;
                            }

                            let fileURL = window.URL.createObjectURL(data.data);
                            let download: any = this.$refs.download;
                            download.href = fileURL;
                            download.setAttribute('download', fileName);
                            download.click()
                        }
                    )
                    .catch(
                        async (err: any) => {

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            getFormatedDate,
            getTextColor
        },
         watch: {
             '$route.params.id': {
                 handler: async function (id) {
                     if (!id) return;

                     await this.getFilters(false);

                     await this.getTraining(id, false);
                 },
                 deep: true,
                 immediate: true
             }
         },
        components: { createGroupTraining: CreateGroupTraining, createPesonalTraining: CreatePesonalTraining, viewGroupTraining: ViewGroupTraining, viewPersonalTraining: ViewPersonalTraining, copyingAlert: CopyingAlert },
        async beforeMount() {
            let timetablePermission = this.$store.getters.getTimetablePermission;

            if (timetablePermission.length == 0) timetablePermission = await retryGetPermissions("timetable");

            if (!timetablePermission?.includes('timetables/trainings/{id}')) this.$router.push('/forbidden');

            this.hasPDFLoadPermission = timetablePermission?.includes('timetables/group-trainings/download-printable');
            this.hasCopyPermission = timetablePermission?.includes('timetables/group-trainings/copy-to-next-week');
            this.hasCreateGroupPermission = timetablePermission?.includes('timetables/group-trainings/create');
            this.hasCreatePersonalPermission = timetablePermission?.includes('timetables/personal-trainings/create');

            this.startWeekDate = this.findStartWeekDate(this.startWeekDate);

            if(!this.$route.params.id) await this.getFilters(true);
        },
    });
</script>

<style scoped>
    .timestamp_header {
        height: 50px;
        width: 55px;
        border-right: 1px solid #D5D5D5;
        border-bottom: 1px solid #101111;
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 2;
    }

    .table_wrapper {
        display: flex;
        overflow: auto;
        padding-right: 8px;
        flex-flow: column;
        padding-bottom: 2px;
    }

    .timestamp {
        width: 56px;
        border-right: 1px solid #D5D5D5;
        border-bottom: 1px solid #D5D5D5;
        color: #65656F;
        display: flex;
        padding: 10px 5px;
        box-sizing: border-box;
    }

    .wrapper_body {
        overflow: hidden;
        max-height: calc(100vh - 183px);
        min-height: 200px;
        display: flex;
        flex-flow: column;
    }

    .timetable_wrapper{
        width: calc(100% - 45px);
        display: flex;
    }

    .week_day_wrapper{
        width: calc(100% / 7);
    }

    .timetable_header {
        height: 50px;
        border-right: 1px solid #D5D5D5;
        border-bottom: 1px solid #101111;
        padding: 0px 24px;
        color: #65656F;
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 2;
    }

    .cell {
        padding: 5px 5px;
        border-right: 1px solid #D5D5D5;
        border-bottom: 1px solid #D5D5D5;
        cursor: pointer;
        position: relative;
        box-sizing: border-box;
    }

    .cell_passed {
        padding: 5px 5px;
        border-right: 1px solid #D5D5D5;
        border-bottom: 1px solid #D5D5D5;
        cursor: not-allowed;
        position: relative;
        box-sizing: border-box;
    }

    .overflow_hidden {
        overflow: hidden;
    }

    .empty_cell {
        padding: 5px 5px;
        border-right: 1px solid #D5D5D5;
        border-bottom: 1px solid #D5D5D5;
        cursor: pointer;
        position: relative;
        box-sizing: border-box;
    }

    .empty_cell:hover {
        transform: scale(1.05);
        border: 1px solid #D5D5D5;
        z-index: 1;
        background-color: #eafafc;
    }

    .cell_disabled {
        background-color: #f2f2f2;
        cursor: default
    }

    .training_wrapper {
        position: absolute;
        z-index: 1;
        width: calc(100% - 10px);
        top: 0px;
        left: 0;
        padding: 5px;
        border-radius: 25px;
        overflow: hidden;
    }

    .training {
        border-radius: 10px;
        border: 1px solid #f2f2f2;
        height: 100%;
        overflow: auto;
        background-color: #00a3ba;
        color: #fff;
        cursor: pointer;
    }

    .training_time {
        margin-left: 8px;
        margin-top: 8px;
        font-weight: 600;
        margin-bottom: 0px;
    }

    .training_name {
        margin-left: 8px;
        margin-top: 8px;
        font-weight: 600;
        margin-bottom: 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .training_trainer_name {
        margin-left: 8px;
        margin-top: 3px;
        margin-bottom: 0px;
        opacity: 0.9;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .training_trainer_capacity {
        margin-left: 8px;
        margin-top: 3px;
        margin-bottom: 0px;
        font-size: 13px;
    }

    .clear_all_filters {
        color: #21A7D1;
        cursor: pointer;
        margin: 8px 0;
        width: fit-content;
    }

    .filter_button {
        display: none;
    }

    .training_button_active {
        display: flex;
        padding: 7px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border: none;
        border-radius: 12px;
        cursor: pointer;
        background-color: #00a3ba;
        font-size: 16px;
        line-height: 24px;
        color: white;
        width: 235px;
        margin: 0px;
    }

    .training_button {
        display: flex;
        padding: 7px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border: none;
        border-radius: 12px;
        cursor: pointer;
        background-color: white;
        font-size: 16px;
        line-height: 24px;
        color: black;
        width: 235px;
        margin: 0px;
    }

    .training_select_wrapper {
        border-radius: 12px;
        display: flex;
        border: 1px solid #D5D5D5;
        width: fit-content;
    }

    .training_buttons_wrapper {
        display: flex;
        justify-content: space-between;
    }

    .new_training {
        display: flex;
        padding: 7px 7px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border: none;
        border-radius: 12px;
        cursor: pointer;
        background-color: #00a3ba;
        font-size: 16px;
        line-height: 24px;
        color: white;
        margin: 0px;
        width: 266px;
    }

    .filter_wrapper{
        margin-top: 16px;
    }

    .filter {
        display: flex;
        justify-content: space-between;
    }

    .club_zone_filter{
        width: 100%;
        margin-right: 16px;
    }

    .classes_filter {
        width: 100%;
        margin-right: 16px;
    }

    .week_switch_button {
        border-radius: 12px;
        background-color: #eaebec;
        border: none;
        cursor: pointer;
        display: flex;
        padding: 11px;
        height: 37px;
        width: 37px;
        justify-content: center;
    }

    .week_switch_button_img {
        margin-top: 1px;
    }

    .week_switch_wrapper {
        display: flex;
        justify-content: end;
        margin-top: -25px;
        margin-bottom: 8px;
        margin-left: 180px;
    }

    .personal_week_switch_wrapper {
        display: flex;
        justify-content: end;
        margin-top: -37px;
        margin-bottom: 16px;
    }

    .load_pdf {
        display: flex;
        padding: 7px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border: none;
        border-radius: 12px;
        cursor: pointer;
        background-color: #df2793;
        font-size: 16px;
        line-height: 24px;
        color: white;
        width: 235px;
        margin-left: 12px;
    }

    .timetable_header_mobile_wrapper_sticky {
        display: none;
    }

    .personal_trainer_filter {
        width: 471px;
    }

    .trainer_photo {
        background-size: cover;
        width: 35px;
        min-width: 35px;
        height: 35px;
        border: 1px solid white;
        border-radius: 50%;
        background-position: center center;
        background-color: white;
        margin-right: 8px;
        margin-top: 8px;
    }

    .training_title_wrapper{
        display: flex;
    }

    .training_title{
        width: calc(100% - 44px);
    }

    .training_service_name {
        margin-left: 8px;
        margin-top: 8px;
        margin-bottom: 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 12px;
    }

    .training_not_paid {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: red;
        margin-top: 12px;
        margin-left: 8px;
    }

    .show_full_personal_training{
       left: 0 !important;
    }

    .hide_personal_training {
        left: 100% !important;
    }

    .menu_button {
        padding: 13px;
        border-radius: 12px;
        background-color: #eaebec;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        margin-left: 12px;
    }

    .menu_img {
        height: 13px;
        width: 13px;
    }

    .menu {
        position: absolute;
        right: 0;
        display: flex;
        flex-flow: column;
        background-color: white;
        width: max-content;
        border: 1px solid #D5D5D5;
        border-radius: 12px;
        top: 39px;
        overflow: hidden;
        z-index: 4;
    }

    .menu_item {
        padding: 12px;
        cursor: pointer;
        border-top: 1px solid #D5D5D5;
    }

    .menu_item:hover {
        background-color: #efefef;
    }

    .menu_item:first-child {
        border-top: none;
    }

   

    @media(max-width: 1900px) {
        .trainer_photo{
            display: none;
        }

        .training_title {
            width: 100%;
        }
    }

    @media(max-width: 1150px) {
        .filter{
            display: block;
        }

        .club_zone_filter {
            margin-right: 0px;
            margin-bottom: 12px;
        }

        .classes_filter {
            margin-right: 0px;
            margin-bottom: 12px;
        }

        .load_pdf{
            display: none;
        }

        .week_day_wrapper {
            width: 100%;
        }

        .week_day_wrapper_hide {
            display: none;
        }

        .timestamp_header{
            display: none;
        }

        .timetable_header {
            display: none;
        }

        .timetable_header_mobile_week_day {
            display: block;
            visibility: hidden;
            width: 14px;
            margin: auto;
            margin-top: 12px;
            margin-bottom: 8px;
        }

        .timetable_header_mobile_week_day:first-letter {
            visibility: visible;
        }

        .timetable_header_mobile_wrapper_sticky{
            display: block;
            background-color: white;
            position: sticky;
            top:0;
            z-index:2;
        }

        .timetable_header_mobile_wrapper {
            display: flex;
            background-color: #f6f7f8;
            border-radius: 16px 16px 0px 0px;
        }

        .timetable_header_mobile {
            width: calc(100% / 7);
            cursor: pointer;
            text-align: center;
            color: #65656F;
            height: 68px;
            border-bottom: 1px solid #D5D5D5;
        }

        .timetable_header_mobile_active {
            color: #21A7D1;
            border-bottom: 1px solid #21A7D1;
        }

        .cell{
            border-right: none;
        }

        .trainer_photo {
            display: block;
        }

        .training_title {
            width: calc(100% - 40px);
        }

        .table_wrapper {
            overflow-x: hidden;
        }
    }

    @media(max-width: 860px) {
        .training_buttons_wrapper {
            display: block;
        }

        .new_training {
            width: 100%;
            margin-top: 12px;
        }

        .training_select_wrapper {
            width: 100%;
        }

        .training_button {
            width: 50%;
        }

        .training_button_active {
            width: 50%;
        }

        .personal_trainer_filter {
            width: 100%;
        }

        .personal_week_switch_wrapper {
            margin-top: 8px;
            margin-bottom: 8px;
        }

        .menu_button {
            display: none;
        }
    }

    @media(max-width: 500px) {     
        .show_filtes_mobile {
            display: block;
            padding-top: 4px;
        }

        .hide_filtes_mobile {
            display: none;
        }

        .filter_button {
            display: flex;
            width: 100%;
            padding: 10px 24px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border: none;
            border-radius: 12px;
            cursor: pointer;
            background-color: #f2f2f2;
            font-size: 16px;
            line-height: 24px;
        }

        .new_training {
            margin-bottom: 12px;
        }

        .filter_wrapper {
            margin-top: 0px;
        }

        .week_switch_wrapper {
            justify-content: space-between;
            margin-top: 8px;
        }

        .personal_week_switch_wrapper {
            justify-content: space-between;
            margin-top: 8px;
        }

        .personal_trainer_filter {
            padding-top: 4px;
        }

        .table_wrapper {
            padding-right: 4px;
        }

        .week_switch_wrapper {
            margin-left: 0px;
        }
    }
</style>