<v-alert v-model="showAlert" :title="'clients.view.view_restriction.alert_title'" :text="'clients.view.view_restriction.alert_text'" :successButtonText="$t('clients.view.view_restriction.yes')" :cancelButtonText="$t('clients.view.view_restriction.cancel')" @success="deleteRestriction" @cancel="showAlert = false"></v-alert>

<div class="view_restriction_wrapper">
    <div class="view_restriction_body" v-click-outside="clickOutside">
        <div class="view_restriction_title"><span class="view_restriction_title_alert"><img src="/assets/img/alert.svg" /></span>{{ restrictionList?.length == 1 ? $t('clients.view.view_restriction.title_with_one_restriction') : $t('clients.view.view_restriction.title_with_many_restrictions') }}</div>
        <div class="view_restriction">
            <div class="view_restriction_content_wrapper" v-if="restrictionList?.length != 1">
                <div v-for="restriction in restrictionList" class="view_restriction_content">
                    <div class="view_restriction_club_name_wrapper">
                        <div class="view_restriction_club_name">{{`${$t('clients.view.view_restriction.in_club')} "${restriction.clubName}":`}}</div>
                        <img class="delete_restriction" src="/assets/img/close.svg" @click="showDeleteRestrictionAlert(restriction.id)" v-if="hasDeleteRestrictionPermission" />
                    </div>
                    <div v-html="restriction.comments"></div>
                </div>
            </div>

            <div class="view_restriction_content_wrapper" v-if="restrictionList?.length == 1">
                <div class="view_restriction_content">
                    <div v-html="restrictionList[0].comments"></div>
                </div>
            </div>
        </div>
        <div class="view_restriction_buttons_wrapper">
            <button class="secondary_button" @click="$emit('close')">{{$t('clients.view.view_restriction.close')}}</button>
            <button class="delete_button" @click="showDeleteRestrictionAlert(restrictionList[0].id)" :disabled="isButtonDisabled" v-if="hasDeleteRestrictionPermission && restrictionList?.length == 1">{{$t('clients.view.view_restriction.remove_restriction')}}</button>
        </div>
    </div>
</div>

