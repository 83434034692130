<template src="./addRestriction.html"></template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { axiosRequests } from '../../../../services/axiosinstance';
    import { editUrl } from '../../../../services/helper';
    import { Routes } from '../../../../const';
    import { SelectModel } from '../../../../models/GUIModels';
    import { ClubShort } from '../../../../models/Club';

    interface ComponentModel {
        text: string,
        textErrorText: string,
        isButtonDisabled: boolean,
        clubs: SelectModel[],
        selectedClub: SelectModel | null,
        clubErrorText: string,
    }

    export default defineComponent({
        name: 'AddRestriction',
        props: ['clientId'],
        data(): ComponentModel {
            return {
                text: "",
                textErrorText: "",
                isButtonDisabled: false,
                clubs: [],
                selectedClub: null,
                clubErrorText: "",
            }
        },
        methods: {
            close: function () {
                this.$emit("close");
            },
            getClubs: async function () {
                await axiosRequests.get(editUrl(`${Routes.getAvailableClubsForAddingRestriction}?ClientId=${this.clientId}`))
                    .then(
                        (data: ClubShort[]) => {
                            this.clubs = data.map(x => { return { id: x.clubId.toString(), text: x.clubName, isTranslated: false, disabled: false, groupName: null } });

                            this.selectedClub = this.clubs.find(x => x.id == this.$store.getters.getSelectedClubId) ?? null;

                            if (this.selectedClub == null) this.selectedClub = this.clubs[0];
                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            save: function () {

                if (!this.text) this.textErrorText = 'clients.view.add_restriction.comment_error_text';
                if (!this.selectedClub) this.clubErrorText = 'clients.view.add_restriction.club_error';

                if (this.textErrorText || this.clubErrorText) return;

                this.isButtonDisabled = true;

                const data = { clubId: this.selectedClub?.id, comments: this.text };

                axiosRequests.post(editUrl(Routes.addRestrictionsToClient, this.clientId), data)
                    .then(
                        () => {
                            this.isButtonDisabled = false;
                            this.$store.commit('SET_ADD_SUCCESS_MESSAGES', 'success_messages.restrictions_to_client_has_been_set');
                            this.$emit("close", true);
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isButtonDisabled = false;
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                 this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            checkPressedButton: function (event: any) {
                if (event.keyCode == 27) this.close();
            }
        },
        async beforeMount() {
            await this.getClubs()
        },
        mounted() {
            window.addEventListener('keyup', this.checkPressedButton);
        },
        beforeUnmount() {
            window.removeEventListener('keyup', this.checkPressedButton);
        }
    });
</script>

<style scoped>
    .add_restriction_wrapper {
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: #b5b4b466;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .add_restriction_body {
        background-color: white;
        max-width: 480px;
        width: max-content;
        border-radius: 24px;
        overflow: hidden;
        animation: show 0.6s linear;
    }

    .add_restriction_title {
        font-size: 17px;
        letter-spacing: 1px;
        font-weight: 600;
        background-color: #f6f7f8;
        padding: 16px 24px;
    }

    .add_restriction {
        margin-top: 12px;
        padding: 0px 24px;
    }

    .add_restriction_buttons_wrapper {
        margin-top: 12px;
        display: flex;
        padding: 0px 24px;
        margin-bottom: 8px;
    }

    .button_space {
        margin-right: 16px;
    }

    .add_restriction_text {
        margin-top: 0px;
        margin-bottom: 16px;
        color: #65656F;
        font-weight: bold;
    }

    @media(max-width: 500px) {
        .add_restriction_body {
            width: 98%;
        }

        .add_restriction_buttons_wrapper {
            display: block;
            margin-bottom: 16px;
        }

        .button_space {
            margin-right: 0px;
        }
    }
</style>