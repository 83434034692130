<template src="./viewRestrictions.html"></template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { axiosRequests } from '../../../../services/axiosinstance';
    import { editUrl, checkIfClickInsideAlert, retryGetPermissions } from '../../../../services/helper';
    import { Routes } from '../../../../const';
    import { Restriction } from '../../../../models/Clients';

    interface ComponentModel {
        restrictionList: Restriction[],
        hasDeleteRestrictionPermission: boolean,
        showAlert: boolean,
        selectRestrictionId: number | null,
        isButtonDisabled: boolean
    }

    export default defineComponent({
        name: 'ViewRestrictions',
        props: ['restrictions'],
        emits: ["close"],
        data(): ComponentModel {
            return {
                restrictionList: [],
                hasDeleteRestrictionPermission: false,
                showAlert: false,
                selectRestrictionId: null,
                isButtonDisabled: false
            }
        },
        methods: {
            clickOutside: function (event: any) {
                if (!checkIfClickInsideAlert(event.target)) this.close();
            },
            close: function () {
                this.$emit("close");
            },
            deleteRestriction: function () {
                if (!this.selectRestrictionId) return;

                this.showAlert = false;
                this.isButtonDisabled = true;

                axiosRequests.delete(editUrl(Routes.deleteRestriction, this.selectRestrictionId))
                    .then(
                        () => {
                            this.$store.commit('SET_ADD_SUCCESS_MESSAGES', 'success_messages.restrictions_removed');

                            let index = this.restrictionList.findIndex(x => x.id == this.selectRestrictionId);
                            if (index != -1) this.restrictionList.splice(index, 1);

                            this.selectRestrictionId = null;

                            this.isButtonDisabled = false;

                            if (this.restrictionList.length == 0) this.$emit("close", true);
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isButtonDisabled = false;

                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                 this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            showDeleteRestrictionAlert: function (restrictionId: number) {
                this.showAlert = true;
                this.selectRestrictionId = restrictionId;
            },
            checkPressedButton: function (event: any) {
                if (event.keyCode == 27) this.close();
            }
        },
        async beforeMount() {
            let clientsPermission = this.$store.getters.getClientsPermission;

            if (clientsPermission.length == 0) clientsPermission = await retryGetPermissions("client");

            this.hasDeleteRestrictionPermission = clientsPermission.includes('clients/restrictions/{id}/delete');

            this.restrictionList = this.restrictions;
        },
        mounted() {
            window.addEventListener('keyup', this.checkPressedButton);
        },
        beforeUnmount() {
            window.removeEventListener('keyup', this.checkPressedButton);
        }
    });
</script>

<style scoped>
    .view_restriction_wrapper {
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: #b5b4b466;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .view_restriction_body {
        background-color: white;
        max-width: 480px;
        width: max-content;
        border-radius: 24px;
        overflow: hidden;
        animation: show 0.6s linear;
    }

    .view_restriction_title {
        font-size: 17px;
        letter-spacing: 1px;
        font-weight: 600;
        background-color: #f6f7f8;
        padding: 16px 24px;
        display: flex;
    }

    .view_restriction {
        margin-top: 12px;
        padding: 0px 12px 0px 24px;
        width: 350px;
    }

    .view_restriction_buttons_wrapper {
        margin-top: 12px;
        display: flex;
        padding: 0px 24px;
        margin-bottom: 8px;
    }

    .view_restriction_club_name {
        font-weight: bold;
        margin-bottom: 8px;
    }

    .view_restriction_club_name_wrapper{
        display: flex;
        justify-content: space-between;
        position: relative;
    }

    .delete_restriction {
        position: absolute;
        top: 1px;
        right: 0px;
        cursor: pointer;
    }

    .view_restriction_content_wrapper {
        max-height: 300px;
        overflow: auto;
        padding-right: 10px;
    }

    .view_restriction_content {
        border-bottom: 1.5px solid #d5d5d5;
        margin-bottom: 8px;
        padding-bottom: 4px;
    }

    .view_restriction_content:last-child {
        border-bottom: unset;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }

    .view_restriction_title_alert {
        position: relative;
        width: 20px;
        display: flex;
        height: 20px;
        margin-right: 12px;
    }

    .view_restriction_title_alert img {
        height: 26px;
        position: absolute;
        top: -3px;
    }

    .delete_button {
        display: flex;
        padding: 7px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border: none;
        border-radius: 12px;
        cursor: pointer;
        background-color: #FBE0E0;
        font-size: 16px;
        line-height: 24px;
        margin-top: 6px;
        color: black;
        min-width: 150px;
        height: min-content;
        margin-left: 16px;
    }

    .delete_button:disabled {
        opacity: 0.5;
        cursor: no-drop;
    }

    @media(max-width: 500px) {
        .view_restriction_body {
            width: 98%;
        }

        .view_restriction_buttons_wrapper {
            display: block;
            margin-bottom: 16px;
        }

        .delete_button {
            margin-left: 0px;
            width: 100%;
        }

    }
</style>