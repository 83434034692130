<div class="last_viewed_client_wrapper">
    <div :class="lastViewedClient.id == clientId && !isLoad ? 'last_viewed_client last_viewed_client_active' : 'last_viewed_client'" :style="{ width: `calc(100% / ${lastViewedClients.length} + ${lastViewedClient.id == clientId ? 0 : 13}px)`, zIndex: lastViewedClients.length - index }" v-for="(lastViewedClient, index) in lastViewedClients">
        <p class="last_viewed_client_p" @click="redirectToLastViewedClient(lastViewedClient.id)">{{lastViewedClient.fi}}</p>
        <div class="last_viewed_client_img_wrapper" @click="deleteLastViewedClient(index)"><img class="last_viewed_client_img" src="/assets/img/close.svg"  /></div>
        <div v-if="lastViewedClient.id != clientId" :class="index > 7 ? 'last_viewed_client_hover last_viewed_client_hover_end' : 'last_viewed_client_hover'">{{lastViewedClient.fi}}</div>
    </div>
</div>


<v-alert v-model="showRegistrationPassAlert" :title="'clients.view.passage'" :html="alertRegistrationPassText" :successButtonText="$t('clients.confirm')" :cancelButtonText="$t('clients.view.cancel')" @success="registerPass" @cancel="showRegistrationPassAlert = false"></v-alert>

<v-alert v-model="showAlert" :title="alertTitle" :text="alertText" :successButtonText="$t('clients.yes')" :cancelButtonText="$t('clients.view.cancel')" @success="applySuccessAlertFunction" :doNotTranslated="!isTranslateAlert" @cancel="showAlert = false"></v-alert>

<changeContractStatus v-if="showChangeContractStatus" :contract="currentClient?.contracts[currentContractIndex]" @close="closeChangeContractStausWindow"></changeContractStatus>
<writeMessageForClient v-if="showWriteMessageForClient" @close="showWriteMessageForClient = false" :clientId="clientId" :hasMobileDevice="currentClient?.mobileDevices.length > 0"></writeMessageForClient>
<addClientTrainingBlock v-if="showAddClientTrainingBlock" @close="closeAddClientTrainingBlockWindow" :client="currentClient"></addClientTrainingBlock>
<retryToPayTrainingBlock v-if="showRetryToPayTrainingBlock" :trainingBlockId="trainingBlockRetryToPayId" :hasClientPaymentMean="currentClient?.activePaymentMean" @close="showRetryToPayTrainingBlock = false" @update="updateTrainingBlocks"></retryToPayTrainingBlock>
<addPhoto  v-if="showAddPhoto" :clientId="clientId"  @close="closeAddPhotoWindow" ></addPhoto>
<linkPassCard v-if="showlinkPassCard" @close="showlinkPassCard = false" :clientId="clientId" @linkPassCard="createPassCard"></linkPassCard>
<updateClientSourse v-if="showClientSourses" :clientId="currentClient?.id" :isRequireSalesChannelSelectionOnSignUp="currentClient?.isRequireSalesChannelSelectionOnSignUp" :clientSourceId="currentClient?.clientSourceId" @close="closeUpdateClientSourse"></updateClientSourse>

<addRestriction :clientId="currentClient?.id" v-if="isShowAddRestriction" @close="closeAddRestriction"></addRestriction>
<viewRestrictions :restrictions="currentClient?.restrictions" v-if="isShowViewRestrictions" @close="closeViewRestrictions"></viewRestrictions>

<passCardAlert :title="passCardAlertTitle" v-if="isShowAwaitingCard" @close="closeAwaitingCard"></passCardAlert>


<div class="change_payment_frame_wrapper" v-show="isShowPaymentFrame">
    <iframe class="change_payment_frame" ref="payment_frame" :src="paymentFrameSrc"></iframe>
    <img class="frame_close_button" src="/assets/img/close.svg" @click="closeFrame" />
</div>

<div class="content_wrapper client_wrapper">
    <div :class="lastViewedClients[0]?.buttonPath ? 'top_buttons_wrapper_space_between' : 'top_buttons_wrapper_end'">
        <button @click="clickOnBackButton" class="button button_back" v-show="lastViewedClients[0]?.buttonPath"><img class="back_button_img" src="/assets/img/arrow-left.svg"><span>{{lastViewedClients[0]?.isNeedTranslatedButtonText && lastViewedClients[0]?.buttonText ? $t(lastViewedClients[0]?.buttonText) : lastViewedClients[0]?.buttonText }}</span></button>

        <div class="flex mb-3" v-if="hasConnection">
            <a class="link_card_button" @click="showlinkPassCard = true">{{$t('clients.view.link_card')}}</a>
            <a class="untie_card" v-if="currentClient?.hasPassCard" @click="deactivatePassCard">{{$t('clients.view.untie_card')}}</a>
        </div>
    </div>
    
    <v-scrollable-menu v-model="activeTab" :items="tabs" @update:modelValue="clickOnTab"></v-scrollable-menu>

    <div class="tab_body" v-if="activeTab.url == 'profile'">
        <div class="profile_wrapper">
            <div class="photo_wrapper">
                <div class="img_wrapper">
                    <div class="img_block position-relative">
                        <p class="photo_text">{{$t('clients.view.photo')}}</p>
                        <div v-if="currentClient?.photoUrl" class="photo" :style="{ 'background-image': `url(${currentClient?.photoUrl})` }"></div>
                        <button class="camera" @click="showAddPhoto = true" v-if="hasUploadPhotoPermission"><img class="camera_img" src="/assets/img/camera.svg" /></button>
                    </div>
                    <div class="img_block">
                        <p class="photo_text">{{$t('clients.view.avatar')}}</p>
                        <div v-if="currentClient?.avatarUrl" class="photo" :style="{ 'background-image': `url(${currentClient?.avatarUrl})`}"></div>
                    </div>
                </div>

                <div class="photo_buttons_wrapper">
                    <button class="photo_button mt-3" @click="createOrRedirectToLead" :disabled="isCreateLeadDisabled">{{currentClient?.hasLead ? $t('clients.view.to_crm') :  $t('clients.view.create_lead') }}</button>
                    <button class="photo_button mt-3" v-if="hasWriteMessagePermission && currentClient?.email" @click="showWriteMessageForClient=true">{{$t('clients.view.write')}}</button>
                </div>
            </div>

            <div class="data_wrapper">
                <div class="client_info_wrapper" v-show="!isEdit">
                    <div class="client_info_title">
                        <div class="w-100">
                            <p class="client_info_fi">{{currentClient?.lastName}} {{currentClient?.firstName}} <span class="restriction_alert" v-if="currentClient?.restrictions.length > 0" @click="isShowViewRestrictions = true"><img src="/assets/img/alert.svg" /></span></p>
                            <p :class="currentClient?.phone || isLoad ? 'client_info_phone' : 'client_info_phone error_text'">{{addPhoneMask(currentClient?.phone) || $t('clients.view.phone_absent')}}</p>
                        </div>
                        <div v-if="hasEditPermission || hasChangePasswordPermission" class="position-relative" v-click-outside="onClickOutsideEditMenu">
                            <button class="edit_button" @click="isShowEditMenu = !isShowEditMenu"><img class="edit_img" src="/assets/img/ellipsis.svg"></button>
                            <div class="edit_menu" v-show="isShowEditMenu">
                                <span v-if="hasEditPermission" class="edit_menu_item" @click="openEdit">{{$t('clients.view.edit')}}</span>
                                <span v-if="hasChangePasswordPermission" class="edit_menu_item" @click="showResetPasswordAlert">{{$t('clients.view.reset_password')}}</span>
                                <span v-if="hasSetRestriction" class="edit_menu_item" @click="isShowAddRestriction = true">{{$t('clients.view.set_restriction')}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="client_info_body">
                        <div class="first_block_wrapper">
                            <div v-if="!currentClient?.isDisplaySalesChannelInClientProfile">
                                <div class="one_block_wrapper mt-3">
                                    <p class="block_label">{{$t('clients.view.email')}}</p>
                                    <p :class="currentClient?.email || isLoad ? 'block_info' : 'block_info error_text'">{{currentClient?.email || $t('clients.view.absent')}}</p>
                                </div>
                            </div>

                            <div class="block_row" v-if="currentClient?.isDisplaySalesChannelInClientProfile">
                                <div class="two_block_wrapper mt-3">
                                    <p class="block_label">{{$t('clients.view.email')}}</p>
                                    <p :class="currentClient?.email || isLoad ? 'block_info' : 'block_info error_text'">{{currentClient?.email || $t('clients.view.absent')}}</p>
                                </div>
                                <div class="two_block_wrapper mt-3">
                                    <p class="block_label">{{$t('clients.view.sales_channel')}}</p>
                                    <p :class="currentClient?.clientSourceName || isLoad ? 'block_info can_change_client_sourse' : 'block_info error_text can_change_client_sourse'" v-if="currentClient?.isHighlightEmptySalesChannelInClientProfile" @click="showClientSourses = true">{{currentClient?.clientSourceName || $t('clients.view.client_source_absent')}}</p>
                                    <p class="block_info" v-else>{{currentClient?.clientSourceName || $t('clients.view.client_source_absent')}}</p>
                                </div>
                            </div>


                            <div class="block_row">
                                <div class="two_block_wrapper mt-3">
                                    <p class="block_label">{{$t('clients.view.birthday')}}</p>
                                    <p :class="currentClient?.birthday || isLoad ? 'block_info' : 'block_info error_text'">{{getFormatedDate(currentClient?.birthday, "DD MMMM yyyy") || $t('clients.view.absent')}}</p>
                                </div>
                                <div class="two_block_wrapper mt-3">
                                    <p class="block_label">{{$t('clients.view.sex')}}</p>
                                    <p class="block_info">{{currentClient?.sex ? $t('clients.male') : $t('clients.female') }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="second_block_wrapper">
                            <div class="block_row">
                                <div class="two_block_wrapper mt-3">
                                    <p class="block_label">{{$t('clients.view.club')}}</p>
                                    <p class="block_info club_name_info">{{currentClient?.lastClubName }}</p>
                                </div>
                                <div class="two_block_wrapper mt-3">
                                    <p class="block_label">{{$t('clients.view.stay')}}</p>

                                    <div class="flex" v-if="showPassButtonIn && currentClient?.isInClub == false && currentClient?.restrictions.length == 0"><div class="dot dot_disable"></div><p class="in_club_status active_pass_registration_button" @click="showRegistrationPass">{{ $t('clients.view.not_in_club') }}</p></div>
                                    <div class="flex" v-if="showPassButtonOut && currentClient?.isInClub == true && currentClient?.restrictions.length == 0"><div class="dot dot_active"></div><p class="in_club_status active_pass_registration_button" @click="showRegistrationPass">{{ $t('clients.view.in_club') }}</p></div>

                                    <div class="flex" v-if="(!showPassButtonIn || currentClient?.restrictions.length != 0) && currentClient?.isInClub == false"><div class="dot dot_disable"></div><p class="in_club_status">{{ $t('clients.view.not_in_club') }}</p></div>
                                    <div class="flex" v-if="(!showPassButtonOut || currentClient?.restrictions.length != 0) && currentClient?.isInClub == true "><div class="dot dot_active"></div><p class="in_club_status">{{ $t('clients.view.in_club') }}</p></div>

                                </div>
                            </div>
                            <div class="block_row">
                                <div class="two_block_wrapper mt-3">
                                    <p class="block_label">{{$t('clients.view.last_in')}}</p>
                                    <p class="block_info">{{getFormatedDate(currentClient?.lastInDate, "DD.MM.yyyy HH:mm") || '-' }}</p>
                                </div>
                                <div class="two_block_wrapper mt-3">
                                    <p class="block_label">{{$t('clients.view.last_out')}}</p>
                                    <p class="block_info">{{getFormatedDate(currentClient?.lastOutDate, "DD.MM.yyyy HH:mm") || '-'}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="client_info_wrapper" v-show="isEdit">
                    <div class="client_edit">
                        <div class="edit_client_elment_wrapper"><v-input class="edit_client_elment" v-model="tempLastName" :label="$t('clients.view.lastname')" height="22" :errorText="lastnameErrorText" @focus="lastnameErrorText = ''" clearable></v-input></div>
                        <div class="edit_client_elment_wrapper"><v-input class="edit_client_elment" v-model="tempFirstName" :label="$t('clients.view.firstname')" height="22" :errorText="firstnameErrorText" @focus="firstnameErrorText = ''" clearable></v-input></div>
                        <div class="edit_client_elment_wrapper"><v-select class="edit_client_elment" v-model="tempSex" :label="'clients.view.sex'" :items="sex"></v-select></div>
                        <div class="edit_client_elment_wrapper"><v-input class="edit_client_elment" v-model="tempEmail" :label="$t('clients.view.email')" height="22" :errorText="emailErrorText" @focus="emailErrorText = ''" clearable></v-input></div>
                        <div class="edit_client_elment_wrapper"><v-input class="edit_client_elment" v-model="tempPhone" placeholder="+7 (000) 000-00-00" v-maska:[options] :label="$t('clients.view.phone')" height="22" :errorText="phoneErrorText" @focus="phoneErrorText = ''" clearable></v-input></div>
                        <div class="edit_client_elment_wrapper"><v-input class="edit_client_elment" v-model="tempBirthday" inputType="date" :label="$t('clients.view.birthday')" height="22" :errorText="birthdayErrorText" :min="minBirthdayDate" :max="maxBirthdayDate" @focus="birthdayErrorText = ''" clearable></v-input></div>
                    </div>


                    <div class="edit_client_button_wrapper">
                        <button class="primary_button button_space" @click="updateClientData" :disabled="isEditSaveButtonDisabled">{{$t('clients.view.save')}}</button>
                        <button class="secondary_button" @click="isEdit = false">{{$t('clients.view.cancel')}}</button>
                    </div>

                </div>

                <div class="client_info_wrapper" v-if="currentClient?.contracts?.length > 0">
                    <div class="client_info_title_contract flex">
                        <div class="w-100">
                            <p class="client_info_agreement" @click="openContract(currentClient?.contracts[currentContractIndex].id)">{{currentClient?.contracts[currentContractIndex].agreement}} ({{currentClient?.contracts[currentContractIndex].clubName}})</p>
                            <div class="flex">
                                <p class="client_info_contract">{{currentClient?.contracts[currentContractIndex].contract}}</p>
                                <div class="flex client_info_contract_status can_change_status" v-if="currentClient?.contracts[currentContractIndex].isChangeContractStatusActive" @click="showChangeContractStatus = true"><div :class="'dot dot_contract_' + getContractStatus(currentClient?.contracts[currentContractIndex].status)"></div>{{$t('contract_status.' + getContractStatus(currentClient?.contracts[currentContractIndex].status))}}</div>
                                <div class="flex client_info_contract_status" v-else><div :class="'dot dot_contract_' + getContractStatus(currentClient?.contracts[currentContractIndex].status)"></div>{{$t('contract_status.' + getContractStatus(currentClient?.contracts[currentContractIndex].status))}}</div>
                            </div>
                            <p class="client_info_contract_status_description">
                                {{currentClient?.contracts[currentContractIndex].contractStatusDescription}}
                                <button class="contract_status_cancel_button" v-if="currentClient?.contracts[currentContractIndex].isShowContractStatusCancelButton" @click="showResetCancelStatusAlert(currentClient?.contracts[currentContractIndex])">{{$t('clients.view.cancel')}}</button>
                                <button class="contract_status_cancel_button" v-if="currentClient?.contracts[currentContractIndex].isShowActivateNewContractButton" @click="showPayAlert('activateNewContract')">{{$t('clients.view.pay')}}</button>
                                <button class="contract_status_cancel_button" v-if="currentClient?.contracts[currentContractIndex].isShowPayDeptButton" @click="showPayAlert('payDept')">{{$t('clients.view.pay')}}</button>
                            </p>
                        </div>
                        <div class="flex" v-if="currentClient?.contracts.length > 1">
                            <button @click="previousContact" :class="currentContractIndex != 0 ? 'contract_switch_button mr-2' : 'contract_switch_button contract_switch_button_disable mr-2'"><img class="contract_switch_button_img" src="/assets/img/arrow-left.svg"></button>
                            <p class="contract_switch_text">{{currentContractIndex + 1}}/{{currentClient?.contracts.length}}</p>
                            <button @click="nextContact" :class="currentContractIndex != currentClient?.contracts.length - 1 ? 'contract_switch_button ml-2' : 'contract_switch_button contract_switch_button_disable ml-2'"><img class="contract_switch_button_img" src="/assets/img/arrow-right.svg"></button>
                        </div>
                    </div>

                    <div class="client_info_body_contract">
                        <div class="client_info_body_contract_data">
                            <div class="first_block_wrapper">
                                <div class="block_row">
                                    <div class="two_block_wrapper mt-3">
                                        <p class="block_label">{{ $t('clients.view.start_date') }}</p>
                                        <p class="block_info">{{ getFormatedDate(currentClient?.contracts[currentContractIndex].startDate, "DD.MM.yyyy") || '-' }}</p>
                                    </div>
                                    <div class="two_block_wrapper mt-3">
                                        <p class="block_label">{{ $t('clients.view.end_date') }}</p>
                                        <p class="block_info">{{ getFormatedDate(currentClient?.contracts[currentContractIndex].endDate, "DD.MM.yyyy") || '-' }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="second_block_wrapper">
                                <div class="block_row">
                                    <div class="two_block_wrapper mt-3">
                                        <p class="block_label">{{ $t('clients.view.referralcode') }}</p>
                                        <p class="block_info">{{ currentClient?.contracts[currentContractIndex].referralPromocode }}</p>
                                    </div>
                                    <div class="two_block_wrapper mt-3">
                                        <p class="block_label">{{ $t('clients.view.freeze') }}</p>
                                        <p class="block_info">{{ $t('clients.view.available_days')}}: {{currentClient?.contracts[currentContractIndex].availableFreezingDaysNumber}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="client_info_body_contract_data" v-if="currentClient?.contracts[currentContractIndex].pin">
                            <div class="first_block_wrapper">
                                <div class="block_row">
                                    <div class="two_block_wrapper mt-3">
                                        <p class="block_label">{{ $t('clients.view.pin') }}</p>
                                        <p class="block_info">{{ currentClient?.contracts[currentContractIndex].pin }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="client_info_body_contract_data">
                            <div class="first_block_wrapper">
                                <div>
                                    <div class="one_block_wrapper mt-3">
                                        <p class="block_label">{{$t('clients.view.previous_payment')}}</p>
                                        <div class="flex" v-if="currentClient?.contracts[currentContractIndex].previousPaymentDueDate">
                                            <div class="dot dot_contract_active mr-2"></div>
                                            <p class="block_info ">{{getFormatedAmount(currentClient?.contracts[currentContractIndex].previousPaymentTotal) }} {{currentClient?.contracts[currentContractIndex].currencySign }} <span class="contract_payment_date">{{ getFormatedDate(currentClient?.contracts[currentContractIndex].previousPaymentDueDate, "DD.MM.yyyy") || '-' }}</span></p>
                                        </div>
                                        <div class="flex" v-else><p class="block_info ">-</p></div>
                                        <div class="client_info_contract_previous_payment_queue" v-show="currentClient?.contracts[currentContractIndex].isShowPreviousPaymentQueueDate">({{$t('clients.view.paid')}} {{ getFormatedDate(currentClient?.contracts[currentContractIndex].previousPaymentQueueDate, "DD.MM.yyyy") || '-' }})</div>
                                    </div>
                                </div>
                            </div>

                            <div class="second_block_wrapper">
                                <div>
                                    <div class="one_block_wrapper mt-3">
                                        <p class="block_label">{{$t('clients.view.next_payment')}}</p>
                                        <div class="flex" v-if="currentClient?.contracts[currentContractIndex].nextPaymentDueDate">
                                            <div :class="currentClient?.contracts[currentContractIndex].isShowNextPaymentDueDate ? 'dot dot_contract_indebt mr-2' : 'dot dot_contract_active mr-2'"></div>
                                            <p class="block_info ">{{getFormatedAmount(currentClient?.contracts[currentContractIndex].nextPaymentTotal) }} {{currentClient?.contracts[currentContractIndex].currencySign }} <span class="contract_payment_date">{{ getFormatedDate(currentClient?.contracts[currentContractIndex].nextPaymentQueueDate, "DD.MM.yyyy") || '-' }}</span></p>
                                        </div>
                                        <div class="flex" v-else><p class="block_info ">-</p></div>
                                        <div class="client_info_contract_previous_payment_queue" v-show="currentClient?.contracts[currentContractIndex].isShowNextPaymentDueDate">({{$t('clients.view.in_debt_since')}} {{ getFormatedDate(currentClient?.contracts[currentContractIndex].nextPaymentDueDate, "DD.MM.yyyy") || '-' }})</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="client_info_body_contract_data" v-if="currentClient?.contracts[currentContractIndex].services.length > 0">
                            <div class="mt-3 w-100">
                                <p class="block_label">{{$t('clients.view.services')}}</p>

                                <div class="services_wrapper">
                                    <div v-for="service in currentClient?.contracts[currentContractIndex].services" class="service_wrapper">
                                        <div class="service">
                                            <div class="service_data_wrapper">
                                                <p class="mt-0 mb-0 mr-2">{{service.name}}</p>
                                                <p class="mt-0 mb-0 service_price">{{getFormatedAmount(service.price)}} {{currentClient?.contracts[currentContractIndex].currencySign }}</p>
                                            </div>

                                            <div class="flex client_info_servicecontract_status"><div :class="'dot dot_service_contract_' + getServiceContractStatus(service.status)"></div>{{$t('service_contract_status.' + getServiceContractStatus(service.status))}}</div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="client_info_wrapper" v-show="!editComment">
                    <div class="client_info_title">
                        <div class="comments_title">
                            <p class="client_info_comments">{{$t('clients.view.comments')}}</p>
                            <button class="comments_add_button" @click="newComment = ''; editComment = true" v-if="hasAddCommentPermission"><span class="comments_add_button_text">{{$t('clients.view.add')}}</span><img class="" src="/assets/img/plus.svg"></button>
                        </div>
                        <div class="comments_show_button" v-show="currentClient?.comments.length > 0">
                            <button class="edit_button" @click="showComments = !showComments"><img class="edit_img" :src="showComments ? '/assets/img/preview-close.svg' : '/assets/img/preview-open.svg'"></button>
                        </div>
                    </div>

                    <div class="client_info_comment_body" v-show="showComments">
                        <div class="comment_wrapper" v-for="comment in currentClient?.comments">
                            <div class="comment_title">
                                <p class="comment_author">{{ comment.userName }}</p>
                                <p class="comment_date">{{ getFormatedDate(comment.createDate, "DD.MM.yyyy HH:mm") || '-' }}</p>
                                <img class="delete_comment" src="/assets/img/close.svg" @click="showDeleteCommentdAlert(comment.id)" v-if="hasDeleteCommentPermission" />
                            </div>
                            <div>
                                <p class="comment_text" v-html="comment.text"></p>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="client_info_wrapper" v-show="editComment">
                    <div class="client_edit">
                        <v-textarea v-model="newComment" :label="$t('clients.view.new_comment')" :errorText="newCommentErrorText" @focus="newCommentErrorText = ''"></v-textarea>
                    </div>


                    <div class="edit_client_button_wrapper">
                        <button class="primary_button button_space" @click="createComment" :disabled="isNewCommentSaveButtonDisabled">{{$t('clients.view.save')}}</button>
                        <button class="secondary_button" @click="editComment = false">{{$t('clients.view.cancel')}}</button>
                    </div>

                </div>

            </div>
        </div>
    </div>

    <div class="tab_body" v-if="activeTab.url == 'contracts'">
        <button class="new_mobile_button" v-if="isShowNewContractButton" @click="$router.push(`/client/${clientId}/contract/create`)">{{$t('clients.view.add_contract')}}</button>
        <div class="mt-3 total_wrapper">
            <p class="total" v-show="contractsTableData.length > 0">{{$t('clients.view.total_contracts')}}: {{contractsTableData.length}}</p>

            <div>
                <a v-if="isShowNewContractButton" class="new" @click="$router.push(`/client/${clientId}/contract/create`)">{{$t('clients.view.add_contract')}}</a>
            </div>
        </div>

        <div class="mt-1">
            <v-datatable class="pointer" :columTemplates="contractsColumTemplates" :data="contractsTableData" @clickOnRow="openContract" :emptyTableClass="'empty_table_with_margin'"></v-datatable>
        </div>
    </div>

    <div class="tab_body" v-if="activeTab.url == 'payment-info'">
        <div class="mb-1">
            <div class="paymeant_mean" v-if="currentClient?.activePaymentMean">
                <p class="paymeant_mean_data">{{$t('clients.view.card_number')}}: <span>{{currentClient?.activePaymentMean.cardNumberMasked}}</span></p>
                <p class="paymeant_mean_data">{{$t('clients.view.expiry_date')}}: <span>{{currentClient?.activePaymentMean.cardExpiryMonth.toString().padStart(2, '0')}}/{{currentClient?.activePaymentMean.cardExpiryYear.toString().padStart(4, '0')}}</span></p>
                <p class="paymeant_mean_data">{{$t('clients.view.card_holder')}}: <span>{{currentClient?.activePaymentMean.cardHolder}}</span></p>
                <p class="paymeant_mean_data mb-0">{{$t('clients.view.card_type')}}: <span>{{getCardType(currentClient?.activePaymentMean.cardType)}}</span></p>
            </div>
            <div class="paymeant_error" v-else>
                {{$t('clients.view.no_bank_card_linked')}}
            </div>
            <div class="payment_buttons_wrapper">
                <div class="payment_select_wrapper">
                    <button :class="isShowPayments ? 'payment_button_active' : 'payment_button'" @click="isShowPayments = true; isShowRefunds = false">{{$t('clients.view.payments')}}</button>
                    <button :class="isShowRefunds ? 'payment_button_active' : 'payment_button'" @click="isShowPayments = false; isShowRefunds = true">{{$t('clients.view.refunds')}}</button>
                </div>

                <div>
                    <button class="payment_change_button" v-if="hasChangePaymentPermission" @click="showChangePaymentMean">{{$t('clients.view.change_payment_method')}}</button>
                </div>
            </div>
        </div>

        <div v-show="isShowPayments">
            <div :class="isShowPaymentFilters ? 'show_filtes_mobile' : 'hide_filtes_mobile mt-2'">
                <div class="filter_wrapper pt-2">
                    <div class="filter_left_wrapper">
                        <v-select v-model="filterPaymentContract" :label="'clients.view.contract'" class="w-100" :items="availableContracts" @update:modelValue="applyPaymentFilter" clearable></v-select>
                        <v-select v-model="filterPaymentStatus" :label="'clients.view.status'" class="status_filter_input" :items="paymentStatus" @update:modelValue="applyPaymentFilter" clearable></v-select>
                    </div>

                    <div class="filter_right_wrapper">
                        <v-select class="small_filter_input mr-4" v-model="filterPaymentProcessingProvider" :label="'clients.view.payments_processing_provider'" :items="paymentProcessingProvider" @update:modelValue="applyPaymentFilter" clearable></v-select>
                        <v-input class="small_filter_input date_input" v-model="filterPaymentDate" :label="$t('clients.view.date')" inputType="date" height="22" @update:modelValue="applyPaymentFilter" clearable></v-input>
                    </div>

                </div>
                <div><p @click="clearPaymentAllFilters" class="clear_all_filters pl-2">{{$t('clients.clear_all_filters')}}</p></div>
            </div>

            <button class="filter_button mt-3" @click="isShowPaymentFilters = !isShowPaymentFilters">{{ !isShowPaymentFilters ? $t('clients.filter') : $t('clients.hide')}} <img v-show="!isShowPaymentFilters" src="/assets/img/filter.svg" /></button>

            <div class="mt-3 total_wrapper">
                <p class="total">{{$t('clients.view.total_orders')}}: {{paymentTableData.length}}</p>
            </div>

            <div :class="currentClient?.activePaymentMean ? 'mt-1 table_orders_wrapper': 'mt-1 table_orders_wrapper_without_active_payment_mean'">
                <v-datatable class="pointer" :columTemplates="paymentColumTemplates" :data="paymentTableData" @clickOnRow="openOrder"></v-datatable>
            </div>
        </div>

        <div v-show="isShowRefunds">
            <div :class="isShowRefundFilters ? 'show_filtes_mobile' : 'hide_filtes_mobile mt-2'">
                <div class="filter_wrapper pt-2">
                    <div class="filter_left_wrapper">
                        <v-select v-model="filterRefundContract" :label="'clients.view.contract'" class="w-100" :items="availableContracts" @update:modelValue="applyRefundFilter" clearable></v-select>
                    </div>

                    <div class="filter_right_wrapper">
                        <v-select v-model="filterRefundStatus" :label="'clients.view.status'" class="small_filter_input mr-4" :items="refundStatus" @update:modelValue="applyRefundFilter" clearable></v-select>
                        <v-input class="small_filter_input date_input" v-model="filterRefundDate" :label="$t('clients.view.date')" inputType="date" height="22" @update:modelValue="applyRefundFilter" clearable></v-input>
                    </div>

                </div>
                <div><p @click="clearRefundAllFilters" class="clear_all_filters pl-2">{{$t('clients.clear_all_filters')}}</p></div>
            </div>

            <button class="filter_button mt-3" @click="isShowRefundFilters = !isShowRefundFilters">{{ !isShowRefundFilters ? $t('clients.filter') : $t('clients.hide')}} <img v-show="!isShowRefundFilters" src="/assets/img/filter.svg" /></button>

            <div class="mt-3 total_wrapper">
                <p class="total">{{$t('clients.view.total_refunds')}}: {{refundTableData.length}}</p>
            </div>

            <div :class="currentClient?.activePaymentMean ? 'mt-1 table_orders_wrapper': 'mt-1 table_orders_wrapper_without_active_payment_mean'">
                <v-datatable class="pointer" :columTemplates="refundColumTemplates" :data="refundTableData" @clickOnRow="openRefundOrder"></v-datatable>
            </div>
        </div>

    </div>

    <div class="tab_body" v-if="activeTab.url == 'trainings'">

        <div :class="isShowPaymentFilters ? 'show_filtes_mobile' : 'hide_filtes_mobile mt-2'">
            <div class="filter_wrapper pt-2">
                <div class="filter_left_wrapper">
                    <v-select v-model="filterTraining" :label="'clients.view.training'" class="training_left_filter" :items="trainings" @update:modelValue="applyTrainingFilter" clearable></v-select>
                    <v-select v-model="filterTrainingTrainer" :label="'clients.view.trainer'" class="training_right_filter" :items="trainingTrainers" @update:modelValue="applyTrainingFilter" clearable></v-select>
                </div>

                <div class="filter_right_wrapper">
                    <v-select class="small_filter_input mr-4" v-model="filterTrainingClubZone" :label="'clients.view.club_zone'" :items="trainingClubZones" @update:modelValue="applyTrainingFilter" clearable></v-select>
                    <v-input class="small_filter_input date_input" v-model="filterTrainingStartDate" :label="$t('clients.view.start_date')" inputType="date" height="22" @update:modelValue="applyTrainingFilter" clearable></v-input>
                </div>

            </div>
            <div><p @click="clearTrainingAllFilters" class="clear_all_filters pl-2">{{$t('clients.clear_all_filters')}}</p></div>
        </div>

        <button class="filter_button mt-3" @click="isShowPaymentFilters = !isShowPaymentFilters">{{ !isShowPaymentFilters ? $t('clients.filter') : $t('clients.hide')}} <img v-show="!isShowPaymentFilters" src="/assets/img/filter.svg" /></button>

        <div class="mt-3 total_wrapper">
            <p class="total">{{$t('clients.view.total_trainings')}}: {{trainingTableData.length}}</p>
        </div>

        <div class="mt-1 table_trainers_wrapper">
            <v-datatable :columTemplates="trainingColumTemplates" :data="trainingTableData"></v-datatable>
        </div>
    </div>

    <div class="tab_body" v-if="activeTab.url == 'referral-program'">
        <div class="mt-3 total_wrapper">
            <p class="total" v-show="invitationsTableData.length > 0">{{$t('clients.view.total_invitations')}}: {{invitationsTableData.length}}</p>
        </div>

        <div class="mt-1">
            <v-datatable :columTemplates="invitationsColumTemplates" :data="invitationsTableData" :emptyTableClass="'empty_table_with_margin'"></v-datatable>
        </div>
    </div>


    <div class="tab_body" v-if="activeTab.url == 'events'">
        <div class="mt-3 total_wrapper">
            <p class="total" v-show="eventsColumTemplates.length > 0">{{$t('clients.view.total_events')}}: {{eventsTableData.length}}</p>
        </div>

        <div class="mt-1">
            <v-datatable :columTemplates="eventsColumTemplates" :data="eventsTableData" :emptyTableClass="'empty_table_with_margin'"></v-datatable>
        </div>
    </div>

    <div class="tab_body" v-if="activeTab.url == 'training-blocks'">
        <button class="new_mobile_button" v-if="hasAddClientBlockPermission" @click="">{{$t('clients.view.add_block')}}</button>
        <div class="mt-3 total_wrapper">
            <p class="total">{{$t('clients.view.total_blocks')}}: {{trainingBlocksTableData.length}}</p>

            <div>
                <a v-if="hasAddClientBlockPermission" class="new" @click="showAddClientTrainingBlock = true">{{$t('clients.view.add_block')}}</a>
            </div>

        </div>

        <div class="mt-1">
            <v-datatable class="pointer" :columTemplates="blockColumTemplates" :data="trainingBlocksTableData" :emptyTableClass="'empty_table_with_margin'" @clickOnButton="clickOnTableButton"></v-datatable>
        </div>


    </div>

    <div class="tab_body" v-if="activeTab.url == 'attendance'">
        <highcharts class="mt-3" v-if="monthlyAttendanceChartOptions" :options="monthlyAttendanceChartOptions"></highcharts>
        <highcharts v-if="yearAttendanceChartOptions" :options="yearAttendanceChartOptions"></highcharts>
    </div>

    

    <div class="tab_body" v-if="activeTab.url == 'privacy-and-notifications'">
        <div>
            <div class="privacy_wrapper">
                <div class="privacy">
                    <p class="privacy_label">{{ $t('clients.view.visible_to_others') }}</p>
                    <p class="mb-0 mt-2">{{ currentClient?.visibleToOthers ? $t('clients.yes') : $t('clients.no') }}</p>
                </div>
                <div class="privacy">
                    <p class="privacy_label">{{ $t('clients.view.show_contact_info_to_others') }}</p>
                    <p class="mb-0 mt-2">{{ currentClient?.showContactInfoToOthers ? $t('clients.yes') : $t('clients.no') }}</p>
                </div>

                <div class="privacy" v-for="privacy in currentClient?.consents">
                    <p class="privacy_label">{{ $t(privacy.type) }}</p>
                    <p class="mb-0 mt-2">{{ privacy.isEnabled ? $t('clients.yes') : $t('clients.no')}}</p>
                </div>
            </div>

        </div>
    </div>

    <div class="tab_body" v-if="activeTab.url == 'mobile-devices'">
        <div class="mt-3 total_wrapper">
            <p class="total" v-show="mobileDevicesTableData.length > 0">{{$t('clients.view.total_devices')}}: {{mobileDevicesTableData.length}}</p>
        </div>

        <div class="mt-1">
            <v-datatable :columTemplates="mobileDevicesColumTemplates" :data="mobileDevicesTableData" :emptyTableClass="'empty_table_with_margin'"></v-datatable>
        </div>
    </div>

</div>