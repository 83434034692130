<div class="add_restriction_wrapper">
    <div class="add_restriction_body" v-click-outside="close">
        <div class="add_restriction_title">{{$t('clients.view.add_restriction.title')}}</div>
        <div class="add_restriction" v-if="clubs?.length > 0">
            <div>
                <v-select v-model="selectedClub" :label="'clients.view.add_restriction.club'" class="w-100 mb-3" :items="clubs" :errorText="clubErrorText" @click="clubErrorText = ''"></v-select>
            </div>

            <v-textarea v-model="text" :label="$t('clients.view.add_restriction.comment')" :errorText="textErrorText" @focus="textErrorText = ''"></v-textarea>
        </div>
        <div class="add_restriction" v-if="clubs?.length == 0">
            <p class="add_restriction_text">{{$t('clients.view.add_restriction.no_active_contract')}}</p>
        </div>
        <div class="add_restriction_buttons_wrapper">
            <button class="primary_button button_space" @click="save" :disabled="isButtonDisabled || clubs.length == 0" v-if="clubs?.length > 0">{{$t('clients.view.add_restriction.set') }}</button>
            <button class="secondary_button" @click="$emit('close')">{{$t('clients.view.add_restriction.cancel')}}</button>
        </div>
    </div>
</div>

