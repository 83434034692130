<div class="last_viewed_client_wrapper" v-show="lastViewedClients.length > 0">
    <div class="last_viewed_client" :style="{ width: `calc(100% / ${lastViewedClients.length} + ${index == 0 ? 0 : 13}px)`, zIndex: lastViewedClients.length - index }" v-for="(lastViewedClient, index) in lastViewedClients">
        <p class="last_viewed_client_p"  @click="clickOnLastViewedClient(lastViewedClient.id)">{{lastViewedClient.fi}}</p>
        <div class="last_viewed_client_img_wrapper" @click="deleteLastViewedClient(index)"><img class="last_viewed_client_img" src="/assets/img/close.svg" /></div>
        <div :class="index > 7 ? 'last_viewed_client_hover last_viewed_client_hover_end' : 'last_viewed_client_hover'">{{lastViewedClient.fi}}</div>
    </div>
</div>


<div class="content_wrapper">
    <v-scrollable-menu v-model="activeTab" :items="tabs" @update:modelValue="clickOnTab"></v-scrollable-menu>


    <div :class="lastViewedClients.length > 0 ? 'mt-2 members_wrapper_with_last_viewed_clients' : 'mt-2 members_wrapper' ">
        <active v-if="isShowActiveChild"></active>
        <all v-if="isShowAllChild"></all>
        <inClub v-if="isShowInClubChild"></inClub>
        <new v-if="isShowNewChild"></new>
        <inDebt v-if="isShowInDebtChild"></inDebt>
        <endSoon v-if="isShowEndSoonChild"></endSoon>
        <former v-if="isShowFormerChild"></former>
        <corporate v-if="isShowCorporateChild"></corporate>
    </div>
</div>







