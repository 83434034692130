<div class="last_viewed_client_wrapper">
    <div :class="lastViewedClient.id == refundOrder?.clientId ? 'last_viewed_client last_viewed_client_active' : 'last_viewed_client'" :style="{ width: `calc(100% / ${lastViewedClients.length} + ${lastViewedClient.id ==  refundOrder?.clientId ? 0 : 13}px)`, zIndex: lastViewedClients.length - index }" v-for="(lastViewedClient, index) in lastViewedClients">
        <p class="last_viewed_client_p" @click="redirectToLastViewedClient(lastViewedClient.id)">{{lastViewedClient.fi}}</p>
        <div class="last_viewed_client_img_wrapper" @click="deleteLastViewedClient(index)"><img class="last_viewed_client_img" src="/assets/img/close.svg" /></div>
        <div v-if="lastViewedClient.id != refundOrder?.clientId" :class="index > 7 ? 'last_viewed_client_hover last_viewed_client_hover_end' : 'last_viewed_client_hover'">{{lastViewedClient.fi}}</div>
    </div>
</div>


<div class="content_wrapper refund_order_wrapper">
    <button @click="$router.push(`/client/${refundOrder?.clientId}/payment-info`)" class="button button_back" :disabled="!refundOrder?.clientId"><img class="back_button_img" src="/assets/img/arrow-left.svg"><span>{{$t('refund_order.payments')}}</span></button>
    <div class="refund_order_page_wrapper">
        <div class="refund_order_data">

            <div class="w-100 mb-1">
                <div class="refund_order_title">
                    <p class="refund_order" v-show="isLoaded">RORD #{{refundOrder?.refundOrderId?.toString().padStart(8, '0')}}</p>
                    <div :class="'refund_order_status status_text_' + getRefundOrderStatus(refundOrder?.processingStatus, refundOrder?.paidStatus)" v-show="isLoaded">{{$t('refund_order_status.' + getRefundOrderStatus(refundOrder?.processingStatus ?? 1, refundOrder?.paidStatus))}}</div>
                </div>

            </div>

            <div class="mt-3 refund_order_data_block">
                <p class="block_label">{{ $t('refund_order.amount') }}</p>
                <p class="block_info">{{ getFormatedAmount(refundOrder?.total) }} {{refundOrder?.currencySign }}</p>
            </div>

            <div class="mt-3 refund_order_data_block">
                <p class="block_label">{{ $t('refund_order.date') }}</p>
                <p class="block_info">{{ getFormatedDate(refundOrder?.dueDate, "DD.MM.yyyy") || '-' }}</p>
            </div>

            <div class="mt-3 refund_order_data_block">
                <p class="block_label">{{ $t('refund_order.description') }}</p>
                <p class="block_info">{{ refundOrder?.comment }}</p>
            </div>
        </div>


        <div class="refund_order_data mt-6">
            <div class="receipt_link_wrapper">
                <h3 class="my-0">{{$t('refund_order.refund_order_details')}}</h3>
                <a class="receipt_link" v-if="refundOrder?.receiptUrl" :href="refundOrder?.receiptUrl" target="_blank">{{$t('refund_order.open_receipt')}}</a>
            </div>

            <v-datatable class="mt-3" :columTemplates="refundOrderItemsColumTemplates" :data="refundOrderItemsTableData"></v-datatable>
        </div>
    </div>

</div>

