import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e8f6b256"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "alert_wrapper"
}
const _hoisted_2 = { class: "alert_body" }
const _hoisted_3 = {
  key: 0,
  class: "alert_text"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "alert_buttons_wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_ctx.modelValue)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
          _withDirectives(_createElementVNode("div", { class: "alert_title" }, _toDisplayString(_ctx.title ? _ctx.$t(_ctx.title) : _ctx.$t("empty_string")), 513), [
            [_vShow, _ctx.title]
          ]),
          (_ctx.text)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.isUntranslatableText ? _ctx.text : _ctx.$t(_ctx.text)), 1))
            : _createCommentVNode("", true),
          (_ctx.html)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "alert_text",
                innerHTML: _ctx.html
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              class: "primary_button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('success')))
            }, _toDisplayString(_ctx.successButtonText || 'ОК'), 1),
            (_ctx.cancelButtonText)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "secondary_button button_space",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancel')))
                }, _toDisplayString(_ctx.cancelButtonText), 1))
              : _createCommentVNode("", true)
          ])
        ])), [
          [_directive_click_outside, _ctx.close]
        ])
      ]))
    : _createCommentVNode("", true)
}