<div class="pass_card_wrapper">
    <div class="pass_card_body" v-click-outside="close" ref="bodyRef">
        <div class="pass_card_title">{{$t('pass_card.link_pass_card.linking_card')}}</div>
        <div class="pass_card" v-if="clubs?.length > 0">
            <p class="pass_card_text">{{$t('pass_card.link_pass_card.text')}}</p>
            <div>
                <v-select v-model="selectedClub" :label="'pass_card.link_pass_card.club'" class="w-100 mb-3" :items="clubs" :errorText="clubErrorText" @click="clubErrorText = ''"></v-select>
            </div>

        </div>
        <div class="pass_card" v-if="clubs?.length == 0 && clientId">
            <p class="pass_card_text">{{$t('pass_card.link_pass_card.no_active_contract')}}</p>
        </div> 
        <div class="pass_card" v-if="clubs?.length == 0 && userId">
            <p class="pass_card_text">{{$t('pass_card.link_pass_card.user_club_not_found')}}</p>
        </div>
        <div class="pass_card_buttons_wrapper">
            <button class="primary_button button_space" @click="link" :disabled="isButtonDisabled || clubs.length == 0" v-if="clubs?.length > 0">{{$t('pass_card.link_pass_card.link') }}</button>
            <button class="secondary_button " @click="$emit('close')">{{$t('pass_card.link_pass_card.cancel')}}</button>
        </div>
    </div>
</div>