import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-788b6dee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "add_client_training_block_wrapper" }
const _hoisted_2 = { class: "add_client_training_block_body" }
const _hoisted_3 = { class: "add_client_training_block_title" }
const _hoisted_4 = { class: "add_client_training_block" }
const _hoisted_5 = { class: "mt-0 mb-0" }
const _hoisted_6 = { class: "price_span" }
const _hoisted_7 = { class: "mt-3 mb-2" }
const _hoisted_8 = { class: "add_client_training_block_buttons_wrapper" }
const _hoisted_9 = ["disabled"]

export function render(_ctx, _cache) {
  const _component_v_alert = _resolveComponent("v-alert")
  const _component_v_select = _resolveComponent("v-select")
  const _component_v_radio = _resolveComponent("v-radio")
  const _directive_click_outside = _resolveDirective("click-outside")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_alert, {
      modelValue: _ctx.showAlert,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.showAlert) = $event)),
      title: 'clients.view.add_client_training_block.confirmation',
      text: _ctx.alertText,
      successButtonText: _ctx.$t('clients.view.add_client_training_block.confirm'),
      cancelButtonText: _ctx.$t('clients.view.cancel'),
      onSuccess: _ctx.save,
      onCancel: _cache[1] || (_cache[1] = $event => (_ctx.showAlert = false))
    }, null, 8, ["modelValue", "title", "text", "successButtonText", "cancelButtonText", "onSuccess"]),
    _createElementVNode("div", _hoisted_1, [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('clients.view.add_client_training_block.add_training_block')), 1),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_v_select, {
            class: "mt-4 mb-4",
            modelValue: _ctx.selectedClientTrainingBlockType,
            "onUpdate:modelValue": [
              _cache[2] || (_cache[2] = $event => ((_ctx.selectedClientTrainingBlockType) = $event)),
              _ctx.selectType
            ],
            label: 'clients.view.add_client_training_block.block_type',
            items: _ctx.clientTrainingBlockTypes
          }, null, 8, ["modelValue", "label", "items", "onUpdate:modelValue"]),
          _withDirectives(_createVNode(_component_v_select, {
            class: "mb-3",
            modelValue: _ctx.selectedContract,
            "onUpdate:modelValue": [
              _cache[3] || (_cache[3] = $event => ((_ctx.selectedContract) = $event)),
              _ctx.selectContract
            ],
            label: 'clients.view.add_client_training_block.contract',
            items: _ctx.contracts
          }, null, 8, ["modelValue", "label", "items", "onUpdate:modelValue"]), [
            [_vShow, _ctx.selectedClientTrainingBlockType.id == 0 && _ctx.contracts.length > 0]
          ]),
          _withDirectives(_createVNode(_component_v_select, {
            class: "mb-3",
            modelValue: _ctx.selectedClubTrainingBlock,
            "onUpdate:modelValue": [
              _cache[4] || (_cache[4] = $event => ((_ctx.selectedClubTrainingBlock) = $event)),
              _ctx.selectClubBlock
            ],
            label: 'clients.view.add_client_training_block.block_name',
            items: _ctx.clubTrainingBlocks
          }, null, 8, ["modelValue", "label", "items", "onUpdate:modelValue"]), [
            [_vShow, _ctx.selectedClientTrainingBlockType.id == 0]
          ]),
          _withDirectives(_createVNode(_component_v_select, {
            class: "mb-3",
            modelValue: _ctx.selectedTrainer,
            "onUpdate:modelValue": [
              _cache[5] || (_cache[5] = $event => ((_ctx.selectedTrainer) = $event)),
              _ctx.getTrainerBlocks
            ],
            label: 'clients.view.add_client_training_block.trainer',
            items: _ctx.trainers
          }, null, 8, ["modelValue", "label", "items", "onUpdate:modelValue"]), [
            [_vShow, _ctx.selectedClientTrainingBlockType.id == 1]
          ]),
          _withDirectives(_createVNode(_component_v_select, {
            class: "mb-3",
            modelValue: _ctx.selectedClub,
            "onUpdate:modelValue": [
              _cache[6] || (_cache[6] = $event => ((_ctx.selectedClub) = $event)),
              _ctx.getTrainerBlocks
            ],
            label: 'clients.view.add_client_training_block.club',
            items: _ctx.clubs
          }, null, 8, ["modelValue", "label", "items", "onUpdate:modelValue"]), [
            [_vShow, _ctx.selectedClientTrainingBlockType.id == 1 && _ctx.clubs.length > 0]
          ]),
          _withDirectives(_createVNode(_component_v_select, {
            class: "mt-4 mb-3",
            modelValue: _ctx.selectedTrainerBlock,
            "onUpdate:modelValue": [
              _cache[7] || (_cache[7] = $event => ((_ctx.selectedTrainerBlock) = $event)),
              _ctx.selectTrainerBlock
            ],
            label: 'clients.view.add_client_training_block.block_name',
            items: _ctx.trainerBlocks
          }, null, 8, ["modelValue", "label", "items", "onUpdate:modelValue"]), [
            [_vShow, _ctx.selectedClientTrainingBlockType.id == 1 && _ctx.trainers.length > 0]
          ]),
          _withDirectives(_createElementVNode("p", _hoisted_5, [
            _createTextVNode(_toDisplayString(_ctx.$t('contract.view.add_service.price')) + ": ", 1),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.price), 1)
          ], 512), [
            [_vShow, _ctx.price]
          ]),
          _createElementVNode("h4", _hoisted_7, _toDisplayString(_ctx.$t('add_entity_payment_method.payment_method')), 1),
          _createVNode(_component_v_radio, {
            class: "ml-2",
            modelValue: _ctx.selectedMethod,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.selectedMethod) = $event)),
            radioList: _ctx.availableMethod,
            mb: 2
          }, null, 8, ["modelValue", "radioList"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("button", {
            class: "primary_button",
            onClick: _cache[9] || (_cache[9] = (...args) => (_ctx.clickOnSaveButton && _ctx.clickOnSaveButton(...args))),
            disabled: _ctx.isButtonDisabled
          }, _toDisplayString(_ctx.$t('clients.view.add_client_training_block.add')), 9, _hoisted_9),
          _createElementVNode("button", {
            class: "secondary_button button_space",
            onClick: _cache[10] || (_cache[10] = $event => (_ctx.$emit('close')))
          }, _toDisplayString(_ctx.$t('clients.view.add_client_training_block.cancel')), 1)
        ])
      ])), [
        [_directive_click_outside, _ctx.clickOutside]
      ])
    ])
  ], 64))
}