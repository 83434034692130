import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5213777d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "write_message_wrapper" }
const _hoisted_2 = { class: "write_message_body" }
const _hoisted_3 = { class: "write_message_title" }
const _hoisted_4 = { class: "write_message" }
const _hoisted_5 = { class: "flex mt-3" }
const _hoisted_6 = { class: "mr-2" }
const _hoisted_7 = { class: "write_message_buttons_wrapper" }
const _hoisted_8 = ["disabled"]

export function render(_ctx, _cache) {
  const _component_v_input = _resolveComponent("v-input")
  const _component_v_textarea = _resolveComponent("v-textarea")
  const _component_v_radio = _resolveComponent("v-radio")
  const _directive_click_outside = _resolveDirective("click-outside")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('clients.view.write_message.send_message')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_v_input, {
          class: "mb-4 mt-5",
          modelValue: _ctx.subject,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.subject) = $event)),
          label: _ctx.$t('clients.view.write_message.subject'),
          height: "22",
          errorText: _ctx.subjectErrorText,
          onFocus: _cache[1] || (_cache[1] = $event => (_ctx.subjectErrorText = '')),
          clearable: ""
        }, null, 8, ["modelValue", "label", "errorText"]),
        _createVNode(_component_v_textarea, {
          modelValue: _ctx.message,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.message) = $event)),
          label: _ctx.$t('clients.view.write_message.message'),
          errorText: _ctx.messageErrorText,
          onFocus: _cache[3] || (_cache[3] = $event => (_ctx.messageErrorText = ''))
        }, null, 8, ["modelValue", "label", "errorText"]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('clients.view.write_message.send')) + ": ", 1),
          _createVNode(_component_v_radio, {
            modelValue: _ctx.selectedType,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.selectedType) = $event)),
            radioList: _ctx.availableTypes,
            mr: 3
          }, null, 8, ["modelValue", "radioList"])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("button", {
          class: "primary_button",
          onClick: _cache[5] || (_cache[5] = (...args) => (_ctx.save && _ctx.save(...args))),
          disabled: _ctx.isButtonDisabled
        }, _toDisplayString(_ctx.$t('clients.view.write_message.save')), 9, _hoisted_8),
        _createElementVNode("button", {
          class: "secondary_button button_space",
          onClick: _cache[6] || (_cache[6] = $event => (_ctx.$emit('close')))
        }, _toDisplayString(_ctx.$t('clients.view.write_message.cancel')), 1)
      ])
    ])), [
      [_directive_click_outside, _ctx.close]
    ])
  ]))
}