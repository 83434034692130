import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c1a641d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "create_training_wrapper" }
const _hoisted_2 = { class: "create_training_body" }
const _hoisted_3 = { class: "create_training_title" }
const _hoisted_4 = { class: "create_training" }
const _hoisted_5 = { class: "text_with_span" }
const _hoisted_6 = { class: "text_with_span mb-4" }
const _hoisted_7 = { class: "text_with_span" }
const _hoisted_8 = { class: "create_training_buttons_wrapper" }
const _hoisted_9 = ["disabled"]

export function render(_ctx, _cache) {
  const _component_v_select = _resolveComponent("v-select")
  const _component_v_input = _resolveComponent("v-input")
  const _directive_click_outside = _resolveDirective("click-outside")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('timetable.create_group_training.create_training')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, [
          _createTextVNode(_toDisplayString(_ctx.$t('timetable.create_group_training.club')) + ": ", 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.clubName), 1)
        ]),
        _createElementVNode("p", _hoisted_6, [
          _createTextVNode(_toDisplayString(_ctx.$t('timetable.create_group_training.club_zone')) + ": ", 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.clubZoneName), 1)
        ]),
        _createVNode(_component_v_select, {
          class: "mt-3 mb-4",
          modelValue: _ctx.selectedClass,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = $event => ((_ctx.selectedClass) = $event)),
            _ctx.selectClass
          ],
          label: 'timetable.create_group_training.training_class',
          items: _ctx.classes,
          errorText: _ctx.classErrorText,
          onClick: _cache[1] || (_cache[1] = $event => (_ctx.classErrorText = ''))
        }, null, 8, ["modelValue", "label", "items", "errorText", "onUpdate:modelValue"]),
        _createVNode(_component_v_select, {
          class: "mt-4 mb-4",
          modelValue: _ctx.selectedTrainer,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.selectedTrainer) = $event)),
          label: 'timetable.create_group_training.trainer',
          items: _ctx.groupTrainers,
          errorText: _ctx.trainerErrorText,
          onClick: _cache[3] || (_cache[3] = $event => (_ctx.trainerErrorText = ''))
        }, null, 8, ["modelValue", "label", "items", "errorText"]),
        _createVNode(_component_v_input, {
          class: "mt-4 mb-2",
          modelValue: _ctx.startDate,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.startDate) = $event)),
          inputType: "datetime-local",
          label: _ctx.$t('timetable.create_group_training.start_date'),
          height: "22",
          min: _ctx.minDate,
          errorText: _ctx.startDateErrorText,
          onFocus: _cache[5] || (_cache[5] = $event => (_ctx.startDateErrorText = '')),
          clearable: ""
        }, null, 8, ["modelValue", "label", "min", "errorText"]),
        _withDirectives(_createElementVNode("p", _hoisted_7, [
          _createTextVNode(_toDisplayString(_ctx.$t('timetable.create_group_training.capacity')) + ": ", 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.capacity), 1)
        ], 512), [
          [_vShow, _ctx.classes.length > 0]
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("button", {
          class: "primary_button",
          onClick: _cache[6] || (_cache[6] = (...args) => (_ctx.save && _ctx.save(...args))),
          disabled: _ctx.isButtonDisabled
        }, _toDisplayString(_ctx.$t('timetable.create_group_training.create')), 9, _hoisted_9),
        _createElementVNode("button", {
          class: "secondary_button button_space",
          onClick: _cache[7] || (_cache[7] = $event => (_ctx.$emit('close')))
        }, _toDisplayString(_ctx.$t('timetable.create_group_training.cancel')), 1)
      ])
    ])), [
      [_directive_click_outside, _ctx.close]
    ])
  ]))
}