<template src="./addClientTrainingBlock.html"></template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';
    import { axiosRequests } from '../../../../services/axiosinstance';
    import { editUrl, getFormatedAmount, checkIfClickInsideAlert } from '../../../../services/helper';
    import { Routes } from '../../../../const';
    import { RadioModel } from '../../../../models/GUIModels'; 
    import { SelectModel } from '../../../../models/GUIModels';
    import { Client } from '../../../../models/Clients';
    import { AvailableClubTrainingBlock, AvailableTrainerTrainingBlock, TrainerTrainingBlock } from '../../../../models/TrainingBlock'; 

    interface ComponentModel {
        clientTrainingBlockTypes: SelectModel[],
        selectedClientTrainingBlockType: SelectModel,
        isButtonDisabled: boolean,
        availableMethod: RadioModel[]
        selectedMethod: RadioModel | null,
        price: string,
        showAlert: boolean,
        alertText: string,
        clubTrainingBlocksData: AvailableClubTrainingBlock[],
        clubTrainingBlocks: SelectModel[],
        selectedClubTrainingBlock: SelectModel | null,
        contracts: SelectModel[],
        selectedContract: SelectModel | null,
        trainersData: AvailableTrainerTrainingBlock[],
        trainerTrainingBlocksData: TrainerTrainingBlock[],
        trainers: SelectModel[],
        selectedTrainer: SelectModel | null,
        clubs: SelectModel[],
        selectedClub: SelectModel | null,
        trainerBlocks: SelectModel[],
        selectedTrainerBlock: SelectModel | null,
    }

    export default defineComponent({
        name: 'AddClientTrainingBlock',
        props: {
            client: {
                type: Object as PropType<Client>,
                required: true
            }
        },
        emits: ["close"], 
        data(): ComponentModel {
            return {
                clientTrainingBlockTypes: [
                    { id: "0", text: 'clients.view.add_client_training_block.club_blocks', isTranslated: true, disabled: false, groupName: null },
                    { id: "1", text: 'clients.view.add_client_training_block.trainer_blocks',isTranslated: true, disabled: false, groupName: null },
                ],
                selectedClientTrainingBlockType: { id: "0", text: 'clients.view.add_client_training_block.club_blocks', isTranslated: true, disabled: false, groupName: null },
                clubTrainingBlocks: [],
                selectedClubTrainingBlock: null,
                trainers: [],
                selectedTrainer: null,
                trainerBlocks: [],
                selectedTrainerBlock: null,
                isButtonDisabled: false,
                availableMethod: [],
                selectedMethod: null,
                price: "",
                clubTrainingBlocksData: [],
                showAlert: false,
                alertText: "",
                trainersData: [],
                trainerTrainingBlocksData: [],
                contracts: [],
                selectedContract: null,
                clubs: [],
                selectedClub: null,
            }
        },
        methods: {
            getAvailableTrainingBlocks: async function () {
                await axiosRequests.get(editUrl(Routes.getAvailableClubTrainingBlocks, this.client.id))
                    .then(
                        (data: AvailableClubTrainingBlock[]) => {

                            this.clubTrainingBlocksData = data;

                            this.contracts = this.client.contracts.filter(x => x.status == 1 || x.status == 2).map(x => {
                                return {
                                    id: x.id.toString(),
                                    text: x.contract,
                                    disabled: false,
                                    isTranslated: false,
                                    groupName: null
                                }
                            });

                            if (this.contracts.length > 0) {
                                this.selectedContract = this.contracts[0];
                                this.selectContract();
                            }
                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                 this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                );

                await axiosRequests.get(editUrl(Routes.getAvailableTrainerTrainingBlocks, this.client.id))
                    .then(
                        (data: AvailableTrainerTrainingBlock[]) => {

                            this.trainersData = data;

                            this.trainers = data.map(x => {
                                return {
                                    id: x.trainerId.toString(),
                                    text: x.trainerName,
                                    disabled: false,
                                    isTranslated: false,
                                    groupName: null
                                }
                            });

                            if (this.trainers.length > 0) this.selectedTrainer = this.trainers[0];

                            let clubs: any = [...new Set(this.client.contracts.filter(x => x.status == 1 || x.status == 2).map(item => item.clubId.toString() ))];

                            for (let club of clubs) {
                                let contractClub = this.client.contracts.find(x => x.clubId.toString() == club);

                                if (!contractClub || contractClub.clubId == 0) continue;

                                this.clubs.push({
                                    id: contractClub.clubId.toString(),
                                    text: contractClub.clubName,
                                    disabled: false,
                                    isTranslated: false,
                                    groupName: null
                                });
                            }

                            if (this.clubs.length > 0) this.selectedClub = this.clubs[0];

                            if (this.selectedTrainer && this.selectedClub) this.getTrainerBlocks();
                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            clickOutside: function (event: any) {
                if (!checkIfClickInsideAlert(event.target)) this.close();
            },
            close: function () {
                this.$emit("close", false);
            },
            selectContract: function () {
                let agreementId = this.client.contracts.find(x => x.id.toString() == this.selectedContract?.id)?.agreementId

                this.clubTrainingBlocks = this.clubTrainingBlocksData.filter(x => x.agreementId == agreementId).map(x => {
                    return {
                        id: x.serviceId.toString(),
                        text: x.serviceName,
                        disabled: false,
                        isTranslated: false,
                        groupName: null
                    }
                });

                if (this.clubTrainingBlocks.length > 0) {
                    this.selectedClubTrainingBlock = this.clubTrainingBlocks[0];
                    this.selectClubBlock();
                }
            },
            selectClubBlock: function () {
                let agreementId = this.client.contracts.find(x => x.id.toString() == this.selectedContract?.id)?.agreementId
                const index = this.clubTrainingBlocksData.findIndex(x => x.serviceId.toString() == this.selectedClubTrainingBlock?.id && x.agreementId == agreementId);

                if (index != -1) {
                    this.price = `${getFormatedAmount(this.clubTrainingBlocksData[index].price)} ${this.clubTrainingBlocksData[index].currencySign}`;
                    this.isButtonDisabled = false;
                } else {
                    this.isButtonDisabled = true;
                    this.price = "";
                }
            },
            save: async function () {
                this.showAlert = false;

                try {
                    if (this.selectedClientTrainingBlockType.id == "0") {
                        if (!this.selectedClubTrainingBlock) return;

                        let agreementId = this.client.contracts.find(x => x.id.toString() == this.selectedContract?.id)?.agreementId

                        let data = { clientId: this.client.id, serviceId: this.selectedClubTrainingBlock.id, agreementId: agreementId, paymentMethod: this.selectedMethod?.value };

                        this.isButtonDisabled = true;

                        await axiosRequests.post(editUrl(Routes.buyClubTrainingBlock), data);
                    } else {
                        if (!this.selectedTrainerBlock) return;

                        let data = { clientId: this.client.id, serviceToTrainerId: this.selectedTrainerBlock.id, paymentMethod: this.selectedMethod?.value };

                        this.isButtonDisabled = true;

                        await axiosRequests.post(editUrl(Routes.buyTrainerTrainingBlock), data);
                    }

                    this.$emit("close", true);

                } catch (err: any) {
                    this.isButtonDisabled = false;

                    if (err.status == 401) {
                        return;
                    } else if (err.status == 403) {
                        this.$router.push('/forbidden');
                    } else if (err.status == 400 && err.data) {
                        this.$store.commit('SET_ADD_ERROR', err.data);
                    } else {
                        this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                    }
                }
            },
            selectType: function () {
                this.price = "";

                if (this.selectedClientTrainingBlockType.id == "0" && this.contracts.length > 0) {
                    this.selectedContract = this.contracts[0];
                    this.selectContract();

                    return;
                }

                if (this.selectedClientTrainingBlockType.id == "1" && this.trainers.length > 0 && this.clubs.length > 0) {
                    this.selectedTrainer = this.trainers[0];
                    this.selectedClub = this.clubs[0];
                    this.getTrainerBlocks();

                    return;
                }
            },
            getTrainerBlocks: function () {
                const index = this.trainersData.findIndex(x => x.trainerId.toString() == this.selectedTrainer?.id);

                this.trainerBlocks = [];
                this.trainerTrainingBlocksData = [];
                this.selectedTrainerBlock = null;
                this.price = "";

                if (index != -1) {
                    this.trainerTrainingBlocksData = this.trainersData[index].trainingBlocks.filter(x => x.clubId.toString() == this.selectedClub?.id );

                    this.trainerBlocks = this.trainerTrainingBlocksData.map(x => { return { id: x.serviceToTrainerId.toString(), text: `${x.name}_${x.trainingsCount} `, isTranslated: false, disabled: false, groupName: null } });

                    if (this.trainerBlocks.length > 0) {
                        this.selectedTrainerBlock = this.trainerBlocks[0];
                        this.selectTrainerBlock();
                    } else {
                        this.isButtonDisabled = true;
                    }
                }
            },
            selectTrainerBlock: function () {
                const index = this.trainerTrainingBlocksData.findIndex(x => x.serviceToTrainerId.toString() == this.selectedTrainerBlock?.id);

                if (index != -1) {
                    this.price = `${getFormatedAmount(this.trainerTrainingBlocksData[index].price)} ${this.trainerTrainingBlocksData[index].currencySign}`;
                    this.isButtonDisabled = false;
                } else {
                    this.isButtonDisabled = true;
                    this.price = "";
                }
            },
            clickOnSaveButton: function () {
                if (this.selectedMethod?.value == "pos") {
                    this.alertText = 'clients.view.add_client_training_block.pos_alert';
                    this.showAlert = true;
                } else if (this.selectedMethod?.value == "cash") {
                    this.alertText = 'clients.view.add_client_training_block.cash_alert';
                    this.showAlert = true;
                } else {
                    this.save();
                }
            },
            checkPressedButton: function (event: any) {
                if (event.keyCode == 27) this.close();
            }
        },
        async beforeMount() {
            this.availableMethod.push({ label: 'add_entity_payment_method.pos_terminal', isTranslated: true, value: "pos" });
            this.availableMethod.push({ label: 'add_entity_payment_method.cash', isTranslated: true, value: "cash" });

            if (this.client.activePaymentMean) this.availableMethod.push({ label: 'add_entity_payment_method.linked_bank_card', isTranslated: true, value: "linkedCard" });

            this.selectedMethod = { label: 'add_entity_payment_method.pos_terminal', isTranslated: true, value: "pos" };

            await this.getAvailableTrainingBlocks();

            if (this.clubTrainingBlocks.length > 0) {
                this.selectedClubTrainingBlock = this.clubTrainingBlocks[0];
                this.selectClubBlock();
            } else {
                this.isButtonDisabled = true;
            }
        },
        mounted() {
            window.addEventListener('keyup', this.checkPressedButton);
        },
        beforeUnmount() {
            window.removeEventListener('keyup', this.checkPressedButton);
        }
    });
</script>

<style scoped>
    .add_client_training_block_wrapper {
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: #b5b4b466;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .add_client_training_block_body {
        background-color: white;
        max-width: 480px;
        width: max-content;
        border-radius: 24px;
        animation: show 0.6s linear;
    }

    .add_client_training_block_title {
        font-size: 17px;
        letter-spacing: 1px;
        font-weight: 600;
        background-color: #f6f7f8;
        padding: 16px 24px;
        border-radius: 24px 24px 0px 0px;
    }

    .add_client_training_block {
        margin-top: 12px;
        padding: 0px 24px;
    }

    .add_client_training_block_buttons_wrapper {
        margin-top: 12px;
        display: flex;
        padding: 0px 24px;
        margin-bottom: 8px;
    }

    .button_space {
        margin-left: 16px;
    }

    .price_span {
        color: #65656F;
    }

    @media(max-width: 500px) {
        .add_client_training_block_body {
            width: 98%;
        }

        .add_client_training_block_wrapper {
            display: block;
            margin-bottom: 16px;
        }

        .button_space {
            margin-left: 0px;
        }

        .add_client_training_block_buttons_wrapper {
            display: block;
        }
    }
</style>