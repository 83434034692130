<template src="./viewChats.html"></template>

<script lang="ts">
    import { defineComponent, nextTick } from 'vue';
    import { axiosRequests } from '../../services/axiosinstance';
    import { editUrl, getFormatedDate, convertToISOString } from '../../services/helper';
    import { Routes } from '../../const';
    import { User } from '../../models/User';
    import { Client } from '../../models/Clients';
    import { Club } from '../../models/Club';

    interface RoomModel {
        roomId: number,
        clientId: number,
        lastName: string,
        firstName: string,
        clientPhoto: string,
        unreadMessageCount: number,
        lastMessage: string,
        isOwner: boolean,
        clubId: number,
        isHide: boolean
    }

    interface MessageModel {
        clientId: number,
        sendDate: string,
        text: string,
        messageId: number,
        isRead: boolean,
        isTechnicalMessage: boolean,
        isClubMessage: boolean,
        isTranslated: boolean
    }

    interface ComponentModel {
        hubConnection: any,
        rooms: RoomModel[],
        selectedRoom: RoomModel | null,
        availableClubs: Club[],
        search: string,
        searchTimeout: any,
        messagesTimeout: any,
        roomsTimeout: any,
        isShowChatBody: boolean,
        currentUser: User | null,
        message: string,
        isTokenRefreshed: boolean,
        connectionInformation: string,
        messages: MessageModel[],
        messageInformation: string,
        isTextareaFocused: boolean,
        textareaHeight: number,
        textarea: any
    }

    export default defineComponent({
        name: 'ViewChats',
        data(): ComponentModel {
            return {
                hubConnection: null,
                rooms: [],
                selectedRoom: null,
                availableClubs: [],
                search: "",
                searchTimeout: null,
                messagesTimeout: null,
                roomsTimeout: null,
                isShowChatBody: false,
                currentUser: null,
                message: "",
                isTokenRefreshed: false,
                connectionInformation: "",
                messages: [],
                messageInformation: "",
                isTextareaFocused: false,
                textareaHeight: 44,
                textarea: null
            }
        },
        methods: {
            getRooms: async function () {
                if (this.roomsTimeout) {
                    clearTimeout(this.roomsTimeout);
                }

                this.roomsTimeout = setTimeout(async () => {
                    try {
                        if (this.availableClubs.length == 0) return;

                        let clubs = this.$store.getters.getSelectedClubId;
                        if (clubs == "0") clubs = this.availableClubs.map(x => x.id).toString();

                        let roomReult = await axiosRequests.get(`${process.env.VUE_APP_CHAT_URL}Chat/GetAdministrationRooms?clubs=${clubs}`);
                        
                        if(roomReult.length == 0){
                            this.rooms = [];
                            return;
                        }
                        
                        if (this.rooms.length == 0) {
                            let clientsReult: Client[] = await axiosRequests.post(editUrl(Routes.getClientsByIds), { ids: roomReult.map((x: any) => x.ClientId.toString()) });

                            for (let i = 0; i < roomReult.length; i++) {
                                let index = clientsReult.findIndex(x => x.id == roomReult[i].ClientId);

                                if (index == -1) continue;

                                this.rooms.push({
                                    roomId: roomReult[i].RoomId,
                                    clientId: roomReult[i].ClientId,
                                    lastName: clientsReult[index].lastName,
                                    firstName: clientsReult[index].firstName,
                                    clientPhoto: clientsReult[index].photoUrl,
                                    unreadMessageCount: roomReult[i].UnreadMessageCount,
                                    lastMessage: roomReult[i].LastMessage,
                                    isOwner: roomReult[i].OwnerId == this.currentUser?.id,
                                    clubId: roomReult[i].ClubId,
                                    isHide: false
                                });
                            }
                        } else {
                             for (let i = 0; i < roomReult.length; i++) {
                                 let index = this.rooms.findIndex(x => x.roomId == roomReult[i].RoomId);

                                 if (index == -1) {
                                      let clientsReult: Client[] = await axiosRequests.post(editUrl(Routes.getClientsByIds), { ids: [roomReult[i].ClientId]});

                                      if(clientsReult.length == 0) continue;

                                      let isHide = false;
                                      if (this.search && this.search.length > 0 && !(roomReult[i].lastName.toLowerCase().includes(this.search.toLowerCase()) || roomReult[i].firstName.toLowerCase().includes(this.search.toLowerCase()))) {
                                              isHide = true;
                                      }

                                       this.rooms.push({
                                             roomId: roomReult[i].RoomId,
                                             clientId: roomReult[i].ClientId,
                                             lastName: clientsReult[0].lastName,
                                             firstName: clientsReult[0].firstName,
                                             clientPhoto: clientsReult[0].photoUrl,
                                             unreadMessageCount: roomReult[i].UnreadMessageCount,
                                             lastMessage: roomReult[i].LastMessage,
                                             isOwner: roomReult[i].OwnerId == this.currentUser?.id,
                                             clubId: roomReult[i].ClubId,
                                             isHide: isHide
                                       });
                                 } else {
                                     this.rooms[index].unreadMessageCount = roomReult[i].UnreadMessageCount;
                                     this.rooms[index].lastMessage = roomReult[i].LastMessage;
                                     this.rooms[index].isOwner = roomReult[i].OwnerId == this.currentUser?.id;

                                     if (roomReult[i].UnreadMessageCount > 0) {
                                         this.rooms.unshift(...this.rooms.splice(index, 1));
                                     }
                                 }
                             }
                        } 

                        if (this.$route.params.id && !this.selectedRoom?.roomId) {
                            const index = this.rooms.findIndex(x => x.roomId.toString() == this.$route.params.id.toString());

                            if (index == -1) return;

                            this.selectRoom(this.rooms[index], false);

                        } 
                    }
                    catch (err: any) {
                        if (err.status == 401) {
                            return;
                        } else if (err.status == 500) {
                            this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                        } else if (err.status == 403) {
                            this.$router.push('/forbidden');
                        } else if (err.status == 400 && err.data) {
                            this.$store.commit('SET_ADD_ERROR', err.data);
                        } else {
                            this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                        }
                    }
                }, 1000)
            },
            getAvailableClubs: async function () {
                await axiosRequests.get(editUrl(Routes.getClubs))
                    .then(
                        (data: Club[]) => {
                            this.availableClubs = data;
                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            selectRoom: function (room: any, isNeedToUpdateRoute: boolean = true) {
                if (isNeedToUpdateRoute) this.$router.push(`/chats/${room.roomId}`);

                this.isShowChatBody = true;

                if (this.selectedRoom?.roomId == room.roomId) return;

                this.selectedRoom = room;

                this.messages = [];

                this.message = "";

                nextTick(() => this.changeTextArea());

                this.messageInformation = 'chat.load_message';

                if (this.messagesTimeout) clearTimeout(this.messagesTimeout);

                this.messagesTimeout = setTimeout(() => this.getMessages(), 1000);
            },
            searchChat: function () {

                if (this.searchTimeout) {
                    clearTimeout(this.searchTimeout);
                }

                this.searchTimeout = setTimeout(() => {
                    if (!this.search || (this.search.length > 0 && this.search.length < 3)) {
                        for (let room of this.rooms) room.isHide = false;

                        return;
                    }

                    for (let room of this.rooms) {
                        if (room.lastName.toLowerCase().includes(this.search.toLowerCase()) || room.firstName.toLowerCase().includes(this.search.toLowerCase())) room.isHide = false;
                        else room.isHide = true;
                    }
                }, 600)
            },
            redirectToClient: function () {
                if (!this.selectedRoom?.clientId) return;

                this.$store.commit('SET_LAST_VIEWED_CLIENT', { fi: "", id: this.selectedRoom?.clientId, buttonText: 'back_button.chat', buttonPath: `/chats/${this.selectedRoom?.roomId}`, isNeedTranslatedButtonText: true });

                this.$router.push('/client/' + this.selectedRoom?.clientId);
            },
            getMessages: function () {
                this.messages = [];
                axiosRequests.get(`${process.env.VUE_APP_CHAT_URL}Chat/GetAdministrationMessages?roomId=${this.selectedRoom?.roomId}&clubId=${this.selectedRoom?.clubId}`)
                    .then(
                        (data: any[]) => {
                            this.messageInformation = "";

                            let unreadMessageIds: string[] = [];
                            let technicalMessageDate: Date | null = null;
                            let todayDate: Date = new Date();

                            for (let i = 0; i < data.length; i++) {
                                let messageDate = new Date(Date.UTC(data[i].DateCreateUTC.year, data[i].DateCreateUTC.month - 1, data[i].DateCreateUTC.day, data[i].DateCreateUTC.hour, data[i].DateCreateUTC.minute, 0, 0));

                                if (technicalMessageDate == null || technicalMessageDate.toDateString() != messageDate.toDateString()) {
                                    this.messages.push({
                                        clientId: 0,
                                        sendDate: todayDate.toDateString(),
                                        text: todayDate.toDateString() != messageDate.toDateString() ? messageDate.toLocaleDateString() :'chat.today',
                                        messageId: 0,
                                        isTranslated: todayDate.toDateString() == messageDate.toDateString(),
                                        isRead: true,
                                        isTechnicalMessage: true,
                                        isClubMessage: false
                                    });

                                    technicalMessageDate = messageDate;
                                }

                                this.messages.push({
                                    clientId: data[i].ClientId,
                                    sendDate: getFormatedDate(convertToISOString(messageDate), "HH:mm"),
                                    text: data[i].Message.toString().replaceAll('\n', '<br>'),
                                    messageId: data[i].MessageId,
                                    isTranslated: false,
                                    isRead: data[i].Read,
                                    isTechnicalMessage: data[i].TechnicalMessage,
                                    isClubMessage: data[i].ClientId == 0 || data[i].ClientId == null
                                });

                                if (!data[i].Read) unreadMessageIds.push(data[i].MessageId);
                            }

                            nextTick(() => this.scroll());

                            if (this.selectedRoom?.isOwner && unreadMessageIds.length > 0) {
                                this.hubConnection.invoke("MarkAsReadAdministrator", this.selectedRoom?.roomId.toString(), unreadMessageIds);
                            }
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.messageInformation = "";
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            sendMessage: function () {
                if (!this.message?.trim() || !this.selectedRoom || !this.selectedRoom.isOwner) return;
                this.hubConnection.invoke("SendAdministration", this.message, this.selectedRoom?.roomId.toString());
                this.message = "";
                nextTick(() => this.changeTextArea());
            },
            connectToChat: function () {
                this.hubConnection = this.$store.getters.getHubConnection;

                if (!this.hubConnection) {
                    this.connectionInformation = 'chat.conneting';
                    return;
                }

                this.connectionInformation = "";

                this.hubConnection.on("Update", this.getRooms);
                this.hubConnection.on("Receive", (message: any, clientInfo: any, messageRoomId: number) => this.receiveMessage(message, clientInfo, messageRoomId));
            },
            receiveMessage: function (message: any, clientInfo: any, messageRoomId: number) {
                if (messageRoomId == this.selectedRoom?.roomId) {

                    let todayDate: Date = new Date();

                    for (let i = this.messages.length - 1; i >= 0; i--) {
                        if (!this.messages[i].isTechnicalMessage) continue;

                        if (this.messages[i].sendDate == todayDate.toDateString()) break;

                        this.messages.push({
                            clientId: 0,
                            sendDate: todayDate.toDateString(),
                            text: 'chat.today',
                            messageId: 0,
                            isTranslated: true,
                            isRead: true,
                            isTechnicalMessage: true,
                            isClubMessage: false
                        });
                    }

                    this.messages.push({
                        clientId: clientInfo.clientId,
                        sendDate: getFormatedDate(convertToISOString(new Date()), "HH:mm"),
                        text: message.message.toString().replaceAll('\n', '<br>'),
                        messageId: message.messageId,
                        isTranslated: false,
                        isRead: true,
                        isTechnicalMessage: false,
                        isClubMessage: clientInfo.clientId == 0 || clientInfo.clientId == null
                    });

                    nextTick(() => this.scroll());

                    this.hubConnection.invoke("MarkAsReadAdministrator", this.selectedRoom?.roomId.toString(), [message.messageId]);
                } else {
                    this.getRooms();
                }
            },
            assignAsOwner: async function () {
                await axiosRequests.post(`${process.env.VUE_APP_CHAT_URL}Chat/AssignAsOwner`, { roomId: this.selectedRoom?.roomId, isAdmin: true})
                    .then(
                        () => {
                            if (this.selectedRoom != null) this.selectedRoom.isOwner = true;
                            this.hubConnection.invoke("SendUpdate", this.selectedRoom?.roomId.toString());

                            this.hubConnection.invoke("MarkAsReadAdministrator", this.selectedRoom?.roomId.toString(), this.messages.filter(x => x.isRead == false).map(x => x.messageId));
                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 500) {
                                this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                            } else if (err.status == 403) {
                                 this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            removeOwner: async function () {
                await axiosRequests.post(`${process.env.VUE_APP_CHAT_URL}Chat/RemoveOwner`, { roomId: this.selectedRoom?.roomId })
                    .then(
                        () => {
                            if (this.selectedRoom != null) this.selectedRoom.isOwner = false;
                            this.hubConnection.invoke("SendUpdate", this.selectedRoom?.roomId.toString());
                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 500) {
                                this.$store.commit('SET_ISSHOWINTERNALSERVERERROR', true);
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            scroll: function () {
                let element = document.getElementById("messages");
                if (!element) return;

                element.scrollTo({
                    top: element.getBoundingClientRect().bottom + 10000000,
                    left: 0,
                    behavior: "smooth",
                });
            },
            updateTextarea: function (e: any) {
                if (e.keyCode == 13 && !e.shiftKey && !e.ctrlKey) {
                    this.sendMessage();
                    e.preventDefault();
                    return;
                }
            },
            changeTextArea: function () {
                if (!this.textarea) this.textarea = document.getElementById("messageTextarea");

                this.textarea.style.height = '44px';
                if (this.textarea.scrollHeight < 44) {
                    this.textareaHeight = 44;
                    this.textarea.style.height = '44px';
                    return;
                }

                if (this.textarea.scrollHeight <= 145) {
                    this.textareaHeight = (this.textarea.scrollHeight);
                } else {
                    this.textareaHeight = 145;
                }

                this.textarea.style.height = `${this.textarea.scrollHeight}px`;
            }
        },
         watch: {
             'isChatReconnecting': function () {
                 if (this.isChatReconnecting){
                     this.connectionInformation = 'chat.conneting';
                     this.textareaHeight = 44; 
                    return;
                 }

                 if (!this.isChatReconnecting && this.isChatConnected) {
                     this.connectToChat();

                     nextTick(() => this.changeTextArea());
                     
                     return;
                 }
             },
             'isChatConnected': function () {
                 if (!this.isChatReconnecting && !this.isChatConnected) {
                     this.connectionInformation = 'chat.conneting_error';
                     this.textareaHeight = 44; 
                     return;
                 }

                 if (!this.isChatReconnecting && this.isChatConnected) {
                     this.connectToChat();

                     nextTick(() => this.changeTextArea());

                     return;
                 }
            },
         },
         computed: {
             isChatReconnecting: function () {
                 return this.$store.getters.getIsChatReconnecting;
             },
             isChatConnected: function () {
                 return this.$store.getters.getIsChatConnected;
             },
             
         },
        async beforeMount() {
            this.currentUser = this.$store.getters.getCurrentUser;

            await this.getAvailableClubs();
            await this.connectToChat();

            await this.getRooms();
        },
        beforeUnmount() {
            this.hubConnection?.off("Update");
            this.hubConnection?.off("Receive");
        }
    });
</script>

<style scoped>
    .chat_page_wrapper {
        height: calc(100vh - 140px);
        overflow: hidden;
        display: flex;
    }

    .left_side {
        width: 400px;
        display: flex;
        flex-flow: column;
    }

    .search_input {
        margin-bottom: 8px;
    }

    .chats_wrapper {
        width: 100%;
        display: flex;
        flex-flow: column;
        overflow: auto;
        height: calc(100% - 44px);
    }

    .chat_wrapper {
        width: calc(100% - 20px);
        display: flex;
        cursor: pointer;
        border-radius: 12px;
        padding: 8px;
        flex-flow: wrap;
        margin-right: 4px;
    }

    .chat_wrapper_active {
        background-color: #c7e9f3;
    }

    .client_photo {
        background-size: cover;
        width: 36px;
        height: 36px;
        border: 1px solid white;
        border-radius: 50%;
        background-position: center center;
        margin-right: 12px;
    }

    .chat_data_wrapper {
        border-bottom: 1.5px solid #d5d5d5;
        padding-bottom: 5px;
        display: flex;
        flex-flow: wrap;
        width: calc(100% - 50px);
    }

    .chat_data_wrapper_active {
        border-bottom: none;
        padding-bottom: 2px;
    }

    .chat_info_wrapper {
        width: 100%;
    }

    .chat_info_wrapper_with_uread_messages {
        width: calc(100% - 49px);
    }

    .chat_info_name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .chat_info_message {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #65656F;
        font-size: 12px;
        line-height: 18px;
    }

    .unread_messages_count {
        background-color: #c7e9f3;
        border-radius: 12px;
        padding: 3px 10px 4px 10px;
        margin-left: 12px;
        font-size: 13px;
        color: #007ca3;
        height: 14px;
        display: flex;
        margin-top: 6px;
    }

    .unread_messages_count p{
        width: 17px;
        text-align: center;
    }

    .right_side {
        width: 100%;
        margin-left: 12px;
        overflow: hidden;
    }

    .chat_body_wrapper {
        border-radius: 12px;
        border: 1.5px solid #d5d5d5;
        overflow: hidden;
        height: calc(100% - 3px);
    }

    .chat_body_top_wrapper {
        height: 60px;
        background-color: #f6f7f8;
    }

    .chat_body {
        height: calc(100%);
        overflow: auto;
        position: relative;
        padding: 0px 12px;
        overflow-x: hidden;
    }

    .button_back{
        display: none;
    }

    .chat_body_top{
        display: flex;
    }

    .button_exit {
        width: 190px;
        height: 36px;
        padding: 8px 12px;
        border-radius: 12px;
        background-color: #fbe0e0;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        font-size: 16px;
        margin-top: 10px;
    }

    .button_exit_img {
        margin-right: 0px;
        margin-top: 1px;
        margin-left: 5px;
        height: 16px;
    }

    .button_owner {
        width: 190px;
        height: 36px;
        padding: 8px 12px;
        border-radius: 12px;
        background-color: #21a7d1;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        font-size: 16px;
        margin-top: 10px;
        color: white;
    }

    .button_owner_img {
        margin-right: 0px;
        margin-top: 1px;
        margin-left: 5px;
        height: 16px;
    }

    .client_photo_body_top {
        background-size: cover;
        margin-top: 9px;
        margin-left: 12px;
        width: 41px;
        height: 41px;
        border: 1px solid white;
        border-radius: 50%;
        background-position: center center;
        margin-right: 12px;
    }

    .client_name_body_top {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        font-weight: bold;
        margin-top: 18px;
        font-size: 18px;
        text-decoration: underline;
        margin-right: 12px;
        width: calc(100% - 285px);
    }

    .button_exit_mobile{
        display: none;
    }

    .button_owner_mobile {
        display: none;
    }

    .right_side_buttons {
        display: flex;
        justify-content: space-between;
    }


    .message_send_button {
        padding: 8px 12px;
        border-radius: 12px;
        background-color: #21a7d1;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        height: 46px;
        width: 46px;
        margin-top: 18px;
    }

    .message_send_button_img {
        margin-right: 0px;
        margin-top: 4px;
        margin-left: 0px;
        height: 22px;
    }

    .info_text {
        font-weight: bold;
        margin-top: 0px;
        margin-bottom: 0px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        text-align: center;
    }

    .technical_message {
        border-radius: 16px;
        background-color: #f6f7f8;
        color: #65656F;
        display: flex;
        width: fit-content;
        padding: 8px 16px;
        margin: 18px auto;
    }

    .club_message {
        border-radius: 16px;
        background-color: #f6f7f8;
        color: black;
        display: flex;
        padding: 20px 16px;
        margin: 6px 12px;
        position: relative;
        width: fit-content;
        min-width: 150px;
        margin-inline: auto 0px;
    }

    .client_message {
        border-radius: 16px;
        background-color: #c7e9f3;
        color: black;
        display: flex;
        padding: 20px 16px;
        margin: 6px 0px;
        position: relative;
        width: fit-content;
        min-width: 150px;
    }

    .message_date {
        margin: 0;
        position: absolute;
        font-size: 12px;
        bottom: 5px;
        right: 16px;
    }

    .chat_content_wrapper {
        height: calc(100% - 60px);
        overflow: hidden;
        display: flex;
        flex-flow: column;
        width: 100%;
    }

    .chat_body_footer_wrapper {
        background-color: #f6f7f8;
        position: relative;
        height: fit-content;
        display: flex;
        width: 100%;
    }

    .chat_body_footer {
        display: flex;
        width: 100%;
        height: fit-content;
    }

    .textarea_wrapper {
        overflow: hidden;
        border-radius: 12px;
        border: 1.5px solid #D5D5D5;
        margin-left: 12px;
        margin-right: 12px;
        margin-top: 18px;
        margin-bottom: 18px;
        width: calc(100% - 85px) !important;
        display: flex;
    }

    .active {
        border: 1.5px solid #21A7D1 !important;
    }

    .textarea {
        border: unset;
        padding: 7px 12px;
        font-size: 16px;
        width:100%;
        height: 44px;
        resize: none;
        box-sizing: border-box;
        max-height: 145px;
    }

    .textarea:focus-visible {
        outline: unset;
        border: unset;
    }


    @media(max-width: 1200px) {
        .left_side {
            width: 320px;
        }
    }

    @media(max-width: 1000px) {
        .button_exit {
            width: 45px;
        }

        .button_text {
            display: none;
        }

        .button_exit_img {
            margin-right: 7px;
            margin-top: 1px;
            margin-left: 10px;
            height: 16px;
        }

        .button_owner {
            width: 45px;
        }

        .button_owner_img {
            margin-right: 7px;
            margin-top: 1px;
            margin-left: 10px;
            height: 16px;
        }

        .client_name_body_top {
            width: calc(100% - 137px);
        }
    }

    @media(max-width: 768px) {
        .chat_page_wrapper {
            height: calc(100vh - 130px);
        }

        .button_text{
            display: none;
        }
    }

    @media(max-width: 700px) {
        .left_side {
            width: 100%;
        }

        .right_side {
            display: none;
            margin-left: 0px;
        }

        .mobile_hide{
            display: none;
        }

        .mobile_show {
            display: block;
        }

        .button_back {
            width: 35px;
            margin-bottom: 12px;
            padding: 8px 12px;
            border-radius: 12px;
            background-color: #eaebec;
            border: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
        }

        .back_button_img {
            margin-right: 7px;
            margin-top: 1px;
            margin-left: 5px
        }

        .chat_body_wrapper {
            height: calc(100% - 44px);
        }

        .button_exit_mobile {
            height: 29px;
            padding: 6px 12px;
            border-radius: 12px;
            background-color: #fbe0e0;
            border: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            font-size: 16px;
            width: 180px;
        }

        .client_name_body_top {
            width: calc(100% - 80px);
        }

        .button_exit{
            display: none;
        }

        .button_owner {
            display: none;
        }

        .button_owner_mobile {
            height: 29px;
            padding: 6px 12px;
            border-radius: 12px;
            background-color: #21a7d1;
            border: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            font-size: 16px;
            width: 210px;
            color: white;
        }
    }

    @media(max-width: 500px) {
        .technical_message {
            font-size: 12px;
        }

        .club_message {
            padding: 16px 16px;
            font-size: 14px;
        }

        .client_message {
            padding: 16px 16px;
            font-size: 14px;
        }
    }
</style>