<template src="./createPesonalTraining.html"></template>

<script lang="ts">
    import { defineComponent, nextTick } from 'vue';
    import { axiosRequests } from '../../../services/axiosinstance';
    import { editUrl, getFormatedDate, checkIfClickInsideAlert, retryGetPermissions, getFormatedAmount, convertToISOString } from '../../../services/helper';
    import { Routes } from '../../../const';
    import { SelectModel, RadioModel } from '../../../models/GUIModels';
    import FindClients from '../FindClients/FindClients.vue';

    interface ComponentModel {
        startDate: string | null,
        isButtonDisabled: boolean,
        startDateErrorText: string,
        servicesToTrainerErrorText: string,
        personalTrainers: SelectModel[],
        servicesToTrainer: SelectModel[],
        selectedPersonalTrainer: SelectModel | null,
        selectedServicesToTrainer: SelectModel | null,
        search: string,
        activeClient: any,
        isShowAvailableMethod: boolean,
        availableMethod: RadioModel[],
        selectedMethod: RadioModel | null,
        isShowAlert: boolean,
        alertText: string,
        clubName: string | null,
        duration: number,
        clubId: string,
        trainerId: string,
        minDate: string
    }

    export default defineComponent({
        name: 'CreatePesonalTraining',
        props: ["clubWithZones", "trainingDate", "selectedPersonalTrainerId"],
        emits: ["close"],
        data(): ComponentModel {
            return {
                isButtonDisabled: false,
                startDate: null,
                startDateErrorText: "",
                servicesToTrainerErrorText: "",
                personalTrainers: [],
                servicesToTrainer: [],
                selectedServicesToTrainer: null,
                selectedPersonalTrainer: null,
                search: "",
                activeClient: null,
                isShowAvailableMethod: false,
                availableMethod: [],
                selectedMethod: null,
                isShowAlert: false,
                alertText: "",
                clubName: "",
                duration: 0,
                clubId: "0",
                trainerId: "0",
                minDate: getFormatedDate(convertToISOString(new Date()), 'yyyy-MM-DDTHH:MM')
            }
        },
        methods: {
            clickOutside: function (event: any) {
                if (!checkIfClickInsideAlert(event.target)) this.close();
            },
            close: function () {
                this.$emit("close", false);
            },
            selectTrainer: function () {
                if (!this.selectedPersonalTrainer) return;

                let trainerIdWithClubId = this.selectedPersonalTrainer.id.split("|");

                if (trainerIdWithClubId.length != 2) return;

                this.trainerId = trainerIdWithClubId[0];
                this.clubId = trainerIdWithClubId[1];

                this.clubName = this.selectedPersonalTrainer.groupName;

                let index = this.clubWithZones.findIndex(x => x.name == this.selectedPersonalTrainer?.groupName);

                if (index == -1) {
                    this.servicesToTrainer = [];
                    this.selectedServicesToTrainer = null;

                    return;
                }

                let trainerIndex = this.clubWithZones[index].trainers.findIndex(x => `${x.trainerId.toString()}|${this.clubWithZones[index].clubId}` == this.selectedPersonalTrainer?.id);

                if (trainerIndex == -1) {
                    this.servicesToTrainer = [];
                    this.selectedServicesToTrainer = null;

                    return;
                }

                this.servicesToTrainer = this.clubWithZones[index].trainers[trainerIndex].servicesToTrainers.map(x => { return { id: x.servicesToTrainerId.toString(), text: `${x.name} - ${getFormatedAmount(x.price)} ${x.currencySign}`, disabled: false, groupName: null } });

                if (this.servicesToTrainer.length > 0) {
                    this.selectedServicesToTrainer = this.servicesToTrainer[0];
                } else {
                    this.selectedServicesToTrainer = null;
                }

                this.selectServiceToTrainer();
            },
            selectServiceToTrainer: function () {
                if (!this.selectedServicesToTrainer) {
                    this.duration = 0;
                    return;
                }

                let index = this.clubWithZones.findIndex(x => x.name == this.selectedPersonalTrainer?.groupName);

                if (index == -1) {
                    this.duration = 0;
                    return;
                }

                let trainerIndex = this.clubWithZones[index].trainers.findIndex(x => `${x.trainerId.toString()}|${this.clubWithZones[index].clubId}` == this.selectedPersonalTrainer?.id);

                if (trainerIndex == -1) {
                    this.duration = 0;
                    return;
                }

                let serviceIndex = this.clubWithZones[index].trainers[trainerIndex].servicesToTrainers.findIndex(x => x.servicesToTrainerId.toString() == this.selectedServicesToTrainer?.id);

                if (serviceIndex == -1) {
                    this.duration = 0;
                    return;
                }

                this.duration = this.clubWithZones[index].trainers[trainerIndex].servicesToTrainers[serviceIndex].duration;
            },
            selectClient: function (client: any) {
                this.activeClient = client;

                this.availableMethod = [];
                this.selectedMethod = null;

                this.isShowAvailableMethod = false;

                if(!client) return;

                this.availableMethod.push({ label: 'training_payment_method.later', isTranslated: true, value: "later" });
                this.availableMethod.push({ label: 'training_payment_method.pos', isTranslated: true, value: "pos" });
                this.availableMethod.push({ label: 'training_payment_method.cash', isTranslated: true, value: "cash" });

                if (this.activeClient?.hasPaymentMean) this.availableMethod.push({ label: 'training_payment_method.linked_card', isTranslated: true, value: "linkedCard" });
                if (this.activeClient?.hasTrainingBlock) this.availableMethod.push({ label: 'training_payment_method.training_block', isTranslated: true, value: "trainingBlock" });

                this.selectedMethod = { label: 'training_payment_method.later', isTranslated: true, value: "later" }
                nextTick(() => this.isShowAvailableMethod = true);
            },
            save: function () {
                if (!this.selectedServicesToTrainer) this.servicesToTrainerErrorText = 'timetable.create_personal_training.service_error';
                if (!this.startDate) this.startDateErrorText = 'timetable.create_personal_training.start_date_error';
                if (this.startDate && new Date(this.startDate) < new Date()) this.startDateErrorText = 'timetable.create_personal_training.start_date_expired_error';

                if (this.servicesToTrainerErrorText || this.startDateErrorText) return;

                this.isButtonDisabled = true;

                let data = { clientId: this.activeClient.id, beginDate: getFormatedDate(this.startDate, "yyyy-MM-DDTHH:mm"), serviceToTrainerId: this.selectedServicesToTrainer?.id, paymentMethod: this.selectedMethod?.value};

                axiosRequests.post(editUrl(Routes.createPersonalTraining), data)
                    .then(
                        () => {
                            this.$emit("close", true);
                        }
                    )
                    .catch(
                        async (err: any) => {
                            this.isButtonDisabled = false;
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400 && err.data) {
                                this.$store.commit('SET_ADD_ERROR', err.data);
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    );
            },
            clickOnSaveButton: function () {
                if (this.selectedMethod?.value == "pos") {
                    this.alertText = 'timetable.create_personal_training.pos_alert';
                    this.isShowAlert = true;
                } else if (this.selectedMethod?.value == "cash") {
                    this.alertText = 'timetable.create_personal_training.cash_alert';
                    this.isShowAlert = true;
                } else {
                    this.save();
                }
            },
            checkPressedButton: function (event: any) {
                if (event.keyCode == 27) this.close();
            }
        },
        watch: {
            startDate: function(newValue){
                if (!newValue || (newValue && new Date() > new Date(newValue))) {
                    this.isButtonDisabled = true;
                    this.startDateErrorText = 'timetable.create_personal_training.start_date_expired_error';
                }
                else {
                    this.isButtonDisabled = false;
                }  
            }
        },
        components: { findClients: FindClients },
        async beforeMount() {
            let timetablePermission = this.$store.getters.getTimetablePermission;

            if (timetablePermission.length == 0) timetablePermission = await retryGetPermissions("timetable");

            if (!timetablePermission?.includes('timetables/personal-trainings/create')) {
                this.$emit("close", false);
                return;
            }

            if (this.trainingDate) this.startDate = getFormatedDate(this.trainingDate, "yyyy-MM-DDTHH:mm");
            if (!this.trainingDate || (this.trainingDate && new Date() > new Date(this.trainingDate))) this.isButtonDisabled = true;

            for (let club of this.clubWithZones) {
                if (club.trainers.length == 0) continue;

                for (let trainer of club.trainers) {
                    this.personalTrainers.push({ id: `${trainer.trainerId.toString()}|${club.clubId}`, text: trainer.name, isTranslated: false, disabled: false, groupName: club.name });
                    if (this.selectedPersonalTrainerId && this.selectedPersonalTrainerId == `${trainer.trainerId.toString()}|${club.clubId}`) this.selectedPersonalTrainer = { id: `${trainer.trainerId.toString()}|${club.clubId}`, text: trainer.name, isTranslated: false, disabled: false, groupName: club.name };
                }
            }

            if (!this.selectedPersonalTrainer && this.personalTrainers.length > 0) this.selectedPersonalTrainer = this.personalTrainers[0];

            this.selectTrainer();         
        },
        mounted() {
            window.addEventListener('keyup', this.checkPressedButton);
        },
        beforeUnmount() {
            window.removeEventListener('keyup', this.checkPressedButton);
        }
    });
</script>

<style scoped>
    .create_training_wrapper {
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: #b5b4b466;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .create_training_body {
        background-color: white;
        max-width: 480px;
        width: max-content;
        border-radius: 24px;
        animation: show 0.6s linear;
        min-height: 365px;
    }

    .create_training_title {
        font-size: 17px;
        letter-spacing: 1px;
        font-weight: 600;
        background-color: #f6f7f8;
        padding: 16px 24px;
        border-radius: 24px 24px 0px 0px;
    }

    .create_training {
        margin-top: 12px;
        padding: 0px 24px;
    }

    .create_training_buttons_wrapper {
        margin-top: 12px;
        display: flex;
        padding: 0px 24px;
        margin-bottom: 8px;
    }

    .button_space {
        margin-left: 16px;
    }

    .text_with_span {
        margin-top: 0px;
        margin-bottom: 8px;
        color: #65656F;
    }

    .text_with_span span {
        font-weight: 600;
        color: black;
    }

    @media(max-width: 500px) {
        .create_training_body {
            width: 98%;
        }

        .button_space {
            margin-left: 0px;
        }

        .create_training_buttons_wrapper {
            display: block;
        }

        .create_training_content_wrapper {
            max-height: calc(100vh - 90px);
            overflow: auto;
        }
    }
</style>