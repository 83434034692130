import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-637cd962"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "add_service_wrapper" }
const _hoisted_2 = { class: "add_service_body" }
const _hoisted_3 = { class: "add_service_title" }
const _hoisted_4 = { class: "add_service" }
const _hoisted_5 = { class: "mt-0 mb-2" }
const _hoisted_6 = { class: "price_span" }
const _hoisted_7 = { class: "mt-0 mb-2" }
const _hoisted_8 = { class: "price_span" }
const _hoisted_9 = { class: "mt-3 mb-2" }
const _hoisted_10 = { class: "add_service_buttons_wrapper" }
const _hoisted_11 = ["disabled"]

export function render(_ctx, _cache) {
  const _component_v_alert = _resolveComponent("v-alert")
  const _component_v_select = _resolveComponent("v-select")
  const _component_v_radio = _resolveComponent("v-radio")
  const _directive_click_outside = _resolveDirective("click-outside")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_alert, {
      modelValue: _ctx.showAlert,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.showAlert) = $event)),
      title: 'contract.view.add_service.confirmation',
      text: _ctx.alertText,
      successButtonText: _ctx.$t('contract.view.add_service.confirm'),
      cancelButtonText: _ctx.$t('clients.view.cancel'),
      onSuccess: _ctx.save,
      onCancel: _cache[1] || (_cache[1] = $event => (_ctx.showAlert = false))
    }, null, 8, ["modelValue", "title", "text", "successButtonText", "cancelButtonText", "onSuccess"]),
    _createElementVNode("div", _hoisted_1, [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('contract.view.add_service.add_service')), 1),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_v_select, {
            class: "mt-4 mb-4",
            modelValue: _ctx.selectedService,
            "onUpdate:modelValue": [
              _cache[2] || (_cache[2] = $event => ((_ctx.selectedService) = $event)),
              _ctx.selectService
            ],
            label: 'contract.view.add_service.service',
            items: _ctx.services,
            errorText: _ctx.serviceErrorText,
            onClick: _cache[3] || (_cache[3] = $event => (_ctx.serviceErrorText = ''))
          }, null, 8, ["modelValue", "label", "items", "onUpdate:modelValue", "errorText"]),
          _createElementVNode("p", _hoisted_5, [
            _createTextVNode(_toDisplayString(_ctx.$t('contract.view.add_service.price')) + ": ", 1),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.selectedPrice), 1)
          ]),
          _createElementVNode("p", _hoisted_7, [
            _createTextVNode(_toDisplayString(_ctx.$t('contract.view.add_service.first_payment')) + ": ", 1),
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.selectedFirstPaymentAmount), 1)
          ]),
          _createElementVNode("h4", _hoisted_9, _toDisplayString(_ctx.$t('add_entity_payment_method.payment_method')), 1),
          _createVNode(_component_v_radio, {
            class: "ml-2",
            modelValue: _ctx.selectedMethod,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.selectedMethod) = $event)),
            radioList: _ctx.availableMethod,
            mb: 2
          }, null, 8, ["modelValue", "radioList"])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("button", {
            class: "primary_button",
            onClick: _cache[5] || (_cache[5] = (...args) => (_ctx.clickOnSaveButton && _ctx.clickOnSaveButton(...args))),
            disabled: _ctx.isButtonDisabled
          }, _toDisplayString(_ctx.$t('contract.view.add_service.add')), 9, _hoisted_11),
          _createElementVNode("button", {
            class: "secondary_button button_space",
            onClick: _cache[6] || (_cache[6] = $event => (_ctx.$emit('close')))
          }, _toDisplayString(_ctx.$t('contract.view.add_service.cancel')), 1)
        ])
      ])), [
        [_directive_click_outside, _ctx.clickOutside]
      ])
    ])
  ], 64))
}